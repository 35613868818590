import { ShareHolderData, SelectedNodeShareHolder } from "../../../types";
import { isNumericValue } from "../../../../../lib/utils";
import i18n from "../../../../../i18n";
import moment from "moment";
export function getDefaultData({
  shareHoldingData
}: {
  shareHoldingData?: ShareHolderData;
}) {
  return {
    entityType: "naturalPerson",
    nodeId: shareHoldingData?.nodeId,
    legalEntityName: shareHoldingData?.legalEntityName || "",
    votingRights: shareHoldingData?.votingRights || 0,
    shareHolding: shareHoldingData?.shareHolding || 0
  };
}
export function checkAvailability({
  remainingVotePercent,
  remainingSharePercent,
  shareholderData
}: {
  remainingVotePercent: number;
  remainingSharePercent: number;
  shareholderData: ShareHolderData;
}) {
  if ((shareholderData.votingRights as number) > remainingVotePercent) {
    return i18n.t(
      `Only {{remainingVotePercent}} % of voting rights are available`,
      { remainingVotePercent: remainingVotePercent }
    );
  }
  if ((shareholderData.shareHolding as number) > remainingSharePercent) {
    return i18n.t(
      `Only {{remainingSharePercent}} % of capital shares are avialable`,
      {
        remainingSharePercent: remainingSharePercent
      }
    );
  }

  return false;
}
export function validateData(data: SelectedNodeShareHolder) {
  if (data.entityType === "naturalPerson") {
    if (!data.firstName || !data.lastName) {
      return "Please enter natural person details";
    }
    if (!data.taxIdNumber && !data.comments) {
      return i18n.t(
        "If exempt from providing the Tax-ID number,please include the reason as a comment."
      );
    }
    if (!data.country) {
      return "Please enter country";
    }
    if (!data.dob) {
      return "Please enter date of birth";
    }
    if (!data.city) {
      return "Please enter city/place of residence";
    }
    if (!data.placeOfBirth) {
      return "Please enter place of birth";
    }
    if (data.dob) {
      const inputDate = moment(data.dob, "DD-MM-YYYY"); // Parse the input date
      const fiveYearsAgo = moment().subtract(5, "years");

      if (inputDate.isAfter(fiveYearsAgo)) {
        return "Date of birth should be at least 5 years ago";
      }
    }
  }
  if (data.entityType === "legalEntity") {
    if (!data.legalEntityName) {
      return "Please enter legal entity name";
    }
  }
  //This is done because vote and shares for parent node will always be 100
  if (
    data.nodeId !== "1" &&
    (!isNumericValue({
      data: data.votingRights,
      allowNegative: false,
      allowOnlyNonZero: false,
      upperLimit: 100
    }) ||
      !isNumericValue({
        data: data.shareHolding,
        allowNegative: false,
        allowOnlyNonZero: false,
        upperLimit: 100
      }))
  ) {
    return "Please enter valid voting rights and capital share between 0-100";
  }
  if (
    data.nodeId !== "1" &&
    Number(data.shareHolding) === 0 &&
    Number(data.votingRights) === 0
  ) {
    return "Both voting rights and capital share can not be 0";
  }
}
export function parseData(data?: SelectedNodeShareHolder) {
  if (!data) {
    return data;
  }
  if (data.entityType === "naturalPerson") {
    return {
      ...data,
      legalEntityName: "",
      firstName: data.firstName?.trim(),
      lastName: data.lastName?.trim()
    };
  }
  if (data.entityType === "legalEntity") {
    return {
      ...data,
      firstName: "",
      lastName: "",
      legalEntityName: data.legalEntityName?.trim()
    };
  }
}
