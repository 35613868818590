import gql from "graphql-tag";

export const GET_RISK_COUNTRIES = gql`
  query RiskCountries {
    riskCountries {
      id
      code
      name
      states {
        id
        code
        name
      }
    }
  }
`;

export const SEARCH_COMPANY_BY_NAME = gql`
  query SearchCompany(
    $countryCode: String!
    $stateCode: String!
    $searchTerm: String
    $accountQuestionnaireId: ID!
    $sectionId: ID!
  ) {
    searchCompany(
      countryCode: $countryCode
      stateCode: $stateCode
      searchTerm: $searchTerm
      accountQuestionnaireId: $accountQuestionnaireId
      sectionId: $sectionId
    ) {
      id
      code
      name
      registrationAuthCode
      address
    }
  }
`;

export const SEARCH_COMPANY_BY_CODE = gql`
  query SearchCompany(
    $countryCode: String!
    $stateCode: String!
    $companyCode: String
    $accountQuestionnaireId: ID!
    $sectionId: ID!
  ) {
    searchCompany(
      countryCode: $countryCode
      stateCode: $stateCode
      companyCode: $companyCode
      accountQuestionnaireId: $accountQuestionnaireId
      sectionId: $sectionId
    ) {
      id
      code
      name
      registrationAuthCode
      address
    }
  }
`;

export const GET_COMPANY_API_PROFILE = gql`
  query GetCompanyApiProfile(
    $accountQuestionnaireId: ID!
    $countryCode: String!
    $stateCode: String!
    $companyCode: String!
    $questionId: ID!
    $sectionId: ID!
    $registrationAuthCode: String
  ) {
    getCompanyApiProfile(
      accountQuestionnaireId: $accountQuestionnaireId
      countryCode: $countryCode
      stateCode: $stateCode
      companyCode: $companyCode
      questionId: $questionId
      sectionId: $sectionId
      registrationAuthCode: $registrationAuthCode
    ) {
      question {
        id
        title
        label
        apiFieldMapping {
          id
          originalFieldName
        }
      }
      answer
    }
  }
`;

export const GET_EXTERNAL_DOCUMENTS_LIST = gql`
  query getExternalDocumentsList(
    $accountQuestionnaireId: ID!
    $companyCode: String
    $sectionId: ID!
    $questionId: ID!
    $continuationKey: String
  ) {
    getExternalDocumentsList(
      accountQuestionnaireId: $accountQuestionnaireId
      companyCode: $companyCode
      sectionId: $sectionId
      questionId: $questionId
      continuationKey: $continuationKey
    ) {
      documentsList {
        productId
        title
        credits
        currency
        companyCode
        format
        deliveryTime
      }
      continuationKey
    }
  }
`;

export const GET_EXTERNAL_COMPANIES_LIST = gql`
  query getExternalCompaniesList($accountQuestionnaireId: ID!) {
    getExternalCompaniesList(accountQuestionnaireId: $accountQuestionnaireId) {
      id
      companyCode
      name
      updatedAt
    }
  }
`;

export const ORDER_DOCUMENTS = gql`
  mutation OrderDocuments($input: OrderExternalDocumentsInput!) {
    orderExternalDocuments(input: $input) {
      message
      orders {
        id
        title
        status
        questionAnswerId
      }
    }
  }
`;
export const ORDER_EXTERNAL_DOCUMENTS_FOR_DATASET = gql`
  mutation OrderExternalDocumentsForDataset(
    $input: OrderExternalDocumentsForDatasetInput!
  ) {
    orderExternalDocumentsForDataset(input: $input) {
      datasetQuestionAnswerId
      message
      orders {
        id
        title
        status
        questionAnswerId
      }
    }
  }
`;
export const DELETE_EXTERNAL_ORDER = gql`
  mutation deleteExternalOrder($input: DeleteExternalOrderInput!) {
    deleteExternalOrder(input: $input) {
      data
      message
    }
  }
`;
export const GET_COMPANY_UBOS = gql`
  query getExternalUbos(
    $accountQuestionnaireId: ID!
    $sectionId: ID!
    $questionId: ID!
    $orderId: ID!
  ) {
    getExternalUbos(
      accountQuestionnaireId: $accountQuestionnaireId
      sectionId: $sectionId
      questionId: $questionId
      orderId: $orderId
    ) {
      id
      title
      status
      ownershipTreeField {
        idField
        nameField
        addressField
        statusField
        uboThresholdField
        maxCreditCostField
        totalCreditsSpentField
        countryIsoField
        unwrapFeeField
        companyCodeField
        continuationKeysUsedField
        nodesField {
          levelField
          rollupPercentageField
          entityField {
            idField
            countryIsoField
            nameField
            nameInEnglishField
            typeField
          }
          edgesField {
            nodeIdField
            typeField
            percentageField
            isCircularField
            shareholdingsField {
              isJointlyHeldField
              percentageField
            }
          }
        }
        ultimateBeneficialOwnersField {
          idField
          nameField
          nameInEnglishField
          percentageField
          entityTypeField
        }
      }
    }
  }
`;
export const ORDER_EXTERNAL_UBO_TREE = gql`
  mutation OrderExternalUboTree($input: OrderExternalUboTreeInput!) {
    orderExternalUboTree(input: $input) {
      order {
        id
        title
        status
      }
    }
  }
`;

export const GET_DATASET_COMPANY_API_PROFILE = gql`
  query GetDatasetCompanyApiProfile(
    $accountQuestionnaireId: ID!
    $countryCode: String!
    $stateCode: String!
    $companyCode: String!
    $sectionId: ID!
    $registrationAuthCode: String
    $datasetQuestionAnswerId: ID
    $datasetQuestionAnswer: QuestionAnswerAttributes!
  ) {
    getDatasetCompanyApiProfile(
      accountQuestionnaireId: $accountQuestionnaireId
      countryCode: $countryCode
      stateCode: $stateCode
      companyCode: $companyCode
      sectionId: $sectionId
      registrationAuthCode: $registrationAuthCode
      datasetQuestionAnswerId: $datasetQuestionAnswerId
      datasetQuestionAnswer: $datasetQuestionAnswer
    ) {
      datasetQuestionAnswerId
      datasetTitle
      mappings {
        question {
          id
          title
          label
          apiFieldMapping {
            id
            originalFieldName
          }
        }
        answer {
          id
          answer
          prefillInfo
          questionId
          sectionId
        }
      }
    }
  }
`;
