import { appData } from "../services/appConfig";
import { TPermissions } from "../types/user-details";
import { toast } from "react-toastify";
import i18n from "../i18n";

export const USER_ADMIN = "user.admin";
export const SUPER_ADMIN = "all_access_on_client_app";
export const ADMIN_USER = "admin";
export const COUNTERPARTY_USER = "counterparty";
export const ASSET_MANAGER_USER = "asset_manager";
export const ADMIN_ASSET_MANAGER = "admin_asset_manager";
export const ASSET_MANAGER_COUNTERPARTY = "asset_manager_counterparty";

// Section Group Permissions List

export const SECTION_VIEW_PERMISSION: string = "section.read";
export const SECTION_EDIT_PERMISSION: string = "section.edit";
export const SECTION_SUBMIT_PERMISSION: string = "section.submit";
export const QUESTIONNAIRE_SUBMIT_PERMISSION: string = "questionnaire.submit";
export const DOWNLOAD_REPORTS_PERMISSION: string = "download_reports";
export const DELETE_EXTERNAL_DOCUMENTS: string = "external_documents.delete";
export const IMPORT_EXTERNAL_PROFILE_BASIC: string =
  "external_profile_basic.import";
export const IMPORT_EXTERNAL_PROFILE_ENHANCED: string =
  "external_profile_enhanced.import";
export const IMPORT_EXTERNAL_UBO_TREE: string = "external_ubo_tree.import";
export const IMPORT_EXTERNAL_DOCUMENTS: string = "external_documents.import";

// Admin Permissions list
export const ADMIN_REGENERATE_PDF_PERMISSION =
  "client_app.questionnaire.regenerate_pdf";
export const ADMIN_SECTION_ROLE_ASSIGNMENT_AM_PERMISSION: string =
  "client_app.section_role_assignment_for_am";
export const ADMIN_SECTION_ROLE_ASSIGNMENT_CP_PERMISSION: string =
  "client_app.section_role_assignment_for_cp";
export const ADMIN_DASHBOARD_PERMISSION: string = "client_app.dashboard";
export const ADMIN_SECTION_REOPEN_PERMISSION: string =
  "client_app.section.reopen";
export const ADMIN_STATUS_CHANGE_PERMISSION: string =
  "client_app.status_change";
export const ADMIN_SECTION_VIEW_PERMISSION: string = "client_app.section.view";
export const ADMIN_SECTION_EDIT_PERMISSION: string = "client_app.section.edit";
export const ADMIN_SECTION_SUBMIT_PERMISSION: string =
  "client_app.section.submit";
export const ADMIN_QUESTIONNAIRE_SUBMIT_PERMISSION: string =
  "client_app.questionnaire.submit";
export const ADMIN_DOWNLOAD_REPORTS_PERMISSION: string =
  "client_app.questionnaire.download_reports";
export const ADMIN_XML_CREATE: string = "client_app.xml_documents.create";
export const ADMIN_DELETE_EXTERNAL_DOCUMENTS: string =
  "client_app.external_documents.delete";
export const ADMIN_IMPORT_EXTERNAL_PROFILE_BASIC: string =
  "client_app.external_profile_basic.import";
export const ADMIN_IMPORT_EXTERNAL_PROFILE_ENHANCED: string =
  "client_app.external_profile_enhanced.import";
export const ADMIN_IMPORT_EXTERNAL_UBO_TREE: string =
  "client_app.external_ubo_tree.import";
export const ADMIN_IMPORT_EXTERNAL_DOCUMENTS: string =
  "client_app.external_documents.import";

export const PERMISSION_ERROR_MESSAGE =
  "You do not have required permission to perform this operation";
export const PERMISSION_VIEW_PAGE_ERROR_MESSAGE =
  "You do not have required permission to view the list";

export type KyckrPermissionTypes =
  | "CompanySearch"
  | "DirectorShareholderSearch"
  | "UBO"
  | "Documents"
  | "DeleteDocuments";

export const kyckrPermissionValues = {
  CompanySearch: [
    IMPORT_EXTERNAL_PROFILE_BASIC,
    ADMIN_IMPORT_EXTERNAL_PROFILE_BASIC
  ],
  DirectorShareholderSearch: [
    IMPORT_EXTERNAL_PROFILE_ENHANCED,
    ADMIN_IMPORT_EXTERNAL_PROFILE_ENHANCED
  ],
  UBO: [IMPORT_EXTERNAL_UBO_TREE, ADMIN_IMPORT_EXTERNAL_UBO_TREE],
  Documents: [IMPORT_EXTERNAL_DOCUMENTS, ADMIN_IMPORT_EXTERNAL_DOCUMENTS],
  DeleteDocuments: [DELETE_EXTERNAL_DOCUMENTS, ADMIN_DELETE_EXTERNAL_DOCUMENTS]
};

export function sectionAccessPermission({
  permissions,
  permissionType
}: {
  permissions: TPermissions[];
  permissionType: string;
}) {
  let sectionAccess = {
    viewSection: false,
    editSection: false,
    submitSection: false
  };

  let isAdminUser = findUserPermissionsFromRoles([
    { name: USER_ADMIN, permissionType: permissionType }
  ]);

  if (isAdminUser) {
    sectionAccess.viewSection = true;
    sectionAccess.editSection = true;
    sectionAccess.submitSection = true;
  } else {
    // For Section VIEW
    if (
      findUserPermissionsFromRoles(
        [{ name: SECTION_VIEW_PERMISSION }],
        permissions
      ) ||
      findUserPermissionsFromRoles([{ name: ADMIN_SECTION_VIEW_PERMISSION }])
    ) {
      sectionAccess.viewSection = true;
    }
    // For Section EDIT
    if (
      findUserPermissionsFromRoles(
        [{ name: SECTION_EDIT_PERMISSION }],
        permissions
      ) ||
      findUserPermissionsFromRoles([{ name: ADMIN_SECTION_EDIT_PERMISSION }])
    ) {
      sectionAccess.viewSection = true;
      sectionAccess.editSection = true;
    }
    // For Section Submit
    if (
      findUserPermissionsFromRoles(
        [{ name: SECTION_SUBMIT_PERMISSION }],
        permissions
      ) ||
      findUserPermissionsFromRoles([{ name: ADMIN_SECTION_SUBMIT_PERMISSION }])
    ) {
      sectionAccess.submitSection = true;
    }
  }
  return sectionAccess;
}

export function downloadReportsPermission(permissions: TPermissions[]) {
  return (
    findUserPermissionsFromRoles(
      [{ name: DOWNLOAD_REPORTS_PERMISSION }],
      permissions
    ) ||
    findUserPermissionsFromRoles([{ name: ADMIN_DOWNLOAD_REPORTS_PERMISSION }])
  );
}

export function hasKyckrPermission({
  permissions,
  permissionType
}: {
  permissions: TPermissions[];
  permissionType: KyckrPermissionTypes;
}) {
  return (
    findUserPermissionsFromRoles(
      [{ name: kyckrPermissionValues[permissionType][0] }],
      permissions
    ) ||
    findUserPermissionsFromRoles([
      { name: kyckrPermissionValues[permissionType][1] }
    ])
  );
}

export function findUserPermissionsFromRoles(
  checkPermissions: TPermissions[],
  permissions?: TPermissions[]
) {
  let permissionsList = permissions?.length
    ? permissions
    : appData.getUserPermissions();
  let permission: TPermissions | undefined;
  if (permissionsList?.length && checkPermissions?.length) {
    permission = checkPermissions.find((item) => {
      return permissionsList.some((permission) => {
        if (item.permissionType) {
          return (
            permission.name === item.name &&
            permission.permissionType === item.permissionType
          );
        } else {
          return permission.name === item.name;
        }
      });
    });
  }
  return permission ? true : false;
}

export function permissionError() {
  toast.error(i18n.t(PERMISSION_ERROR_MESSAGE));
}
