import React, { useContext, useEffect, useState } from "react";
import ExternalCompaniesList from "../../presentation/ExternalCompaniesList";
import CustomModal from "../../presentation/Modals/CustomModal";
import {
  ExternalDocument,
  ImportInformationData,
  OrderDocument
} from "./types";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as apis from "../../../apis/CompanySearch";
import CommonTable from "../../presentation/CommonTable";
import { getExternalDocumentsListColumnConfig } from "./service";
import * as utils from "../../../lib/utils";
import { UserContext } from "../../../Contexts/UserContext";

type Props = {
  isCompanySearchQuestionParent: boolean;
  isCompanySearchQuestion?: string;
  fileParams?: any;
  searchedCompanyDetails?: any;
  hide?: () => void;
  addDocumentsToFiles?: (
    fileList: any,
    datasetQuestionAnswerId?: string | number,
    questionAnswerId?: string | number
  ) => void;
  datasetQuestionAnswerId?: string | number;
  datasetQuestionAnswer?: any;
};

const ImportInfo: React.FC<Props> = ({
  isCompanySearchQuestionParent,
  isCompanySearchQuestion,
  fileParams,
  searchedCompanyDetails,
  hide,
  addDocumentsToFiles,
  datasetQuestionAnswerId,
  datasetQuestionAnswer
}) => {
  const [importInfoData, setImportInfoData] = useState<ImportInformationData>();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const {
    sectionId,
    accountLevelOneQuestionnaireId: accountQuestionnaireId,
    questionId
  } = fileParams;
  const { t } = useTranslation();
  const { userDetails }: any = useContext(UserContext);
  const [continuationKey, setContinuationKey] = useState<string>("");

  const externalDocumentsColumnConfig = React.useMemo(() => {
    return getExternalDocumentsListColumnConfig(
      selectedRows,
      setSelectedRows,
      t
    );
  }, [importInfoData, selectedRows, t]);

  const onClose = () => {
    setImportInfoData(undefined);
    hide && hide();
    setSelectedRows([]);
  };

  const onConfirm = () => {
    if (selectedRows.length) {
      const reqObj: OrderDocument = {
        sectionId,
        accountQuestionnaireId,
        questionId,
        companyCode: importInfoData?.selectedCompany?.id || "",
        products:
          importInfoData?.externalDocumentsList?.map((item: any) => {
            if (selectedRows.includes(item.productId)) {
              return {
                id: item.productId,
                title: item.title
              };
            }
          }) || []
      };
      if (datasetQuestionAnswerId) {
        reqObj.datasetQuestionAnswerId =
          datasetQuestionAnswerId === "DATASET_ADD"
            ? ""
            : datasetQuestionAnswerId;
        reqObj.datasetQuestionAnswer = datasetQuestionAnswer;
        apis
          .orderExternalDocumentsForDataset(reqObj)
          .then((res: any) => {
            if (res.errors?.length) {
              res.errors.forEach((err: any) => {
                toast.error(err);
              });
            } else {
              addDocumentsToFiles &&
                addDocumentsToFiles(
                  res.data.orderExternalDocumentsForDataset.orders,
                  res.data.orderExternalDocumentsForDataset
                    .datasetQuestionAnswerId,
                  res.data.orderExternalDocumentsForDataset.orders[0]
                    .questionAnswerId
                );
              toast.success(
                t(res.data.orderExternalDocumentsForDataset.message)
              );
            }
            onClose();
          })
          .catch((err: Error) => {
            toast.error(err.message);
          });
        return;
      }
      apis
        .orderDocuments(reqObj)
        .then((res: any) => {
          if (res.errors?.length) {
            res.errors.forEach((err: any) => {
              toast.error(err);
            });
          } else {
            addDocumentsToFiles &&
              addDocumentsToFiles(
                res.data.orderExternalDocuments.orders,
                "",
                res.data.orderExternalDocuments.orders[0].questionAnswerId
              );
            toast.success(t(res.data.orderExternalDocuments.message));
          }
          onClose();
        })
        .catch((err: Error) => {
          toast.error(err.message);
        });
    } else {
      toast.error(t("Please select a document"));
    }
  };

  const onCompanySelect = (selectedCompany: any) => {
    const { id: companyCode } = selectedCompany;
    apis
      .getExternalDocumentsList({
        accountQuestionnaireId,
        sectionId,
        companyCode,
        questionId
      })
      .then((res: any) => {
        if (res.errors?.length) {
        } else {
          setSelectedRows([]);
          setImportInfoData({
            ...importInfoData,
            selectedCompany,
            externalDocumentsList:
              res.data.getExternalDocumentsList.documentsList || [],
            continuationKey: res.data.getExternalDocumentsList.continuationKey
          });
        }
      })
      .catch((err: Error) => {
        toast.error(err.message);
      });
  };

  const onContinue = () => {
    if (importInfoData?.continuationKey) {
      apis
        .getExternalDocumentsList({
          accountQuestionnaireId,
          sectionId,
          companyCode: importInfoData?.selectedCompany?.id || "",
          questionId,
          continuationKey: importInfoData?.continuationKey
        })
        .then((res: any) => {
          if (res.errors?.length) {
            res.errors.forEach((err: any) => {
              toast.error(err);
            });
          } else {
            let combinedDocumentsList = [
              ...(importInfoData?.externalDocumentsList || []),
              ...(res.data.getExternalDocumentsList.documentsList || [])
            ];
            if (combinedDocumentsList.length > 1000) {
              combinedDocumentsList =
                res.data.getExternalDocumentsList.documentsList || [];
            }
            setImportInfoData({
              ...importInfoData,
              externalDocumentsList: combinedDocumentsList,
              continuationKey: res.data.getExternalDocumentsList.continuationKey
            });
            if (
              res.data.getExternalDocumentsList.continuationKey !==
              continuationKey
            ) {
              setContinuationKey(
                res.data.getExternalDocumentsList.continuationKey
              );
            }
          }
        })
        .catch((err: Error) => {
          toast.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (!isCompanySearchQuestionParent) {
      setImportInfoData({
        showModal: true
      });
      return;
    }
    if (!searchedCompanyDetails) {
      toast.error(t("Please search a company"));
      return;
    }

    (async () => {
      try {
        const companyCode: any =
          searchedCompanyDetails?.companyProfileField?.codeField;
        const selectedCompany: any = { id: companyCode };
        const externalDocumentsList = await apis.getExternalDocumentsList({
          accountQuestionnaireId,
          sectionId,
          companyCode,
          questionId
        });
        if (externalDocumentsList.errors?.length) {
          externalDocumentsList.errors.forEach((err: any) => {
            toast.error(err);
          });
          return;
        }
        setImportInfoData({
          showModal: true,
          selectedCompany,
          externalDocumentsList:
            externalDocumentsList.data.getExternalDocumentsList.documentsList ||
            []
        });
      } catch (err) {
        if (err instanceof Error) {
          toast.error(utils.formatGraphQLError(err.message));
        }
      }
    })();
  }, []);

  return (
    <>
      {isCompanySearchQuestion && importInfoData && (
        <CustomModal
          modalTitle={t("Import Documents")}
          showModal={importInfoData.showModal || false}
          onClose={onClose}
          showFooter={true}
          footer={
            !!importInfoData.selectedCompany ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    onClose();
                  }}
                >
                  {t("Cancel")}
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    onConfirm();
                  }}
                >
                  {t("Confirm")}
                </button>
              </div>
            ) : (
              <></>
            )
          }
        >
          {!isCompanySearchQuestionParent && (
            <div className="mb-3">
              <ExternalCompaniesList onCompanySelect={onCompanySelect} />
            </div>
          )}
          {importInfoData.selectedCompany ? (
            importInfoData.externalDocumentsList?.length ? (
              <>
                <CommonTable
                  id={1}
                  className={`table common-table-new filter-table`}
                  gridData={importInfoData.externalDocumentsList}
                  columnConfig={externalDocumentsColumnConfig}
                  pageIndex={0}
                  pageSize={10}
                  defaultPageSize={10}
                  totalRecordsCount={
                    importInfoData.externalDocumentsList.length
                  }
                  hiddenColumns={
                    utils.isCounterParty(userDetails?.user_type)
                      ? ["productId", "companyCode", "credits"]
                      : ["productId", "companyCode"]
                  }
                  searchable
                  tableRowClassName="px-2"
                  tableHeadClassName="px-2"
                  scrollable
                  onContinue={onContinue}
                ></CommonTable>
              </>
            ) : (
              <p>{t("No Documents found for the selected company.")}</p>
            )
          ) : (
            ""
          )}
        </CustomModal>
      )}
    </>
  );
};

export default ImportInfo;
