import React from "react";
import InputField from "../../../presentation/InputField";
import DOMPruify from "dompurify";

type Props = {
  id: string | number;
  name: string;
  description: string;
  title: string;
  placeholder?: string;
  showError?: boolean;
  isDisabled?: boolean;
  onChange?: ({ target }: { target: any }) => void;
};

const CustomTitleComponent = ({
  id,
  name,
  title,
  description,
  placeholder,
  showError = false,
  isDisabled = false,
  onChange
}: Props) => {
  return (
    <div className="w-50">
      <div className="text-22 bold">Title</div>
      <div
        className="edit-title-txt"
        dangerouslySetInnerHTML={{
          __html: DOMPruify.sanitize(description)
        }}
      ></div>
      <div>
        <InputField
          inputType="text"
          id={id}
          name={`text-${name}`}
          isDisabled={isDisabled}
          placeholder={placeholder || ""}
          value={title}
          onChange={({ target }: { target: any }) => {
            onChange && onChange({ target });
          }}
        />
      </div>
      {showError && (
        <div className="error-m">Please provide a name for the dataset.</div>
      )}
    </div>
  );
};

export default CustomTitleComponent;
