import gql from "graphql-tag";
export const publicPrivateComments = `
    comment
    commentType{
      id
      name
    }
    commentableId
    commentableType
    createdAt
    deletedAt
    id
    updatedAt
    user{
      name
      userId
    }
`;
const questionFragment = `
    id
    questionBankId
    apiFieldMapping{
      id
      originalFieldName
    }
    title
    label
    placeholder
    helpInfo
    mandatory
    internalUseOnly
    deletedAt
    position
    answerDetails(accountQuestionnaireId: $accountQuestionnaireId){
        answer
        answeredBy
        answeredByAccountName
        updatedAt
        prefillInfo
        publicPrivateComments{
          ${publicPrivateComments}
        }
        datasetAnswers {
          datasetQuestionAnswerId
          datasetQuestionId
          datasetTitle
        }
        id
        uploadedFiles{
            id
            originalFileName
            timeStampedFileName
            externalOrderId
        }
        externalDocumentOrders {
          id
          title
          status
          createdAt
          message
      }
      externalUboOrder {
        id
        title
        status
        createdAt
      }
    }
`;

const fieldValuesFragment = `
    id
    label
    value
    position
    deletedAt
    sectionId
    datasetSection{
      allowExternalInfoRetrieval
    }
`;

const fieldTypeFragment = `
    name
    id
`;

const questionListFragment = `
    ${questionFragment}
    fieldType {
        ${fieldTypeFragment}
    }
    fieldValues {
        ${fieldValuesFragment}
        subQuestions {
            question {
                ${questionFragment}
                fieldType {
                    ${fieldTypeFragment}
                }
                fieldValues {
                    ${fieldValuesFragment}
                    subQuestions {
                        question {
                            ${questionFragment}            
                            fieldType {
                                ${fieldTypeFragment}
                            }
                            fieldValues {
                                ${fieldValuesFragment}
                                subQuestions {
                                    question {
                                        ${questionFragment}                    
                                        fieldType {
                                            ${fieldTypeFragment}
                                        }
                                        fieldValues {
                                            ${fieldValuesFragment}
                                            subQuestions {
                                                question {
                                                    ${questionFragment}
                                                    fieldType {
                                                        ${fieldTypeFragment}
                                                    }
                                                    fieldValues {
                                                        ${fieldValuesFragment}
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const sectionFragment = `
    id
    title
    status{
      id
      label
      name
    }
    parentId
    position
    description
    permissions{
      name      
    }
    autofill
    visibility
    internalUseOnly
    deletedAt
    submittedQuestionnaireStatus{
      id 
      label
      name
    }
    publicPrivateComments{
      ${publicPrivateComments}
    }
    questions{
        ${questionListFragment}
    }
    reopenRequest{
      requestedAt
      requestedBy
    }
    isRiskSection
    riskCatOverview{
      sections{
        title
        riskLevel
        questions{
          title
          score
          riskLevel
          answer
        }
      }
    }
    questionnaireRiskLevel
`;

export const EDIT_QUESTIONNAIRE_QUERY = gql`
  query getQuestionnaireAnswers($accountQuestionnaireId: ID!,$sectionId:ID!) { 
    getQuestionnaireAnswers(
        accountQuestionnaireId: $accountQuestionnaireId,
        sectionId: $sectionId
    ) {
      id
      title
      accountLogo
      allowExternalInfoRetrieval
      completePercentage
      dateStarted
      requestFrom
      distributor
      status{
        id
        label
        name
      }
      questionnairePdfError
      sectionSubmission
      enableSubmitQuestionnaire
      reopenRequest{
        requestedAt
        requestedBy
      }
      sections{
        id
        permissions{
            name            
        }
        status{
          id
          label
          name
        }
        submittedQuestionnaireStatus{
          id
          label
          name
        }
        title
        position
        visibility
        internalUseOnly
      }
      sectionDetail{
        ${sectionFragment}
        subSections {
          ${sectionFragment}
          subSections{
            ${sectionFragment}
          }
        }
      }
    }
  }
`;

export const SAVE_ANSWERS = gql`
  mutation SaveAnswers($input: SaveAnswersInput!) {
    saveAnswers(input: $input) {
      message
      completePercentage
      errors {
        sectionId
        questionId
        questionLabel
        errors
      }
      sectionStatus {
        id
        label
        name
      }
    }
  }
`;

export const COMPLETE_SUBMISSION = gql`
  mutation CompleteSubmission($input: CompleteSubmissionInput!) {
    completeSubmission(input: $input) {
      message
      errors {
        sectionId
        questionId
        questionLabel
        datasets {
          datasetQuestionAnswerId
          hasErrors
        }
        errors
      }
      enableSubmitQuestionnaire
      submittedSections {
        sectionId
        status {
          id
          label
          name
        }
      }
    }
  }
`;

export const AUTOFILL_SECTION = gql`
  mutation AutofillSection($input: SavePrefillDataInput!) {
    savePrefillData(input: $input) {
      message
    }
  }
`;

export const SUBMIT_QUESTIONNAIRE = gql`
  mutation SubmitQuestionnaire($input: SubmitQuestionnaireInput!) {
    submitQuestionnaire(input: $input) {
      message
      questionnaireStatus {
        id
        label
        name
      }
    }
  }
`;

export const REQUEST_REOPEN_QUERY = gql`
  mutation CreateReopenRequest($input: CreateReopenRequestInput!) {
    createReopenRequest(input: $input) {
      message
    }
  }
`;

export const REGENERATE_QUESTIONNAIRE_PDF = gql`
  mutation RegenerateQuestionnairePDF(
    $input: ReGenerateQuestionnairePdfInput!
  ) {
    reGenerateQuestionnairePdf(input: $input) {
      message
    }
  }
`;

export const CHANGE_QUESTIONNAIRE_RISK_LEVEL = gql`
  mutation ChangeQuestionnaireRiskLevel(
    $input: ChangeQuestionnaireRiskLevelInput!
  ) {
    changeQuestionnaireRiskLevel(input: $input) {
      message
    }
  }
`;
