import React, { useState, useEffect, useContext } from "react";
import PageHeader from "../../presentation/PageHeader";
import { getTabDetails, getDefaultRoute, getDefaultTab } from "./service";
import { useParams, useHistory } from "react-router-dom";
import { Params, PageHeaderDetails } from "./types";
import AccountRoutes from "./routes";
import { UserContext } from "../../../Contexts/UserContext";
import * as utils from "../../../lib/utils";
import { toast } from "react-toastify";
import { useQueryParams } from "../../../Hooks/useQueryParams";

const AccountManagerInfo: React.FC = () => {
  const history = useHistory();
  const { accountId, nestedPath }: Params = useParams<any>();
  const defaultRoute = getDefaultRoute(nestedPath);
  const defaultTab = getDefaultTab(defaultRoute);
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [headerDetails, setHeaderDetails] = useState<PageHeaderDetails>({
    title: "",
    details: []
  });
  const { userDetails }: any = useContext(UserContext);
  const defaultPath = utils.isCounterPartyOnly(userDetails?.user_type)
    ? "assignmentsList"
    : "responseList";

  const getPageHeaderDetails = React.useCallback(
    (details: PageHeaderDetails) => {
      setHeaderDetails(details);
    },
    []
  );

  useEffect(() => {
    toast.dismiss();
    setActiveTab(getDefaultTab(defaultRoute));
  }, [defaultRoute]);

  function handleTabChange(tabName: string, tabLink: string) {
    toast.dismiss();
    setActiveTab(tabName);
    history.push(tabLink);
  }

  // Remove jwt_token if present in url
  useQueryParams({
    paramNames: ["jwt_token"]
  });

  return (
    <>
      {activeTab && (
        <PageHeader
          title={" "}
          details={[" "]}
          tabs={getTabDetails(activeTab, accountId, userDetails?.user_type)}
          handleTabChange={handleTabChange}
        />
      )}

      <AccountRoutes
        getPageHeaderDetails={getPageHeaderDetails}
        defaultPath={`/accountManagerInfo/${accountId}/${defaultPath}`}
      />
    </>
  );
};

export default AccountManagerInfo;
