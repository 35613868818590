import moment from "moment";
import i18n from "../i18n";
import { SectionLocks } from "../components/container/CounterpartySubmittedQuestionnaire/types";
import {
  LOCKED,
  STATUS_CAPSULE_DISABLED,
  STATUS_CAPSULE_INFO,
  STATUS_CAPSULE_PRIMARY,
  STATUS_CAPSULE_SUCCESS
} from "../constants/app-constants";
import {
  ARCHIVED,
  DRAFT,
  NEW,
  UNDER_REVIEW,
  INTERNAL_DRAFT,
  MISSING_INFORMATION,
  READY_FOR_CLIENT_APPROVAL,
  VALID
} from "./questionnaireStatusAccess";

import {
  COUNTERPARTY_USER,
  ASSET_MANAGER_USER,
  ADMIN_ASSET_MANAGER,
  ASSET_MANAGER_COUNTERPARTY,
  ADMIN_USER
} from "./questionnairePermissionsAccess";
import {
  HIGH_RISK,
  MODERATE_RISK,
  LOW_RISK,
  INCREASED_RISK,
  HIGH_RISK_LABEL,
  LOW_RISK_LABEL,
  MEDIUM_RISK_LABEL,
  INCREASED_RISK_LABEL
} from "../constants/app-constants";
import { appData } from "../services/appConfig";
import { Section } from "../components/container/SectionComponent/types";
import {
  FieldValue,
  Question
} from "../components/container/QuestionComponent/types";

export const BUILD_REVIEW = "Build Review";
export const SUBMIT = "Submit";
export const REVIEW = "Review";
export function camelCaseToDash(str: string) {
  return str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}

export function getDotColor(input: string) {
  switch (input) {
    case NEW:
    case READY_FOR_CLIENT_APPROVAL:
    case UNDER_REVIEW:
      return "zeidler";
    case MODERATE_RISK:
      return "orange";
    case VALID:
    case LOW_RISK:
      return "green";
    case ARCHIVED:
      return "archived";
    case DRAFT:
    case MISSING_INFORMATION:
    case INTERNAL_DRAFT:
      return "draft";
    case HIGH_RISK:
      return "red";
    default:
      break;
  }
}

export function getScoreColor(riskLevel: string) {
  switch (riskLevel) {
    case HIGH_RISK:
      return "high";
    case INCREASED_RISK:
    case MODERATE_RISK:
      return "average";
    case LOW_RISK:
      return "low";
    default:
      return "expired";
  }
}

export function convertRiskLabels(riskLevel: string) {
  let riskTxt: string = "";
  switch (riskLevel) {
    case HIGH_RISK:
      riskTxt = i18n.t(HIGH_RISK_LABEL);
      break;
    case MODERATE_RISK:
      riskTxt = i18n.t(MEDIUM_RISK_LABEL);
      break;
    case LOW_RISK:
      riskTxt = i18n.t(LOW_RISK_LABEL);
      break;
    case INCREASED_RISK:
      riskTxt = i18n.t(INCREASED_RISK_LABEL);
      break;
  }
  return riskTxt;
}

export function getDate(dateStr: any, format: string = "DD-MM-YYYY") {
  const date = dateStr ? moment(dateStr).format(format) : "";
  return translateDate(date);
}

function translateDate(date: string) {
  let newDateStr = date;
  moment.months().forEach((month: string) => {
    if (date.includes(month)) {
      newDateStr = date.replace(month, i18n.t(month));
    }
  });
  return newDateStr;
}

export function getTime(dateStr: any, format: string = "hh:mm:ss") {
  const time = dateStr ? moment(dateStr).format(format) : "";
  return time;
}

export function getUserTypeFromPage(userType: string) {
  return userType === "cp" ? COUNTERPARTY_USER : ASSET_MANAGER_USER;
}

export function getUserTypes(userType: string) {
  switch (userType) {
    case ADMIN_USER:
      return [ADMIN_USER];
    case ASSET_MANAGER_USER:
      return [ASSET_MANAGER_USER];
    case COUNTERPARTY_USER:
      return [COUNTERPARTY_USER];
    case ADMIN_ASSET_MANAGER:
      return [ADMIN_USER, ASSET_MANAGER_USER];
    case ASSET_MANAGER_COUNTERPARTY:
      return [ASSET_MANAGER_USER, COUNTERPARTY_USER];
  }
}

export function isAdminUser(userType: string) {
  if (
    userType?.toLowerCase() === ADMIN_USER ||
    userType?.toLowerCase() === ADMIN_ASSET_MANAGER
  ) {
    return true;
  } else {
    return false;
  }
}

export function isAssetManager(userType: string) {
  if (
    userType?.toLowerCase() === ASSET_MANAGER_USER ||
    userType?.toLowerCase() === ASSET_MANAGER_COUNTERPARTY ||
    userType?.toLowerCase() === ADMIN_ASSET_MANAGER
  ) {
    return true;
  } else {
    return false;
  }
}

export function isCounterParty(userType: string) {
  if (
    userType?.toLowerCase() === COUNTERPARTY_USER ||
    userType?.toLowerCase() === ASSET_MANAGER_COUNTERPARTY
  ) {
    return true;
  } else {
    return false;
  }
}

export function isAssetManagerOnly(userType: string) {
  if (userType?.toLowerCase() === ASSET_MANAGER_USER) {
    return true;
  } else {
    return false;
  }
}

export function isCounterPartyOnly(userType: string) {
  if (userType?.toLowerCase() === COUNTERPARTY_USER) {
    return true;
  } else {
    return false;
  }
}

export function isAdminOnly(userType: string) {
  if (userType?.toLowerCase() === ADMIN_USER) {
    return true;
  } else {
    return false;
  }
}

export function formatGraphQLError(errorMessage: string) {
  const regex = /GraphQL error:/gi;
  return errorMessage.replace(regex, "");
}

//ToDo: we will combine two methods modifyForSelectBox & modifyForSelectQuestionBox
export function modifyForSelectBox(
  data: any[],
  idField: string,
  labelField: string,
  additionalField?: string,
  isFixed?: boolean
) {
  let modifiedData: any[] = [];
  data &&
    data.length &&
    data.forEach((item, index) => {
      modifiedData.push({
        id: item[idField],
        value:
          additionalField && item[additionalField]
            ? (item[labelField] || "") + " (" + item[additionalField] + ")"
            : item[labelField],
        label:
          additionalField && item[additionalField]
            ? (item[labelField] || "") + " (" + item[additionalField] + ")"
            : i18n.t(item[labelField]),
        ...(isFixed && { isFixed: isFixed ? true : false })
      });
    });
  return modifiedData;
}

export function modifyForSelectBoxi18n(
  data: any[],
  idField: string,
  valueField: string,
  labelField: string,
  isFixed?: boolean
) {
  let modifiedData: any[] = [];
  data &&
    data.length &&
    data.forEach((item, index) => {
      modifiedData.push({
        id: item[idField],
        value: item[valueField],
        label: item[labelField],
        isFixed: isFixed ? true : false
      });
    });
  return modifiedData;
}

export function modifyForSelectQuestionBox(
  data: any[],
  idField: string,
  labelField: string,
  valueField?: string
) {
  let modifiedData: any[] = [];
  data &&
    data.length &&
    data.forEach((item, index) => {
      modifiedData.push({
        id: item[idField],
        value: valueField ? item[valueField] : "",
        label: labelField ? item[labelField] : ""
      });
    });
  return modifiedData;
}

export function getFiltersByAccount() {
  let filtersByAccounts: any;

  try {
    filtersByAccounts = JSON.parse(
      localStorage.getItem("FiltersByAccounts") as string
    );
  } catch (e) {
    console.log("Exception while getting FiltersByAccounts >> ", e);
  }

  return filtersByAccounts || {};
}

export function removeLocalStorage(key: string) {
  let currentAccount = appData?.getUserDetails()?.current_account_id;
  let filtersByAccounts = getFiltersByAccount();
  if (filtersByAccounts[currentAccount]) {
    delete filtersByAccounts[currentAccount][key];
  }
  try {
    if (getCookieConsent()) {
      localStorage.setItem(
        "FiltersByAccounts",
        JSON.stringify(filtersByAccounts)
      );
    }
  } catch (domException) {
    if (
      ["QuotaExceededError", "NS_ERROR_DOM_QUOTA_REACHED"].includes(
        (domException as any)?.name
      )
    ) {
      localStorage.setItem("FiltersByAccounts", JSON.stringify(null));
      console.log("Local storage quota exceeded");
    }
  }
}

export function getLocalStorage(key: string) {
  let currentAccount = appData?.getUserDetails()?.current_account_id;
  let filtersByAccounts = getFiltersByAccount();

  return (
    (filtersByAccounts[currentAccount] &&
      filtersByAccounts[currentAccount][key]) ||
    null
  );
}

export function setLocalStorage(key: string, value: any) {
  let currentAccount = appData?.getUserDetails()?.current_account_id;
  let filtersByAccounts = getFiltersByAccount();

  filtersByAccounts = {
    ...filtersByAccounts,
    [currentAccount]: { ...filtersByAccounts[currentAccount], [key]: value }
  };

  if (key && value) {
    try {
      if (getCookieConsent()) {
        localStorage.setItem(
          "FiltersByAccounts",
          JSON.stringify(filtersByAccounts)
        );
      }
    } catch (domException) {
      if (
        ["QuotaExceededError", "NS_ERROR_DOM_QUOTA_REACHED"].includes(
          (domException as any)?.name
        )
      ) {
        localStorage.setItem("FiltersByAccounts", JSON.stringify(null));
        console.log("Local storage quota exceeded");
      }
    }
  }
}

export function setActiveFilter(arr: any, obj: any) {
  const index = arr.findIndex((e: any) => e.filterName === obj.filterName);

  if (index === -1) {
    arr.push(obj);
  } else {
    if (obj.filterValue.length === 0) {
      arr.splice(index, 1);
    } else {
      arr[index] = obj;
    }
  }

  arr = arr.map((obj: any, index: number) => {
    obj.parentPriority = index + 1;
    return obj;
  });

  return arr;
}

export function isNumericValue({
  data,
  allowNegative,
  allowOnlyNonZero,
  upperLimit
}: {
  data: any;
  allowNegative: boolean;
  allowOnlyNonZero?: boolean;
  upperLimit?: number;
}) {
  try {
    if ((data || data === "0" || data === 0) && !isNaN(data)) {
      if (
        (!allowNegative && Number(data) < Number(0)) ||
        (allowOnlyNonZero && Number(data) === Number(0)) ||
        (upperLimit && Number(data) > Number(upperLimit))
      ) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  } catch {
    return false;
  }
}

export function showLock({
  sectionId,
  lockData
}: {
  sectionId: string | number;
  lockData: SectionLocks[];
}) {
  if (!lockData?.length) {
    return {
      section_id: sectionId,
      locked_by: "Loading...",
      locked_by_id: "Loading...",
      locked_at: new Date().toString(),
      status: LOCKED
    };
  }
  let found: SectionLocks | undefined = lockData.find(
    (val) => Number(val.section_id) === Number(sectionId)
  );
  if (found) {
    return { ...found, section_id: sectionId };
  } else {
    return {
      section_id: sectionId,
      locked_by: "Loading...",
      locked_by_id: "Loading...",
      locked_at: new Date().toString(),
      status: LOCKED
    };
  }
}

export function getStatusCapsule(input: string) {
  switch (input) {
    case DRAFT:
    case MISSING_INFORMATION:
    case INTERNAL_DRAFT:
      return STATUS_CAPSULE_INFO;
    case UNDER_REVIEW:
    case NEW:
    case READY_FOR_CLIENT_APPROVAL:
      return STATUS_CAPSULE_PRIMARY;
    case ARCHIVED:
      return STATUS_CAPSULE_DISABLED;
    case VALID:
      return STATUS_CAPSULE_SUCCESS;
    default:
      return "";
  }
}

export function getPastMonths(numberOfMonths = 12, nameCharLength?: number) {
  let now = new Date();
  const pastMonths = [];
  for (let i = numberOfMonths; i > 0; i--) {
    let pastMonth = new Date(now.getFullYear(), now.getMonth() - i, 1);
    const monthName = pastMonth
      .toLocaleString(undefined, { month: "long" })
      .replace(/[^ -~]/g, "");
    let month = nameCharLength
      ? monthName.trim().slice(0, nameCharLength)
      : monthName;
    pastMonths.push(month);
  }
  return pastMonths;
}

export function getFileType(nameString: string) {
  let extension = nameString.split(".").pop();
  return extension || "";
}

export function resetStickyNavBar() {
  document.body.classList.remove("questionnaire--is-full");
  let navPanelElement = document.querySelector(".side-nav") as any;
  let navListElement = document.querySelector(".side-nav-items") as any;
  if (navPanelElement && navListElement) {
    navPanelElement.style.width = "auto";
    navListElement.style.height = "auto";
  }
}

export function makeNavPanelSticky() {
  let questionnaireElement = document.querySelector(".questionnaire") as any;
  let navPanelElement = document.querySelector(".side-nav") as any;
  let footerPanelElement = document.querySelector(".site-footer") as any;
  let navListElement = document.querySelector(".side-nav-items") as any;
  let submitBtnElement = document.querySelector("#submit_questionnaire") as any;
  let submitBtnHeight = submitBtnElement ? 200 : 100;
  if (
    questionnaireElement &&
    navPanelElement &&
    navListElement &&
    footerPanelElement
  ) {
    let viewportOffset = questionnaireElement.getBoundingClientRect();
    let navPanelWidth = navPanelElement.parentElement?.offsetWidth - 30 || 0; // Minus padding
    // these are relative to the viewport, i.e. the window
    let top = viewportOffset.top;
    if (top <= 0) {
      document.body.classList.add("questionnaire--is-full");
      navPanelElement.style.width = navPanelWidth + "px";
      navListElement.style.height =
        window.innerHeight -
        getVisibleFooterHeight(footerPanelElement) -
        submitBtnHeight +
        "px";
    } else {
      document.body.classList.remove("questionnaire--is-full");
      navPanelElement.style.width = "auto";
      navListElement.style.height = "auto";
    }
  }
}

export function getVisibleFooterHeight(footerElem: any) {
  let visibleFooterHt =
    window.innerHeight - footerElem.getBoundingClientRect()?.top;
  return visibleFooterHt;
}
export function getNodeLevel(nodeId: string) {
  return (nodeId?.match(/\./g) || []).length;
}
export function getCookieValue(cookieName: any) {
  const name = cookieName + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function getCookieConsent() {
  let cookieConsent: string | boolean = getCookieValue("cookie_consent");
  if (cookieConsent && isJsonString(cookieConsent)) {
    cookieConsent = JSON.parse(cookieConsent)?.categories?.length
      ? JSON.parse(cookieConsent)?.categories.indexOf("preferences") !== -1
      : "";
  } else {
    cookieConsent = false;
  }
  return cookieConsent;
}

export function isJsonString(jsonStr: string) {
  try {
    JSON.parse(jsonStr);
  } catch (e) {
    return false;
  }
  return true;
}
export function getStringValues(data: any[]) {
  if (!data?.length) {
    return "";
  }
  const arrayValues = data.map((val: any) => val.label);
  return arrayValues.join(", ");
}
export function sortByMutipleKeys(originalData: any) {
  let duplicateData = [...originalData];
  duplicateData.sort(function (a: any, b: any) {
    return +b.isApproved - +a.isApproved || a.sequence - b.sequence;
  });
  return duplicateData;
}
export function validateCondition({
  sections,
  question,
  fieldValues,
  callback
}: {
  sections?: Section[];
  question?: Question;
  fieldValues?: FieldValue[];
  callback: (question: Question) => boolean;
}) {
  if (sections?.length) {
    return checkSubSections({ sections });
  }
  if (question) {
    return checkSubQuestions({ question });
  }
  if (fieldValues?.length) {
    return checkFieldValues({ fieldValues });
  }
  function checkSubSections({ sections }: { sections: Section[] }) {
    for (const section of sections) {
      if (section.questions?.length) {
        for (const question of section.questions) {
          const isValidCheck = checkSubQuestions({ question });
          if (isValidCheck) {
            return true;
          }
        }
      }
      if (section.subSections?.length) {
        const isValidCheck = checkSubSections({
          sections: section.subSections
        });
        if (isValidCheck) {
          return true;
        }
      }
    }
  }
  function checkSubQuestions({
    question,
    fieldValueId
  }: {
    question: Question;
    fieldValueId?: number | string;
  }) {
    if (callback(question)) {
      return true;
    }
    if (question.fieldValues?.length) {
      const isValidCheck = checkFieldValues({
        fieldValues: question.fieldValues
      });
      if (isValidCheck) {
        return isValidCheck;
      }
    }
  }
  function checkFieldValues({ fieldValues }: { fieldValues: FieldValue[] }) {
    for (const fieldValue of fieldValues) {
      if (fieldValue?.subQuestions?.length) {
        for (const subQuestion of fieldValue.subQuestions) {
          const isValidCheck = checkSubQuestions({
            question: subQuestion?.question,
            fieldValueId: fieldValue.id
          });
          if (isValidCheck) {
            return true;
          }
        }
      }
    }
  }
}

export const validateInteger = (value: string) => {
  if (!/^\d+$/.test(value)) {
    return true;
  }
  return false;
};
export const overrideDropdownStyle = (allowAnswerSubmit?: boolean) => {
  return {
    control: (base: any, state: any) => ({
      ...base,
      ":hover": {
        borderColor: state.isFocused ? "red" : base.borderColor
      },
      borderColor: state.isFocused ? "red" : base.borderColor,
      boxShadow: state.isFocused ? "0 0 0 .1rem #ef8386" : base.boxShadow,
      backgroundColor: allowAnswerSubmit ? "#FFF" : base.backgroundColor
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "#495057"
    })
  };
};
export function removeFileExtension(fileName: string) {
  return fileName.replace(/\.[^/.]+$/, "");
}
