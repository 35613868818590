import client from "../apollo.config";
import * as queries from "./queries";

export const getQuestionnaireDocumentsList = (
  accountQuestionnaireId: string | number
) => {
  return client.query({
    query: queries.GET_QUESTIONNAIRE_DOCUMENTS_LIST_QUERY,
    fetchPolicy: "network-only",
    variables: { accountQuestionnaireId }
  });
};

export const SaveQuestionnaireFileUploadMetadata = (input: {
  questionnaireFileUploadId: string | number;
  documentName: string;
  partnerNumber: string;
  importSystem: string;
  importKey: string;
  tagwords: string;
}) => {
  return client.mutate({
    mutation: queries.SAVE_DOCUMENT_META_DATA,
    variables: { input }
  });
};
