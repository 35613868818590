import { OrgVisualizationNode } from "./types";
import { OrgTreeDetails } from "../types";
import { RefObject, useEffect, useMemo, useState } from "react";
import * as d3 from "d3";
import i18n from "../../../i18n";

let idCounter = 0;
export const nodeHeight = 56;
export const addNodeDimension = 42;
export const nodeSpacingY = 50;
const nodeSpacingX = 10;
const padding = 20;

export const generateId = () => `node-${++idCounter}`;
const textWidthCache: { [key: string]: number | undefined } = {};

const getName = (node: OrgTreeDetails): string => {
  if (node.entityType === "naturalPerson") {
    return [node.firstName, node.lastName].join(" ").trim();
  }

  return node.legalEntityName || i18n.t("New entity");
};

const convertPercent = (percentValue: number): number =>
  Number.isNaN(percentValue) ? 0 : percentValue;

const significantPrecision = (value: number, decimalPlaces: number): string => {
  let result = Number(value).toFixed(decimalPlaces);

  while (
    result.length &&
    result.indexOf(".") > 0 &&
    ["0", "."].includes(result[result.length - 1])
  ) {
    result = result.substr(0, result.length - 1);
  }
  return result;
};

export const formatPercentage = (x: number) => `${significantPrecision(x, 5)}%`;

export const getTextWidth = (text: string, widthTester?: HTMLElement) => {
  const cachedValue = textWidthCache[text];
  if (typeof cachedValue === "number") {
    return cachedValue;
  }

  if (!widthTester) {
    const domTester = document.getElementById("org-chart-width-tester");
    if (domTester) {
      widthTester = domTester;
    }
  }

  if (!widthTester) {
    return 0;
  }

  widthTester.innerText = text;
  const result = widthTester.getBoundingClientRect().width;

  if (result > 0) {
    textWidthCache[text] = result;
  }

  return result;
};

export const getRectWidth = (
  node: OrgVisualizationNode,
  widthTester?: HTMLElement
): number => {
  if (node.type === "add") {
    return 42;
  }

  let padding;

  switch (node.type) {
    case "natural":
    case "company":
      padding = 80;
      break;
    case "root":
      padding = 30;
      break;
    default:
      padding = 0;
      break;
  }

  return getTextWidth(node.name, widthTester) + padding;
};

export const convertTree = (
  node: OrgTreeDetails,
  root?: boolean
): OrgVisualizationNode => {
  const result = {
    id: node.nodeId || generateId(),
    ubo: node.isUBO,
    name: getName(node),
    type: node.entityType === "naturalPerson" ? "natural" : "company",
    auxInformation: {
      holdings: convertPercent(Number(node.shareHolding)),
      holdingsAbsolute: convertPercent(Number(node.absoluteSharePercent)),
      voting: convertPercent(Number(node.votingRights)),
      votingAbsolute: convertPercent(Number(node.absoluteVotePercent)),
      isFreeFloatShareEntity: node.isFreeFloatShareEntity || false,
      isEntityWithNoUbo: node.isEntityWithNoUbo || false
    }
  } as OrgVisualizationNode;

  if (root) {
    result.type = "root";
  }

  if (node.subsidiaries) {
    result.children = node.subsidiaries.map((subsidiary) =>
      convertTree(subsidiary, false)
    );
  }

  return result;
};

export const useZoom = ({
  svg,
  width,
  height,
  extentX,
  extentY,
  scrollToZoomDisabled
}: {
  svg: SVGSVGElement;
  width: number;
  height: number;
  extentX: number[];
  extentY: number[];
  scrollToZoomDisabled?: boolean;
}) => {
  const [transform, setTransform] = useState<string | undefined>();

  const getFitScale = () => {
    const xScale = Math.min(1, width / (extentX[1] - extentX[0]));
    const yScale = Math.min(1, height / (extentY[1] - extentY[0]));

    return Math.min(xScale, yScale);
  };

  const { zoom, handleZoomIn, handleZoomOut, handleResetZoom } = useMemo(() => {
    const newZoom = d3
      .zoom<SVGSVGElement, null>()
      .on("zoom", function ({ transform }) {
        setTransform(transform);
      });

    d3.select<SVGSVGElement, null>(svg).call(newZoom);

    if (scrollToZoomDisabled) {
      d3.select<SVGSVGElement, null>(svg).on("wheel.zoom", null);
    }

    const newHandleZoomIn = () => {
      d3.select<SVGSVGElement, null>(svg)
        .transition()
        .duration(400)
        .call(zoom.scaleBy, 1.5);
    };

    const newHandleZoomOut = () => {
      d3.select<SVGSVGElement, null>(svg)
        .transition()
        .duration(400)
        .call(zoom.scaleBy, 1 / 1.5);
    };

    const newHandleResetZoom = () => {
      d3.select<SVGSVGElement, null>(svg)
        .transition()
        .duration(400)
        .call(zoom.transform, d3.zoomIdentity);
    };

    return {
      zoom: newZoom,
      handleZoomIn: newHandleZoomIn,
      handleZoomOut: newHandleZoomOut,
      handleResetZoom: newHandleResetZoom
    };
  }, [svg]);

  const { handleFitZoom, fitScale } = useMemo(() => {
    const fitScale = getFitScale();

    const newHandleFitZoom = () => {
      d3.select<SVGSVGElement, null>(svg)
        .call(zoom.transform, d3.zoomIdentity)
        .call(zoom.scaleBy, fitScale);
    };

    return {
      handleFitZoom: newHandleFitZoom,
      fitScale
    };
  }, [svg, width, extentX, extentY]);

  return {
    transform,
    fitScale,
    handleFitZoom,
    handleResetZoom,
    handleZoomIn,
    handleZoomOut
  };
};

// means that not all shares and voting rights are fully distributed to children
const hasCapacity = (node: OrgVisualizationNode): boolean => {
  if (
    node.auxInformation.isEntityWithNoUbo ||
    node.auxInformation.isFreeFloatShareEntity
  ) {
    return false;
  }
  const children = node.children || [];

  const totalHoldings = children.reduce<number>(
    (acc, child) => acc + child.auxInformation.holdings,
    0
  );
  const totalVotes = children.reduce<number>(
    (acc, child) => acc + child.auxInformation.voting,
    0
  );

  return totalHoldings < 100 || totalVotes < 100;
};

const extendNode = (
  rawNode: OrgVisualizationNode,
  parent?: OrgVisualizationNode,
  readonly?: boolean
): OrgVisualizationNode => {
  const node = {
    ...rawNode,
    parent
  };

  if (!node.children && (node.type === "company" || node.type === "root")) {
    node.children = [];
  }

  if (node.children) {
    node.children = node.children.map((child) =>
      extendNode(child, node, readonly)
    );

    if (node.type === "company" || node.type === "root") {
      // to be able to add a child, we should not be in readonly mode, node must have capacity and its parent shouldn't
      if (!readonly && hasCapacity(node) && !(parent && hasCapacity(parent))) {
        node.children = [
          {
            type: "add",
            name: "",
            id: generateId(),
            auxInformation: {
              holdings: 0,
              holdingsAbsolute: 0,
              voting: 0,
              votingAbsolute: 0
            }
          },
          ...node.children
        ];
      }
    }
  }

  return node;
};

export const useOrganizationLayout = ({
  hierarchy,
  width,
  height,
  widthTesterRef,
  readonly
}: {
  hierarchy: OrgVisualizationNode;
  width: number;
  height: number;
  widthTesterRef: RefObject<HTMLElement>;
  readonly?: boolean;
}) => {
  const extendedHierarchy = useMemo(
    () => extendNode(hierarchy, undefined, readonly),
    [hierarchy, readonly]
  );

  const d3hierarchy = d3.hierarchy<OrgVisualizationNode>(extendedHierarchy);

  let h = d3hierarchy.height * (nodeSpacingY + nodeHeight);

  const treeLayout = d3
    .tree<OrgVisualizationNode>()
    .nodeSize([1, nodeHeight + 50])
    .separation((a, b) => {
      const [w1, w2]: number[] = [a, b].map((node) => getRectWidth(node.data));
      const result = (w1 + w2) / 2 + nodeSpacingX;

      return a.parent === b.parent ? result : result * 2;
    });

  const root = treeLayout(d3hierarchy);
  const [leftmostNode, rightmostNode] = mirrorLayoutAndGetLayoutExtent(root, h);

  const extentX = [
    leftmostNode.x -
      getRectWidth(leftmostNode.data, widthTesterRef.current || undefined) / 2 -
      padding,
    rightmostNode.x +
      getRectWidth(rightmostNode.data, widthTesterRef.current || undefined) /
        2 +
      padding
  ];

  const extentY = [-nodeHeight / 2 - padding, h + nodeHeight / 2 + padding];

  const offset = [
    (width - (extentX[1] - extentX[0])) / 2,
    (height - (extentY[1] - extentY[0])) / 2
  ];

  const offsetTranslate = [-extentX[0] + offset[0], -extentY[0] + offset[1]]
    .map(Math.floor)
    .join(",");

  return {
    extentX,
    extentY,
    root,
    centerTransform: `translate(${offsetTranslate})`
  };
};

const mirrorLayoutAndGetLayoutExtent = (
  node: d3.HierarchyPointNode<OrgVisualizationNode>,
  h?: number
) => {
  let leftmostNode = node;
  let rightmostNode = node;

  if (typeof h === "number") {
    node.y = h - node.y;
  }

  if (node.children) {
    for (const child of node.children) {
      if (child) {
        const [childLeft, childRight] = mirrorLayoutAndGetLayoutExtent(
          child,
          h
        );

        if (childLeft.x < leftmostNode.x) {
          leftmostNode = childLeft;
        }
        if (childRight.x > rightmostNode.x) {
          rightmostNode = childRight;
        }
      }
    }
  }

  return [leftmostNode, rightmostNode];
};

// Hook
export function useHover(ref: RefObject<SVGElement>) {
  const [value, setValue] = useState<boolean>(false);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);
  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);
        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );
  return value;
}

export const supportsCssVariables = () => {
  if (window.CSS instanceof Object && typeof CSS.supports === "function") {
    if (CSS.supports("color", "var(--fake-var)")) {
      return true;
    }
  }

  return false;
};
