import { MetaDataField } from "./types";

export function getXmlFileMetadata({
  documentDetails,
  creator,
  fileName
}: {
  documentDetails?: MetaDataField;
  creator: string;
  fileName: string;
}) {
  if (documentDetails) {
    return documentDetails;
  }
  return {
    documentName: fileName,
    creator: creator,
    importSystem: "FILE",
    importKey: "",
    partnerNumber: "",
    tagwords: ""
  };
}
