import React from "react";
import { Questionnaire } from "../components/container/CounterpartySubmittedQuestionnaire/types";
interface Props {
  value: {
    questionnaire: Questionnaire;
    updateQuestionnaire: any;
  };
}
export const QuestionnaireContext = React.createContext({
  questionnaire: {} as any,
  updateQuestionnaire: (data: any) => {}
});
const QuestionnaireContextProvider: React.FC<Props> = (props) => {
  return (
    <QuestionnaireContext.Provider value={props.value}>
      {props.children}
    </QuestionnaireContext.Provider>
  );
};
export default QuestionnaireContextProvider;
