import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Panel from "../../presentation/Panel";
import * as apis from "../../../apis/QuestionnaireDocumentsList";
import * as utils from "../../../lib/utils";
import {
  Params,
  QuestionnaireDocuments,
  StatusLevelAccess,
  SelectedDocument,
  MetaDataField
} from "./types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Icons from "../../presentation/Icons";
import { useTranslation } from "react-i18next";
import PublicPrivateComments from "../PublicPrivateComments";
import { UserContext } from "../../../Contexts/UserContext";
import { downloadFile } from "../../../services/FileUploadDownloadService";
import { allowPublicPrivateComments } from "../../../lib/questionnaireStatusAccess";
import { enableES5 } from "immer";
import produce from "immer";
import { useCommentScroll } from "../../../Hooks/useCommentScroll";
import {
  QueryParamActions,
  QueryPrameters
} from "../../../types/applicationData";
import { useQueryParams } from "../../../Hooks/useQueryParams";
import AddMetaData from "../AddMetaData";
import { getXmlFileMetadata } from "./service";
import {
  findUserPermissionsFromRoles,
  ADMIN_USER,
  ADMIN_XML_CREATE
} from "../../../lib/questionnairePermissionsAccess";
enableES5();
const QuestionnaireDocumentsList: React.FC = () => {
  const queryParams: QueryPrameters | undefined = useQueryParams({
    paramNames: ["documentId", "sectionId"]
  });
  const commentRef = useRef<any>();
  const queryParamActions = useRef<QueryParamActions>({
    hasScrolled: false,
    hasPopupOpened: false
  });
  const { t } = useTranslation();
  const {
    accountQuestionnaireId,
    submittedQuestionnaireId,
    assetManagerId,
    counterpartyId
  }: Params = useParams<any>();
  const { userDetails }: any = useContext(UserContext);
  const [showPPC, setShowPPC] = useState<Boolean>(false);
  const [showXMLMetadata, setShowXMLMetadata] = useState<Boolean>(false);
  const [questionnaireDocuments, setQuestionnaireDocuments] = useState<
    QuestionnaireDocuments[]
  >([]);
  const [metaDataFields, setMetaDataFields] = useState<MetaDataField>(
    {} as MetaDataField
  );
  const [selectedDocument, setSelectedDocument] = useState<SelectedDocument>();
  const [statusLevelAccess, setStatusLevelAccess] =
    useState<StatusLevelAccess>();
  useCommentScroll({
    callBack: () => {
      setShowPPC(true);
    },
    commentRef,
    queryParamActions: queryParamActions.current,
    idFromQueryParam: queryParams?.documentId,
    entityId: queryParams?.documentId || ""
  });
  useEffect(() => {
    apis
      .getQuestionnaireDocumentsList(accountQuestionnaireId)
      .then((res) => {
        setQuestionnaireDocuments(
          res?.data?.questionnaireDocuments
            ? res.data.questionnaireDocuments
            : []
        );
      })
      .catch((error) => {
        toast.error(utils.formatGraphQLError(error.message));
      });
  }, [
    accountQuestionnaireId,
    submittedQuestionnaireId,
    assetManagerId,
    counterpartyId
  ]);
  function openComments(
    documentDetail: any,
    sectionDetails: QuestionnaireDocuments
  ) {
    setSelectedDocument({
      documentId: documentDetail.id,
      sectionId: sectionDetails.sectionId,
      publicPrivateComments: documentDetail.publicPrivateComments || []
    });
    setStatusLevelAccess(
      allowPublicPrivateComments({
        questionnaireStatus: sectionDetails?.submittedQuestionnaireStatus.name,
        userType: userDetails.user_type,
        sectionStatus: sectionDetails?.status?.name
      })
    );
    setShowPPC(true);
  }
  useEffect(() => {
    if (queryParams?.sectionId && queryParams?.documentId) {
      let sectionDetails = questionnaireDocuments.find(
        (sec) => String(sec.sectionId) === String(queryParams?.sectionId)
      );
      if (sectionDetails && sectionDetails.documents?.length) {
        let documentDetail = sectionDetails.documents.find(
          (doc) => String(doc.id) === String(queryParams?.documentId)
        );
        if (documentDetail) {
          openComments(documentDetail, sectionDetails);
        }
      }
    }
  }, [questionnaireDocuments]);
  function onCloseXmlpopUp() {
    setShowXMLMetadata(false);
    setSelectedDocument(undefined);
  }
  function onXmlSave({
    selectedDocument,
    xmlMetadata
  }: {
    selectedDocument: SelectedDocument;
    xmlMetadata: MetaDataField;
  }) {
    onCloseXmlpopUp();
    // Using Immer library for mutating state. Produce returns new state
    setQuestionnaireDocuments(
      produce((draftState: QuestionnaireDocuments[]) => {
        for (let section of draftState) {
          if (section.sectionId === selectedDocument.sectionId) {
            for (let doc of section.documents) {
              if (doc.id === selectedDocument.documentId) {
                doc.xmlFile = xmlMetadata;
                return;
              }
            }
          }
        }
      })
    );
  }
  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mt-5">
                {questionnaireDocuments?.length ? (
                  questionnaireDocuments.map((dataitem, index) => (
                    <div className="type-of-data-details-list mb-5" key={index}>
                      <div
                        className="heading-data-category pb-4"
                        id={`section-${dataitem.sectionId}`}
                      >
                        <span className="text-muted-light mr-2">
                          {index + 1}
                        </span>{" "}
                        {dataitem.sectionName}
                      </div>
                      {dataitem.documents.length ? (
                        dataitem.documents.map((documentDetail, index) => (
                          <Panel
                            key={index}
                            title={documentDetail.originalFileName}
                            description={documentDetail.filePath}
                            infoIcon={
                              <div className="label-info type-of-data">
                                {utils
                                  .getFileType(documentDetail.fileType)
                                  .toUpperCase()}
                              </div>
                            }
                            icons={
                              <>
                                {findUserPermissionsFromRoles([
                                  {
                                    name: ADMIN_XML_CREATE,
                                    permissionType: ADMIN_USER
                                  }
                                ]) && (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id={`meta-data`}>
                                        {t(
                                          documentDetail.xmlFile
                                            ? "View document information for Doxis"
                                            : "Export document to Doxis"
                                        )}
                                      </Tooltip>
                                    }
                                    rootClose
                                  >
                                    <div
                                      className="mr-2 action"
                                      onClick={() => {
                                        setShowXMLMetadata(true);
                                        setSelectedDocument({
                                          documentId: documentDetail.id,
                                          sectionId: dataitem.sectionId
                                        });
                                        setMetaDataFields({
                                          ...getXmlFileMetadata({
                                            documentDetails:
                                              documentDetail.xmlFile,
                                            creator: userDetails.user_name,
                                            fileName:
                                              documentDetail.originalFileName
                                          })
                                        });
                                      }}
                                    >
                                      <Icons
                                        className={`icon ${
                                          documentDetail?.xmlFile
                                            ? "text-primary"
                                            : ""
                                        }`}
                                        icon="metaData"
                                      />
                                    </div>
                                  </OverlayTrigger>
                                )}
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`add-comments`}>
                                      {t("Add comments regarding file")}.
                                    </Tooltip>
                                  }
                                  rootClose
                                >
                                  <div
                                    ref={commentRef}
                                    id={`document-comment-${documentDetail.id}`}
                                    className="mr-2 action"
                                    onClick={(e: any) => {
                                      openComments(documentDetail, dataitem);
                                      e.stopPropagation();
                                    }}
                                  >
                                    <Icons
                                      className={`icon ${
                                        documentDetail?.publicPrivateComments &&
                                        documentDetail.publicPrivateComments
                                          .length > 0
                                          ? "text-primary"
                                          : ""
                                      }`}
                                      icon={
                                        documentDetail?.publicPrivateComments &&
                                        documentDetail.publicPrivateComments
                                          .length > 0
                                          ? "comment"
                                          : "add-comment"
                                      }
                                    />
                                  </div>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`Download-file`}>
                                      {t("Download file")}
                                    </Tooltip>
                                  }
                                  rootClose
                                >
                                  <div
                                    className="mr-2 action"
                                    onClick={() => {
                                      downloadFile({
                                        accountLevelOneQuestionnaireId:
                                          accountQuestionnaireId,
                                        timeStampedFileName:
                                          documentDetail.timeStampedFileName,
                                        fileType: "answerDoc", // add filetype
                                        originalFileName:
                                          documentDetail.originalFileName,
                                        sectionId: dataitem.sectionId
                                      });
                                    }}
                                  >
                                    <Icons className="icon" icon="download" />
                                  </div>
                                </OverlayTrigger>
                              </>
                            }
                            subHeader={
                              <span className="text-16 text--muted">
                                {documentDetail.externalOrderId
                                  ? t("Imported from Registry")
                                  : t("Uploaded by User")}
                              </span>
                            }
                          />
                        ))
                      ) : (
                        <Panel title="Document is not available for this section" />
                      )}
                    </div>
                  ))
                ) : (
                  <Panel title="Documents are not available for this questionnaire" />
                )}
              </div>
            </div>
          </div>
        </div>
        {showPPC && selectedDocument && (
          <PublicPrivateComments
            commentDetails={selectedDocument.publicPrivateComments}
            submittedQuestionnaireId={submittedQuestionnaireId}
            // accountQuestionnaireId={accountQuestionnaireId}
            commentableId={selectedDocument.documentId}
            commentableType="QuestionnaireFileUpload"
            allowComments={{
              showPublicComment: statusLevelAccess?.showPublicComment,
              showPrivateComment: statusLevelAccess?.showPrivateComment,
              editPublicComment: statusLevelAccess?.editPublicComment,
              editPrivateComment: statusLevelAccess?.editPrivateComment
            }}
            onClose={(comments) => {
              setShowPPC(false);
              queryParamActions.current.hasScrolled = true;
              queryParamActions.current.hasPopupOpened = true;
              if (
                statusLevelAccess?.editPublicComment ||
                statusLevelAccess?.editPrivateComment
              ) {
                // Using Immer library for mutating state. Produce returns new state
                setQuestionnaireDocuments(
                  produce((draftState: QuestionnaireDocuments[]) => {
                    for (let section of draftState) {
                      if (section.sectionId === selectedDocument.sectionId) {
                        for (let doc of section.documents) {
                          if (doc.id === selectedDocument.documentId) {
                            doc.publicPrivateComments = comments;
                            return;
                          }
                        }
                      }
                    }
                  })
                );
              }
            }}
            currentUserId={userDetails.user_id}
          />
        )}
      </section>
      {showXMLMetadata && selectedDocument && (
        <AddMetaData
          metaDataFields={metaDataFields}
          selectedDocument={selectedDocument}
          allowEdit={metaDataFields.importKey ? false : true}
          onClose={onCloseXmlpopUp}
          onSuccess={onXmlSave}
        />
      )}
    </>
  );
};

export default QuestionnaireDocumentsList;
