import React, { useState, useRef } from "react";
import SectionComponent from "../SectionComponent";
import { Section } from "../SectionComponent/types";
import Icons from "../../presentation/Icons";
import { deductionDetails } from "../../presentation/Deductions/types";
import { Question } from "../../container/QuestionComponent/types";
import PublicPrivateComments from "../PublicPrivateComments";
import { updateCommentInSection } from "../SectionComponent/service";
import { ValidationError } from "../../container/QuestionComponent/types";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useTranslation } from "react-i18next";
import { useCommentScroll } from "../../../Hooks/useCommentScroll";
import {
  QueryParamActions,
  QueryPrameters
} from "../../../types/applicationData";
type Props = {
  queryParams?: QueryPrameters | undefined | null;
  itemIndex: number | string;
  subSec: Section;
  showComments: any;
  showPublicComment?: boolean;
  showPrivateComment?: boolean;
  editPublicComment?: boolean;
  editPrivateComment?: boolean;
  hiddenSections: any;
  deductionDetails?: deductionDetails;
  showAnswers?: boolean;
  sectionViewType: string;
  allowAnswerSubmit?: boolean;
  currentUserId: string | number;
  submittedQuestionnaireId?: string | number;
  accountQuestionnaireId?: string | number;
  validationErrors?: ValidationError[];
  showErrors?: boolean;
  updateParent?: (section: Section) => void;
  onFieldValChange?: ({
    ques,
    answer,
    additionalInfo,
    updatedSection,
    instantSave,
    companySearchQuestions,
    datasetQuestionAnswerId,
    datasetTitle
  }: {
    ques: Question;
    answer: any;
    additionalInfo?: any;
    updatedSection: Section;
    instantSave?: boolean;
    companySearchQuestions: any;
    datasetQuestionAnswerId?: string | number;
    datasetTitle?: string;
  }) => void;
  fileParams?: any;
  dataSetSectionList?: Section[];
  datasetQuestionAnswerId?: string | number;
  datasetQuestionAnswer?: any;
  onPageRefresh?: () => void;
};
const SubSectionComponent: React.FC<Props> = ({
  queryParams,
  itemIndex,
  subSec,
  showComments,
  showPublicComment,
  showPrivateComment,
  editPublicComment,
  editPrivateComment,
  hiddenSections,
  deductionDetails,
  showAnswers,
  sectionViewType,
  allowAnswerSubmit,
  currentUserId,
  submittedQuestionnaireId,
  accountQuestionnaireId,
  onFieldValChange,
  updateParent,
  validationErrors,
  showErrors,
  fileParams,
  dataSetSectionList,
  datasetQuestionAnswerId,
  datasetQuestionAnswer,
  onPageRefresh
}) => {
  const commentRef = useRef<any>();
  const queryParamActions = useRef<QueryParamActions>({
    hasScrolled: false,
    hasPopupOpened: false
  });
  const { t } = useTranslation();
  const [showPPC, setShowPPC] = useState<Boolean>(false);
  useCommentScroll({
    callBack: () => {
      setShowPPC(true);
    },
    commentRef,
    queryParamActions: queryParamActions.current,
    idFromQueryParam: queryParams?.sectionId || null,
    entityId: subSec?.id
  });
  function handleCommentSave() {
    showComments({
      commentableId: subSec.id,
      commentableType: "Section",
      comments: subSec.comments
    });
  }

  function getBody() {
    return (
      <>
        <div className="response-row response-row--title">
          <div className="response__cell response__cell--content response__cell--subsection new-cell-content border-bottom">
            <h2 className="text-19">
              {subSec.title}
              {subSec.internalUseOnly && (
                // <span className="badge que-badge">For internal use</span>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`internal-use-${itemIndex}`}>
                      {t("For internal use")}
                    </Tooltip>
                  }
                >
                  <span style={{ marginLeft: "10px" }}>
                    <Icons
                      className="text-primary"
                      icon="privateInternalShield"
                    />
                  </span>
                </OverlayTrigger>
              )}
            </h2>
            {subSec.description && (
              <div
                className="response__description-text custom_edit_text custom-title"
                dangerouslySetInnerHTML={{
                  __html: subSec.description
                }}
              ></div>
            )}
          </div>

          {deductionDetails &&
            deductionDetails.scoreLabel &&
            (deductionDetails?.showCurrentScore ||
              deductionDetails?.showDefaultScore) && (
              <div className="response__cell response__cell--deductions">
                <span className="text-muted mr-2">
                  {deductionDetails.scoreLabel}
                </span>
              </div>
            )}

          {showComments && (
            <div className="response__cell response__cell--deductions flex-right mr-3">
              <div className="action-group">
                <div
                  className="action"
                  data-toggle="modal"
                  data-target="#cdd-add-comment-modal"
                  onClick={handleCommentSave}
                >
                  <span
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={
                      subSec.comments && subSec.comments.length > 0
                        ? t("View comments")
                        : t("Add comments regarding questions or scoring.")
                    }
                  >
                    <Icons
                      className={`icon ${
                        subSec.comments && subSec.comments.length > 0
                          ? "text-primary"
                          : ""
                      }`}
                      icon={
                        subSec.comments && subSec.comments.length > 0
                          ? "comment"
                          : "add-comment"
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
          )}
          {(showPublicComment || showPrivateComment) && (
            <div className="response__cell response__cell--deductions flex-right mr-3">
              <div className="action-group">
                <div
                  id={`ppc-comment-sec-${subSec.id} line-height-0`}
                  ref={commentRef}
                  className="action"
                  data-toggle="modal"
                  data-target="#cdd-add-comment-modal"
                  onClick={() => setShowPPC(true)}
                >
                  <span
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={
                      subSec?.publicPrivateComments &&
                      subSec.publicPrivateComments.length > 0
                        ? t("View comments")
                        : t("Add comments for questions.")
                    }
                  >
                    <Icons
                      className={`icon ${
                        subSec?.publicPrivateComments &&
                        subSec.publicPrivateComments.length > 0
                          ? "text-primary"
                          : ""
                      }`}
                      icon={
                        subSec?.publicPrivateComments &&
                        subSec.publicPrivateComments.length > 0
                          ? "comment"
                          : "add-comment"
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <SectionComponent
          queryParams={queryParams}
          itemId={itemIndex}
          section={subSec}
          showComments={showComments}
          hiddenSections={hiddenSections}
          deductionDetails={deductionDetails}
          showAnswers={showAnswers}
          sectionViewType={sectionViewType}
          allowAnswerSubmit={allowAnswerSubmit}
          currentUserId={currentUserId}
          showPublicComment={showPublicComment}
          showPrivateComment={showPrivateComment}
          editPublicComment={editPublicComment}
          editPrivateComment={editPrivateComment}
          submittedQuestionnaireId={submittedQuestionnaireId}
          accountQuestionnaireId={accountQuestionnaireId}
          updateParent={updateParent}
          validationErrors={validationErrors}
          showErrors={showErrors}
          onFieldValChange={({
            ques,
            answer,
            additionalInfo,
            updatedSection,
            instantSave,
            companySearchQuestions,
            datasetQuestionAnswerId,
            datasetTitle
          }) => {
            onFieldValChange &&
              onFieldValChange({
                ques,
                answer,
                additionalInfo,
                updatedSection,
                instantSave,
                companySearchQuestions,
                datasetQuestionAnswerId,
                datasetTitle
              });
          }}
          fileParams={{
            ...fileParams
          }}
          dataSetSectionList={dataSetSectionList}
          datasetQuestionAnswerId={datasetQuestionAnswerId}
          datasetQuestionAnswer={datasetQuestionAnswer}
          onPageRefresh={onPageRefresh}
        />
      </>
    );
  }

  return (
    <React.Fragment>
      {itemIndex.toString().split(".").length > 2 ? (
        <div className="form-group  ">{getBody()}</div>
      ) : (
        <>{getBody()}</>
      )}
      {showPPC && (
        <PublicPrivateComments
          commentDetails={subSec?.publicPrivateComments}
          submittedQuestionnaireId={submittedQuestionnaireId}
          accountQuestionnaireId={accountQuestionnaireId}
          commentableId={subSec.id}
          commentableType="Section"
          allowComments={{
            showPublicComment,
            showPrivateComment,
            editPublicComment,
            editPrivateComment
          }}
          onClose={(comments) => {
            setShowPPC(false);
            queryParamActions.current.hasScrolled = true;
            queryParamActions.current.hasPopupOpened = true;
            (editPublicComment || editPrivateComment) &&
              updateParent &&
              updateParent(updateCommentInSection(subSec, comments));
          }}
          currentUserId={currentUserId}
        />
      )}
    </React.Fragment>
  );
};
export default SubSectionComponent;
