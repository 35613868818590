import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Icons from "../Icons";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";

type Props = {
  searchValue?: string;
  searchOnEnter?: boolean;
  onSearch: (searchString: string) => void;
  placeholder?: string;
  textboxClassName?: string;
};

const Search: React.FC<Props> = ({
  onSearch,
  searchOnEnter,
  searchValue,
  placeholder = "Search",
  textboxClassName = "form-control--rounded"
}) => {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState<string>(searchValue || "");
  const debounced = useDebouncedCallback((value) => onSearch(value), 800);

  useEffect(() => {
    setSearchString(searchValue || "");
  }, [searchValue]);

  return (
    <Form
      onSubmit={(e: any) => {
        e.preventDefault();
      }}
    >
      <div className="custom-search custom-search--loader">
        <Form.Control
          id="search-input"
          className={textboxClassName}
          type="text"
          placeholder={t(placeholder)}
          value={searchString}
          onChange={(e: any) => {
            setSearchString(e.target.value);
            !searchOnEnter && debounced.callback(e.target.value);
          }}
          onKeyPress={({ key }: { key: string }) => {
            if (searchOnEnter && key === "Enter") {
              debounced.callback(searchString);
            }
          }}
        />
        <label
          htmlFor="search-input"
          className="custom-search__icon"
          style={{ cursor: "pointer" }}
          onClick={() => debounced.callback(searchString)}
        >
          <Icons icon="search" className="icon-small" />
        </label>
      </div>
    </Form>
  );
};
export default Search;
