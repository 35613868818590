import gql from "graphql-tag";

export const GET_QUESTIONNAIRE_DOCUMENTS_LIST_QUERY = gql`
  query QuestionnaireDocuments($accountQuestionnaireId: ID!) {
    questionnaireDocuments(accountQuestionnaireId: $accountQuestionnaireId) {
      sectionName
      sectionId
      status {
        label
        name
      }
      submittedQuestionnaireStatus {
        label
        name
      }
      documents {
        id
        originalFileName
        timeStampedFileName
        fileType
        externalOrderId
        filePath
        xmlFile {
          documentName
          partnerNumber
          importSystem
          importKey
          tagwords
          creator: createdBy
        }
        publicPrivateComments {
          id
          comment
          createdAt
          user {
            name
            userId
          }
          commentType {
            id
            name
          }
        }
      }
    }
  }
`;

export const SAVE_DOCUMENT_META_DATA = gql`
  mutation SaveQuestionnaireFileUploadMetadata(
    $input: SaveQuestionnaireFileUploadMetadataInput!
  ) {
    saveQuestionnaireFileUploadMetadata(input: $input) {
      message
      questionnaireFileUploadMetadata {
        documentName
        partnerNumber
        importSystem
        importKey
        tagwords
        creator: createdBy
      }
    }
  }
`;
