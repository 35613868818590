import gql from "graphql-tag";
import { publicPrivateComments } from "../CounterpartySubmittedQuestionnaire/queries";
const questionFragment = `
    id
    questionBankId
    apiFieldMapping{
      id
      originalFieldName
    }
    title
    label
    placeholder
    helpInfo
    mandatory
    internalUseOnly
    deletedAt
    position
    answerDetails(datasetQuestionAnswerId: $datasetQuestionAnswerId){ 
        answer
        answeredBy
        prefillInfo
        answeredByAccountName
        updatedAt
        publicPrivateComments{
            ${publicPrivateComments}
          }
        id
        uploadedFiles{
            id
            originalFileName
            timeStampedFileName
            externalOrderId
        }
        externalDocumentOrders {
          id
          title
          status
          createdAt
          message
      }
    }
`;

const fieldValuesFragment = `
    id
    label
    value
    position
    deletedAt
    sectionId
`;

const fieldTypeFragment = `
    name
    id
`;

const questionListFragment = `
    ${questionFragment}
    fieldType {
        ${fieldTypeFragment}
    }
    fieldValues {
        ${fieldValuesFragment}
        subQuestions {
            question {
                ${questionFragment}
                fieldType {
                    ${fieldTypeFragment}
                }
                fieldValues {
                    ${fieldValuesFragment}
                    subQuestions {
                        question {
                            ${questionFragment}            
                            fieldType {
                                ${fieldTypeFragment}
                            }
                            fieldValues {
                                ${fieldValuesFragment}
                                subQuestions {
                                    question {
                                        ${questionFragment}                    
                                        fieldType {
                                            ${fieldTypeFragment}
                                        }
                                        fieldValues {
                                            ${fieldValuesFragment}
                                            subQuestions {
                                                question {
                                                    ${questionFragment}
                                                    fieldType {
                                                        ${fieldTypeFragment}
                                                    }
                                                    fieldValues {
                                                        ${fieldValuesFragment}
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const sectionFragment = `
    id
    title
    
    parentId
    position
    description
    autofill
    visibility
    internalUseOnly
    deletedAt
    publicPrivateComments{
        ${publicPrivateComments}
      }
    questions{
        ${questionListFragment}
    }
`;
export const GET_DATASET_DETAILS = gql`
  query GetDataSetDetails(
      $datasetQuestionAnswerId: ID!
      $questionId: ID! 
      $accountQuestionnaireId: ID! 
      $sectionId: ID!
  ) {
    datasetSectionDetails(
      datasetQuestionAnswerId: $datasetQuestionAnswerId
      questionId: $questionId
      accountQuestionnaireId: $accountQuestionnaireId
      sectionId: $sectionId
    ) {
        id
        title
        questions{
            ${questionListFragment}
        }

        subSections{
            ${sectionFragment}
            subSections {
              ${sectionFragment}
              subSections{
                ${sectionFragment}
              }
            }
          }

        datasetTitle(datasetQuestionAnswerId: $datasetQuestionAnswerId)

    }
  }
`;

export const DELETE_DATASET = gql`
  mutation DeleteDataSet($input: DeleteDatasetAnswerInput!) {
    deleteDatasetAnswer(input: $input) {
      message
      completePercentage
    }
  }
`;

export const SAVE_DATASET = gql`
  mutation SaveDataSet($input: SaveDatasetAnswersInput!) {
    saveDatasetAnswers(input: $input) {
      message
      datasetQuestionAnswerId
      datasetTitle
      completePercentage
      errors {
        errors
        questionId
      }
      questionId
    }
  }
`;

export const GET_EXTERNAL_COMPANIES_LIST = gql`
  query getExternalCompaniesList($accountQuestionnaireId: ID!) {
    getExternalCompaniesList(accountQuestionnaireId: $accountQuestionnaireId) {
      id
      companyCode
      fullAddress
      name
      updatedAt
      directorsAndShareholders {
        id
        name
        title
        dateOfBirth
        allInfo
        percentage
        shareholderType
        totalShares
      }
    }
  }
`;

export const SAVE_EXTERNAL_DATASET = gql`
  mutation SaveDataSet($input: SaveExternalDatasetsInput!) {
    saveExternalDatasets(input: $input) {
      message
      errors {
        datasetQuestionAnswerId
        hasErrors
      }
    }
  }
`;
