import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef
} from "react";
import {
  useTable,
  usePagination,
  useFilters,
  useSortBy,
  useRowSelect,
  useMountedLayoutEffect
} from "react-table";
import Checkbox from "../Checkbox";
import { useTranslation } from "react-i18next";
import Search from "../Search";

interface Props {
  id?: number;
  gridData: any;
  pageIndex: number;
  pageSize: number;
  columnConfig: any;
  totalRecordsCount: number;
  hiddenColumns?: string[];
  onSort?: (selectValue: any) => void;
  defaultPageSize: number;
  disableSort?: boolean;
  className?: string;
  headerProps?: any;
  rowProps?: any;
  onRowSelect?: any;
  tableHeaderClassName?: string;
  selectedCheckbox?: boolean;
  onBulkSelect?: (selectedRows: any, id: number) => void;
  tableRowClassName?: string;
  tableHeadClassName?: string;
  searchable?: boolean;
  scrollable?: boolean;
  onContinue?: () => void;
}
const CommonTable: React.FC<Props> = ({
  id = 1,
  pageIndex,
  pageSize,
  gridData,
  columnConfig,
  totalRecordsCount,
  hiddenColumns = [],
  onSort,
  defaultPageSize,
  disableSort,
  className = "",
  headerProps,
  rowProps,
  onRowSelect,
  tableHeaderClassName = "",
  selectedCheckbox = false,
  onBulkSelect,
  tableRowClassName = "",
  tableHeadClassName = "",
  searchable,
  scrollable,
  onContinue
}) => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");

  const filteredData = useMemo(() => {
    if (!searchInput) return gridData;
    return gridData.filter((row: any) =>
      columnConfig.some(
        (column: any) =>
          row[column.accessor] &&
          row[column.accessor]
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase())
      )
    );
  }, [gridData, searchInput, columnConfig]);

  // Search input handler
  const handleSearch = (value: string) => {
    setSearchInput(value);
  };
  const {
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    getTableProps,
    getTableBodyProps,
    selectedFlatRows
  } = useTable(
    {
      columns: columnConfig,
      data: filteredData,
      initialState: {
        pageIndex: 0,
        pageSize: defaultPageSize,
        hiddenColumns: hiddenColumns
      },
      manualPagination: true,
      pageCount: Math.ceil(totalRecordsCount / pageSize),
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      disableSortBy: disableSort ? true : false
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (selectedCheckbox) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }: any) => (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Checkbox
                  {...getToggleAllRowsSelectedProps()}
                  id={`table-${id}-checkbox-all`}
                  name="checkbox"
                />
              </div>
            ),
            Cell: ({ row }: any) => {
              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Checkbox
                    {...row.getToggleRowSelectedProps()}
                    name="checkbox"
                    id={`table-${id}-checkbox-${row.id}`}
                  />
                </div>
              );
            }
          },
          ...columns
        ]);
      }
    }
  );
  const divRef = useRef<HTMLDivElement>(null);

  useMountedLayoutEffect(() => {
    onBulkSelect && onBulkSelect(selectedFlatRows, id);
  }, [selectedFlatRows]);

  useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize, setPageSize, totalRecordsCount]);

  useEffect(() => {
    const handleScroll = () => {
      const divElement = divRef.current;
      if (!divElement) return;

      const isBottom =
        Math.ceil(divElement.clientHeight + divElement.scrollTop) ===
        divElement.scrollHeight;
      if (isBottom && onContinue) {
        onContinue();
      }
    };

    const divElement = divRef.current;
    if (divElement) {
      divElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (divElement) {
        divElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [onContinue, pageIndex, pageSize, totalRecordsCount]);

  return (
    <>
      {searchable && (
        <div className="d-flex align-items-center mb-3 pt-3">
          <Search
            onSearch={handleSearch}
            searchValue={searchInput}
            textboxClassName="form-control--bordered"
          />
        </div>
      )}
      {scrollable ? (
        <div className="company-table-scroll" ref={divRef}>
          <table className={className} {...getTableProps()}>
            <thead className={tableHeaderClassName}>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any, index: number) => (
                    <th
                      className={`${
                        selectedCheckbox ? "px-2" : ""
                      } ${tableHeadClassName}`}
                      {...column.getHeaderProps()}
                      key={index}
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({
                          title: undefined
                        })
                      )}
                    >
                      {column.render("Header", headerProps)}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {!!page.length ? (
                page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      key={i}
                      onClick={(event) => {
                        event.stopPropagation();
                        onRowSelect && onRowSelect(row);
                      }}
                    >
                      {row.cells.map((cell: any, index: number) => {
                        return (
                          <td
                            className={`${
                              selectedCheckbox ? "px-2" : ""
                            } ${tableRowClassName}`}
                            key={index}
                          >
                            {cell.render("Cell", rowProps)}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={
                      headerGroups.reduce(
                        (totalColumns, headerGroup) =>
                          totalColumns + headerGroup.headers.length,
                        0
                      ) +
                      1 +
                      (selectedCheckbox ? 1 : 0)
                    }
                    className="table__text"
                  >
                    {t("No Records Found")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <table className={className} {...getTableProps()}>
          <thead className={tableHeaderClassName}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, index: number) => (
                  <th
                    className={`${
                      selectedCheckbox ? "px-2" : ""
                    } ${tableHeadClassName}`}
                    {...column.getHeaderProps()}
                    key={index}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({
                        title: undefined
                      })
                    )}
                  >
                    {column.render("Header", headerProps)}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {!!page.length ? (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    key={i}
                    onClick={(event) => {
                      event.stopPropagation();
                      onRowSelect && onRowSelect(row);
                    }}
                  >
                    {row.cells.map((cell: any, index: number) => {
                      return (
                        <td
                          className={`${
                            selectedCheckbox ? "px-2" : ""
                          } ${tableRowClassName}`}
                          key={index}
                        >
                          {cell.render("Cell", rowProps)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={
                    headerGroups.reduce(
                      (totalColumns, headerGroup) =>
                        totalColumns + headerGroup.headers.length,
                      0
                    ) +
                    1 +
                    (selectedCheckbox ? 1 : 0)
                  }
                  className="table__text"
                >
                  {t("No Records Found")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </>
  );
};

export default CommonTable;
