import React from "react";

import { Column } from "react-table";
import { Section } from "../SectionComponent/types";
import { DataSetRecord, DirectorOrShareholder } from "./types";
import { Question, ValidationError } from "../QuestionComponent/types";
import { TFunction } from "i18next";

export function updateSectionsWithComment({
  records,
  section
}: {
  records: DataSetRecord[];
  section: Section;
}) {
  let secIndex = records.findIndex(
    (val: DataSetRecord) => val.datasetSection?.id === section.id
  );
  let updatedRecords = [...records];
  updatedRecords[secIndex].datasetSection = section;
  return updatedRecords;
}

export function getDirectorOrShareholderColumnConfig({
  externalCompaniesList,
  t
}: {
  externalCompaniesList: any;
  t: TFunction;
}) {
  const columnConfig: Column<DirectorOrShareholder>[] = [
    {
      Header: "Id",
      accessor: "id"
    },
    {
      Header: "Name",
      accessor: "name"
    },
    {
      Header: t("Title") || "Title",
      accessor: "title",
      Cell: (cellProps: any) => <span>{cellProps.value || "-"}</span>
    },
    {
      Header: t("Date of Birth") || "Date of Birth",
      accessor: "dateOfBirth",
      Cell: (cellProps: any) => <span>{cellProps.value || "-"}</span>
    },
    {
      Header: t("Share (%)") || "Share (%)",
      accessor: "percentage",
      Cell: (cellProps: any) => (
        <span>{cellProps.value ? cellProps.value + "%" : "-"}</span>
      )
    },
    {
      Header: t("Shareholder Type") || "Shareholder Type",
      accessor: "shareholderType",
      Cell: (cellProps: any) => <span>{cellProps.value || "-"}</span>
    },
    {
      Header: t("Total Shares") || "Total Shares",
      accessor: "totalShares",
      Cell: (cellProps: any) => <span>{cellProps.value || "-"}</span>
    }
  ];
  return columnConfig;
}

export function hasErrorDataSetRecord({
  validationErrors,
  record
}: {
  validationErrors?: ValidationError[];
  record: DataSetRecord;
}) {
  if (!validationErrors?.length) {
    return false;
  }

  return validationErrors.some(
    (validationError) =>
      validationError.questionId === record.datasetQuestionId &&
      validationError.datasets?.some(
        (datasetError) =>
          datasetError.datasetQuestionAnswerId ===
          record.datasetQuestionAnswerId
      )
  );
}

export function getFirstQuestion({
  section
}: {
  section: Section;
}): Question | undefined {
  const findFirstQuestion = (section: Section): Question | undefined => {
    const firstQuestion = section.questions.find((val) => val.position === 1);
    if (firstQuestion?.fieldType.name === "company_api_search") {
      return firstQuestion.fieldValues[0].subQuestions.find(
        (val) => val.question.position === 1
      )?.question;
    }
    if (section.subSections?.length) {
      const secIndex = section.subSections.findIndex(
        (val) => val.position === 1
      );
      if (secIndex !== -1) {
        return findFirstQuestion(section.subSections[secIndex]);
      }
    }
    return firstQuestion;
  };

  if (section.subSections?.length) {
    const secIndex = section.subSections.findIndex((val) => val.position === 1);
    if (secIndex !== -1) {
      return findFirstQuestion(section.subSections[secIndex]);
    }
  }
  return findFirstQuestion(section);
}
