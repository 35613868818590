import React, { useState, useEffect, useContext } from "react";
import { Section } from "../../container/SectionComponent/types";
import Icons from "../Icons";
import CustomPopover from "../Popovers";
import "./styles.scss";
import * as utils from "../../../lib/utils";
import { ValidationError } from "../../container/QuestionComponent/types";
import { HashLink } from "react-router-hash-link";
import { withRouter, useParams } from "react-router-dom";
import { SectionLocks } from "../../container/CounterpartySubmittedQuestionnaire/types";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { LOCKED, SUBMIT } from "../../../constants/app-constants";
import { UserContext } from "../../../Contexts/UserContext";
import { getSectionStatusIcon } from "./service";
import {
  permissionError,
  sectionAccessPermission,
  ASSET_MANAGER_USER
} from "../../../lib/questionnairePermissionsAccess";
import { useTranslation } from "react-i18next";

type Props = {
  sections: Section[];
  sidebarCollapse: () => void;
  allowAnswerSubmit: boolean;
  validationErrors?: ValidationError[];
  showErrors?: boolean;
  toggleShowErrors?: (flag: boolean) => void;
  userTypeFromPage?: string;
  accountQuestionnaireId?: string | number;
  submittedQuestionnaireId: string | number;
  sectionId?: string | number;
  accountId?: string | number;
  sidebarType: string;
  sectionLocks: SectionLocks[];
};

const SideBarSections: React.FC<Props | any> = ({
  sections,
  sidebarCollapse,
  allowAnswerSubmit,
  validationErrors,
  showErrors,
  toggleShowErrors,
  userTypeFromPage,
  accountId,
  accountQuestionnaireId,
  sectionId,
  history,
  sidebarType,
  submittedQuestionnaireId,
  sectionLocks
}) => {
  const { t } = useTranslation();
  const { userDetails }: any = useContext(UserContext);
  const [showSidebarError, setShowSidebarError] = useState<boolean>(false);
  const { accountType }: any = useParams<any>();

  const getInfoContent = () => {
    return (
      <div className="cdd-auto-feature section-status-info">
        <ul className="cddQuestionnairStepsBox">
          <li>
            <div className="statusInfoIcon">
              <Icons icon="complete" />
              {t("Submitted")}
            </div>
            <div className="statusInfoDeas">
              {t("When section is completed.")}
            </div>
          </li>
          <li>
            <div className="statusInfoIcon">
              <Icons icon="pending" />
              {t("Pending")}
            </div>
            <div className="statusInfoDeas">
              {t("When section is ready for editing.")}
            </div>
          </li>
          <li>
            <div className="statusInfoIcon">
              <Icons icon="locked" />
              {t("Locked")}
            </div>
            <div className="statusInfoDeas">
              {t("When the section is locked for editing by other member.")}
            </div>
          </li>
          <li>
            <div className="statusInfoIcon">
              <Icons icon="error" />
              {t("Error")}
            </div>
            <div className="statusInfoDeas">
              {t("When the section has any error after autosave.")}
            </div>
          </li>
        </ul>
      </div>
    );
  };

  const navigateToLink = (link: string, hasPermission: boolean) => {
    if (hasPermission) {
      history.push(link);
    } else {
      permissionError();
    }
  };

  useEffect(() => {
    showErrors && setShowSidebarError(showErrors);
  }, [showErrors]);

  function showSectionIcons(section: Section) {
    let lockData = utils.showLock({
      sectionId: section.id,
      lockData: sectionLocks
    });
    if (
      allowAnswerSubmit &&
      sidebarType === SUBMIT &&
      lockData &&
      lockData.status === LOCKED &&
      String(lockData.locked_by_id) !== String(userDetails.user_id)
    ) {
      return (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id={`section-lock-${section.id}`}>
              {t("dynamicStringTranslation.lockedBy", {
                username: lockData.locked_by,
                datetimestamp: `${utils.getDate(lockData.locked_at || "")}${" "}
              ${utils.getTime(lockData?.locked_at || "")}`
              })}
            </Tooltip>
          }
        >
          <div className="nav-item-icon">
            <Icons icon="locked" />
          </div>
        </OverlayTrigger>
      );
    }

    let iconDetails = getSectionStatusIcon({
      sectionStatus: section.status?.name,
      sidebarType
    });
    if (iconDetails) {
      return (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id={`section-status-${section.id}`}>
              <span>{t(iconDetails.tooltip)}</span>
            </Tooltip>
          }
        >
          <div className="nav-item-icon">
            <Icons className="icon--medium" icon={iconDetails.icon} />
          </div>
        </OverlayTrigger>
      );
    } else {
      return "";
    }
  }
  return (
    <>
      {
        <div className="see-errors">
          <div className="see-errors-in"></div>
          {allowAnswerSubmit && (
            <div className="see-errors-btn">
              {t("See errors")}
              <div className="switch">
                <input
                  id="switch-1"
                  type="checkbox"
                  className="switch-input"
                  checked={showSidebarError}
                  onChange={() => {
                    setShowSidebarError(!showSidebarError);
                    toggleShowErrors && toggleShowErrors(!showSidebarError);
                  }}
                />
                <label htmlFor="switch-1" className="switch-label">
                  {t("Switch")}
                </label>
              </div>
            </div>
          )}
          <div className="error-info cddQuestionnairStepsBox">
            {allowAnswerSubmit && (
              <div className="action" style={{ height: "25px" }}>
                <CustomPopover content={getInfoContent()} contentType="html">
                  <Icons icon="info" />
                </CustomPopover>
              </div>
            )}
            <div className="hide-qu-icon" onClick={() => sidebarCollapse()}>
              <Icons icon="collapse-sidebar" />
            </div>
          </div>
        </div>
      }
      <ul className="side-nav-items flex-column">
        {[]
          .concat(sections)
          .sort((a: any, b: any) => a.position - b.position)
          ?.map((section: Section, index: number) => {
            return (
              section.visibility && (
                <li
                  className="nav-item"
                  key={section.id}
                  onClick={() => {
                    let permissions = sectionAccessPermission({
                      permissions: section.permissions,
                      permissionType:
                        sidebarType === SUBMIT
                          ? userTypeFromPage
                          : ASSET_MANAGER_USER
                    });

                    if (sidebarType === SUBMIT) {
                      navigateToLink(
                        `/counterpartySubmittedQuestionnaire/${accountType}/${accountQuestionnaireId}/${accountId}/${section.id}`,
                        permissions.editSection || permissions.viewSection
                      );
                    } else {
                      navigateToLink(
                        `/submittedQuestionnaire/am/${accountQuestionnaireId}/${accountId}/${
                          submittedQuestionnaireId || "new"
                        }/${section.id}`,
                        permissions.editSection || permissions.viewSection
                      );
                    }
                  }}
                >
                  <a className={`questionnaire__link nav-link`}>
                    {showSectionIcons(section)}

                    <div
                      className={`nav-item-txt ${
                        section.id === sectionId
                          ? "active"
                          : "disabled cursor-pointer"
                      }`}
                    >
                      {section.title}
                      {section.internalUseOnly && (
                        // <span className="badge que-badge">
                        //   For internal use
                        // </span>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`internal-use-${section.id}`}>
                              {t("For internal use")}
                            </Tooltip>
                          }
                        >
                          <span style={{ marginLeft: "10px" }}>
                            <Icons
                              className="text-primary"
                              icon="privateInternalShield"
                            />
                          </span>
                        </OverlayTrigger>
                      )}
                    </div>
                    {section.riskLevel && (
                      <div
                        className={`rating ml-2 rating--medium rating--${utils.getScoreColor(
                          section.riskLevel
                        )}`}
                      >
                        <div className="rating__number h5">
                          {utils.convertRiskLabels(section.riskLevel)}
                        </div>
                      </div>
                    )}
                    {validationErrors &&
                      validationErrors.filter(
                        (error: any) => error.sectionId === section.id
                      ).length > 0 && (
                        <span className="ml-2">
                          <Icons icon="error" />
                        </span>
                      )}
                  </a>
                  {showSidebarError &&
                    validationErrors &&
                    validationErrors
                      .filter((error: any) => error.sectionId === section.id)
                      .map((error: ValidationError, index: number) => {
                        return (
                          <div
                            key={`${index}-${error.questionId}`}
                            className="see-error-Show"
                          >
                            <div className="see-error-wrp-list">
                              <div className="see-erros-label">
                                {index + 1}.{" "}
                                <HashLink
                                  to={`${window.location.pathname}#${error.questionId}`}
                                >
                                  {error.questionLabel}
                                </HashLink>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                </li>
              )
            );
            // );
          })}
      </ul>
    </>
  );
};
export default withRouter(SideBarSections);
