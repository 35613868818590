import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
  useContext
} from "react";
import {
  Question,
  UploadedFiles,
  ValidationError
} from "../QuestionComponent/types";
import {
  DataSetCustomTitle,
  DataSetRecord,
  DatasetQuestionAnswer,
  DirectorOrShareholder,
  ImportInformationData,
  SavedQuestion
} from "./types";
import Icons from "../../presentation/Icons";
import CustomModal from "../../presentation/Modals/CustomModal";
import SectionComponent from "../SectionComponent";
import * as apis from "../../../apis/DataSet";
import { toast } from "react-toastify";
import * as utils from "../../../lib/utils";
import { useParams } from "react-router-dom";
import { Params } from "../CounterpartySubmittedQuestionnaire/types";
import ConfirmationModal from "../../presentation/Modals/ConfirmationModal";
import Accordion from "react-bootstrap/Accordion";
import {
  getDirectorOrShareholderColumnConfig,
  getFirstQuestion,
  hasErrorDataSetRecord,
  updateSectionsWithComment
} from "./service";
import { Section } from "../SectionComponent/types";
import { useTranslation } from "react-i18next";
import { QueryPrameters } from "../../../types/applicationData";
import CommonTable from "../../presentation/CommonTable";
import _ from "lodash";
import { QuestionnaireContext } from "../../../Contexts/QuestionnaireContext";
import { hasKyckrPermission } from "../../../lib/questionnairePermissionsAccess";
import CustomTitleComponent from "./CustomTitleComponent";

type Props = {
  queryParams?: QueryPrameters | null | undefined;
  list: DataSetRecord[];
  question: Question;
  isDisabled: boolean;
  sectionViewType: string;
  currentUserId: string | number;
  submittedQuestionnaireId?: string | number;
  showPublicComment?: boolean;
  showPrivateComment?: boolean;
  editPublicComment?: boolean;
  editPrivateComment?: boolean;
  dataSetSectionList?: Section[];
  itemIndex: string | number;
  onPageRefresh?: () => void;
  onChange: ({
    completePercentage
  }: {
    completePercentage: number | undefined;
  }) => void;
  validationErrors?: ValidationError[];
};

const DataSet: React.FC<Props> = ({
  queryParams,
  list,
  isDisabled,
  question,
  sectionViewType,
  currentUserId,
  submittedQuestionnaireId,
  showPublicComment,
  showPrivateComment,
  editPublicComment,
  editPrivateComment,
  dataSetSectionList,
  itemIndex,
  onPageRefresh,
  onChange,
  validationErrors
}) => {
  const { t } = useTranslation();
  const [records, setRecords] = useState<DataSetRecord[]>([]);
  const [showDataSetModal, setShowDataSetModal] = useState<boolean>(false);
  const [datasetSection, setDatasetSection] = useState<any>();
  const { accountQuestionnaireId, sectionId }: Params = useParams<any>();
  const [collapseId, setCollapseId] = useState<any>();
  const activeDatasetIndex = useRef<string | null | undefined>();
  const [dataSetCustomTitle, setDataSetCustomTitle] =
    useState<DataSetCustomTitle>();
  const queryParamRef = useRef<QueryPrameters | null | undefined>();
  const [completePercentage, setCompletePercentage] = useState<number>();
  const [currentDatasetQuestionAnswerId, setCurrentDatasetQuestionAnswerId] =
    useState<string | number>("");
  const [confirmationBoxData, setConfirmationBoxData] = useState({
    title: "",
    message: "",
    callBack: () => {}
  });
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [questionsAnswered, setQuestionsAnswered] = useState<SavedQuestion[]>(
    []
  );
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [importInfoData, setImportInfoData] = useState<ImportInformationData>();
  const sharableRows = useRef<Array<string | number>[] | undefined>([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [datasetQuestionAnswer] = useState<DatasetQuestionAnswer>({
    questionId: question.id,
    questionBankId: question.questionBankId,
    answer: "",
    answerId: "",
    questionType: question.fieldType.name
  });

  const mappedDataSetSection = useMemo(
    () =>
      question &&
      dataSetSectionList?.length &&
      dataSetSectionList.find(
        (section) =>
          String(section.id) ===
          String(question.fieldValues[0]?.sectionId || "")
      ),
    [question, dataSetSectionList]
  );
  const datasetFillIndex = useRef<string | number>();
  const { questionnaire } = useContext(QuestionnaireContext);

  useEffect(() => {
    if (completePercentage) {
      onChange({
        completePercentage: completePercentage
      });
    }
  }, [completePercentage]);
  const externalCompanyColumnConfig = React.useMemo(
    () =>
      getDirectorOrShareholderColumnConfig({
        externalCompaniesList: importInfoData?.externalCompaniesList,
        t: t
      }),
    [importInfoData]
  );

  const onBulkSelect = useCallback(
    (list: DirectorOrShareholder[], id: number) => {
      let tempArr = list?.map((item: any) => item.values.id) || [];
      if (sharableRows.current) sharableRows.current[id] = tempArr;
    },
    []
  );
  function onFieldValChange({
    ques,
    answer,
    additionalInfo,
    updatedSection,
    companySearchQuestions,
    datasetQuestionAnswerId,
    datasetTitle
  }: {
    ques: Question;
    answer: any;
    additionalInfo?: any;
    updatedSection: any;
    companySearchQuestions?: any;
    datasetQuestionAnswerId?: string | number;
    datasetTitle?: string;
  }) {
    if (ques.fieldType.name !== "file" && answer == null) return false;
    let quesObj: SavedQuestion = {
      questionId: ques.id,
      answer: answer,
      questionType: ques.fieldType.name,
      questionBankId: ques.questionBankId,
      answerId: ques.answerDetails.id
    };

    // if (question.fieldType.name === "file") {
    //   if (additionalInfo && additionalInfo.datasetQuestionAnswerId) {
    //     setCurrentDatasetQuestionAnswerId(
    //       additionalInfo.datasetQuestionAnswerId
    //     );
    //   }
    // } else {
    let arr = [...questionsAnswered];
    const index = arr.findIndex(
      (val: SavedQuestion) => val.questionId === ques.id
    );

    if (!companySearchQuestions?.length) {
      if (index === -1) {
        arr.push(quesObj);
      } else {
        arr[index] = quesObj;
      }
    } else {
      arr = _.unionBy([...companySearchQuestions], arr, "questionId");
    }
    if (datasetQuestionAnswerId) {
      setCurrentDatasetQuestionAnswerId(datasetQuestionAnswerId);
    }
    if (datasetTitle) {
      setDataSetCustomTitle({
        title: datasetTitle,
        description: "",
        isDisabled: false
      });
    }

    setQuestionsAnswered(arr);
    setDatasetSection({ ...updatedSection });
    //  }
  }
  async function getDatasetForReview(record: DataSetRecord, index: number) {
    let existingDataSetDetails =
      records && records[index] ? records[index]["datasetSection"] : null;
    let dummyRecords: any;
    if (existingDataSetDetails) {
      dummyRecords = [...records];
      dummyRecords[index] = {
        ...dummyRecords[index],
        datasetSection: existingDataSetDetails
      };
    } else {
      dummyRecords = [...list];
      let datasetRecord: any = await fetchDatasetDetails(
        record.datasetQuestionAnswerId
      );

      dummyRecords[index] = {
        ...dummyRecords[index],
        datasetSection: datasetRecord
      };
    }
    setRecords([...dummyRecords]);
  }
  function showDatasetList() {
    if (isDisabled) {
      switch (sectionViewType) {
        case utils.BUILD_REVIEW:
          return mappedDataSetSection ? (
            <Accordion key="dataset-build-review" className="m-r-10 m-t-10">
              <div className="card card--accordion-list card--inner">
                <Accordion.Toggle
                  as="div"
                  eventKey="dataset-build-review"
                  className="card__accordion-header card-onclick"
                  data-toggle="collapse"
                  onClick={(event: any) => {
                    event.stopPropagation();
                  }}
                >
                  <div className="accordion__header">
                    <div className="modal__list-item">
                      <div className="accordion__title">
                        <a
                          className={`card-onclick__target`}
                          aria-expanded="true"
                        >
                          <h4 className="modal__detail text-secondary">
                            {mappedDataSetSection.title}
                          </h4>
                        </a>
                      </div>
                    </div>
                  </div>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="dataset-build-review">
                  <div className="questionnaire__body">
                    <SectionComponent
                      queryParams={queryParamRef.current}
                      itemId={`${itemIndex}.1`}
                      section={mappedDataSetSection}
                      isParent={true}
                      showAnswers={true}
                      sectionViewType={sectionViewType}
                      allowAnswerSubmit={false}
                      currentUserId={currentUserId}
                      submittedQuestionnaireId={submittedQuestionnaireId}
                      //This is done because create public private comments api needs accountQuesId when submitted ques id is not present
                      accountQuestionnaireId={
                        !submittedQuestionnaireId
                          ? accountQuestionnaireId
                          : undefined
                      }
                      showPublicComment={showPublicComment}
                      showPrivateComment={showPrivateComment}
                      editPublicComment={editPublicComment}
                      editPrivateComment={editPrivateComment}
                      updateParent={(updatedSection) =>
                        setRecords(
                          updateSectionsWithComment({
                            records: records,
                            section: updatedSection
                          })
                        )
                      }
                      // onFieldValChange={
                      //   ({
                      //     ques,
                      //     answer,
                      //     additionalInfo,
                      //     updatedSection
                      //   }) => {} //onFieldValChange()
                      // }
                      fileParams={{
                        accountLevelOneQuestionnaireId: accountQuestionnaireId
                      }}
                      datasetQuestionAnswerId={currentDatasetQuestionAnswerId}
                      //validationErrors={errors}
                      //showErrors={true}
                    />
                  </div>
                </Accordion.Collapse>
              </div>
            </Accordion>
          ) : (
            <div style={{ color: "red" }}>
              *{t("No dataset section has been mapped to this question")}
            </div>
          );
        case utils.REVIEW:
        default:
          return records?.length > 0 ? (
            <form className="form response">
              {records.map((record: DataSetRecord, index: number) => {
                return (
                  <Accordion
                    activeKey={activeDatasetIndex.current || undefined}
                    // as="div"
                    key={index}
                    onSelect={(value) => {
                      activeDatasetIndex.current = value;
                      queryParamRef.current = null;
                      if (value) {
                        getDatasetForReview(record, index);
                      }
                    }}
                    className="m-r-10 m-t-10"
                  >
                    <div className="card card--accordion-list card--inner">
                      <Accordion.Toggle
                        as="div"
                        eventKey={index.toString()}
                        className="card__accordion-header card-onclick"
                        data-toggle="collapse"
                        onClick={(event: any) => {
                          event.stopPropagation();
                          setCollapseId((collapseId: any) =>
                            String(collapseId) !==
                            String(record.datasetQuestionAnswerId)
                              ? record.datasetQuestionAnswerId
                              : ""
                          );
                        }}
                      >
                        <div className="accordion__header">
                          <div className="modal__list-item">
                            <div className="accordion__title">
                              <a
                                className={`card-onclick__target ${
                                  String(collapseId) ===
                                  String(record.datasetQuestionAnswerId)
                                    ? "open"
                                    : "collapsed"
                                }`}
                                aria-expanded="true"
                              >
                                <h4 className="modal__detail text-secondary">
                                  {record.datasetTitle}
                                </h4>
                              </a>
                            </div>
                          </div>
                        </div>
                      </Accordion.Toggle>

                      <Accordion.Collapse eventKey={index.toString()}>
                        {record.datasetSection ? (
                          <div className="questionnaire__body">
                            <SectionComponent
                              queryParams={queryParamRef.current}
                              itemId={`${itemIndex}.${index + 1}`}
                              section={record.datasetSection}
                              isParent={true}
                              showAnswers={true}
                              sectionViewType={sectionViewType}
                              allowAnswerSubmit={false}
                              currentUserId={currentUserId}
                              submittedQuestionnaireId={
                                submittedQuestionnaireId
                              }
                              //This is done because create public private comments api needs accountQuesId when submitted ques id is not present
                              accountQuestionnaireId={
                                !submittedQuestionnaireId
                                  ? accountQuestionnaireId
                                  : undefined
                              }
                              showPublicComment={showPublicComment}
                              showPrivateComment={showPrivateComment}
                              editPublicComment={editPublicComment}
                              editPrivateComment={editPrivateComment}
                              updateParent={(updatedSection) =>
                                setRecords(
                                  updateSectionsWithComment({
                                    records: records,
                                    section: updatedSection
                                  })
                                )
                              }
                              // onFieldValChange={
                              //   ({
                              //     ques,
                              //     answer,
                              //     additionalInfo,
                              //     updatedSection
                              //   }) => {} //onFieldValChange()
                              // }
                              fileParams={{
                                accountLevelOneQuestionnaireId:
                                  accountQuestionnaireId
                              }}
                              datasetQuestionAnswerId={
                                record.datasetQuestionAnswerId
                              }
                              //validationErrors={errors}
                              //showErrors={true}
                            />
                          </div>
                        ) : (
                          <div>{t("Loading")}...</div>
                        )}
                      </Accordion.Collapse>
                    </div>
                  </Accordion>
                );
              })}
            </form>
          ) : (
            <div>{t("No datasets present")}</div>
          );
      }
    } else {
      return (
        <>
          <div className="response-row response-row--input">
            <div>
              {records.length > 0 &&
                records.map((record: DataSetRecord, index: number) => {
                  return (
                    <div className="action-group" key={index}>
                      {hasErrorDataSetRecord({ validationErrors, record }) && (
                        <div className="d-flex align-items-center">
                          <Icons icon="error" />
                        </div>
                      )}
                      <div className="action action--view mr-1 align-items-center">
                        <span className="fixed-filename">
                          {record.datasetTitle}
                        </span>
                      </div>
                      <div
                        className="action action--view"
                        onClick={() => {
                          onEdit(record.datasetQuestionAnswerId, index);
                        }}
                      >
                        <Icons className="icon" icon="edit" />
                      </div>
                      <div
                        className="action action--view"
                        onClick={() => onDelete(record.datasetQuestionAnswerId)}
                      >
                        <Icons className="icon" icon="delete" />
                      </div>
                    </div>
                  );
                })}
              <button
                id="add-details"
                className="btn btn-primary mt-3"
                type="button"
                onClick={() => addDetails()}
              >
                {t("Add Details")}
              </button>
              {questionnaire?.allowExternalInfoRetrieval &&
                question.fieldValues.length &&
                question.fieldValues[0].datasetSection
                  ?.allowExternalInfoRetrieval &&
                hasKyckrPermission({
                  permissions: questionnaire?.sectionDetail?.permissions || [],
                  permissionType: "DirectorShareholderSearch"
                }) && (
                  <button
                    id="import-information"
                    className="btn btn-outline-primary mt-3"
                    type="button"
                    onClick={() => showImportInformation()}
                  >
                    {t("Import Information")}
                  </button>
                )}
            </div>
            <div className="response__cell response__cell--deductions"></div>
            {(showPublicComment || showPrivateComment) && (
              <div className="response__cell response__cell--actions"></div>
            )}
          </div>
          {datasetSection && (
            <CustomModal
              datasetTitle={customTitle}
              modalTitle={datasetSection.title}
              classNames="cdd-groups-management cdd-auto-feature editable"
              showModal={showDataSetModal}
              onClose={() => {
                queryParamRef.current = null;
                setShowDataSetModal(false);
                setDatasetSection(null);
                setErrors([]);
                onPageRefresh && onPageRefresh();
              }}
            >
              <div className="form response">
                <SectionComponent
                  queryParams={queryParamRef.current}
                  itemId={`${itemIndex}.${datasetFillIndex.current}`}
                  section={datasetSection}
                  isParent={true}
                  showAnswers={true}
                  allowAnswerSubmit={true}
                  sectionViewType={sectionViewType}
                  currentUserId={currentUserId}
                  submittedQuestionnaireId={submittedQuestionnaireId}
                  //This is done because create public private comments api needs accountQuesId when submitted ques id is not present
                  accountQuestionnaireId={
                    !submittedQuestionnaireId
                      ? accountQuestionnaireId
                      : undefined
                  }
                  showPublicComment={showPublicComment}
                  showPrivateComment={showPrivateComment}
                  editPublicComment={editPublicComment}
                  editPrivateComment={editPrivateComment}
                  updateParent={(updatedSection) =>
                    setDatasetSection({ ...updatedSection })
                  }
                  onFieldValChange={({
                    ques,
                    answer,
                    additionalInfo,
                    updatedSection,
                    companySearchQuestions,
                    datasetQuestionAnswerId,
                    datasetTitle
                  }) => {
                    onFieldValChange({
                      ques,
                      answer,
                      additionalInfo,
                      updatedSection,
                      companySearchQuestions,
                      datasetQuestionAnswerId,
                      datasetTitle
                    });
                  }}
                  fileParams={{
                    accountLevelOneQuestionnaireId: accountQuestionnaireId
                  }}
                  datasetQuestionAnswerId={currentDatasetQuestionAnswerId}
                  datasetQuestionAnswer={datasetQuestionAnswer}
                  validationErrors={errors}
                  showErrors={true}
                />
              </div>
              <div className="align-right">
                <button
                  id="cancel-details"
                  className="btn btn-primary mt-3"
                  type="button"
                  onClick={() => onClose()}
                >
                  {t("Cancel")}
                </button>
                <button
                  id="save-details"
                  className="btn btn-primary mt-3"
                  type="button"
                  disabled={saving}
                  onClick={() => !saving && onSave()}
                >
                  {t("Save")}
                </button>
              </div>
            </CustomModal>
          )}
        </>
      );
    }
  }
  function addDetails() {
    setQuestionsAnswered([]);
    setCurrentDatasetQuestionAnswerId("DATASET_ADD");
    apis
      .getDataSetDetails("", question.id, accountQuestionnaireId, sectionId)
      .then((response: any) => {
        setDatasetSection(response?.data.datasetSectionDetails);
      })
      .catch((error: any) => {
        toast.error(utils.formatGraphQLError(error.message));
      });
    datasetFillIndex.current = Number(records?.length || 0) + 1;
    setShowDataSetModal(true);
  }
  function showImportInformation() {
    apis
      .getExternalCompaniesList({ accountQuestionnaireId })
      .then((response: any) => {
        if (response.errors?.length) {
        } else {
          setImportInfoData({
            showModal: true,
            externalCompaniesList: response.data.getExternalCompaniesList || []
          });
        }
      })
      .catch((error: any) => {});
  }
  async function fetchDatasetDetails(datasetQuestionAnswerId: string | number) {
    return apis
      .getDataSetDetails(
        datasetQuestionAnswerId,
        question.id,
        accountQuestionnaireId,
        sectionId
      )
      .then((response: any) => {
        return response?.data.datasetSectionDetails;
      })
      .catch((error: any) => {
        toast.error(utils.formatGraphQLError(error.message));
      });
  }
  async function onEdit(
    datasetQuestionAnswerId: string | number,
    index: number
  ) {
    datasetFillIndex.current = index + 1;
    setQuestionsAnswered([]);
    setCurrentDatasetQuestionAnswerId(datasetQuestionAnswerId);
    let datasetRecord = await fetchDatasetDetails(datasetQuestionAnswerId);
    setDataSetCustomTitle({
      title: dataSetCustomTitle?.title || datasetRecord.datasetTitle,
      description: datasetRecord.description,
      isDisabled: false
    });
    setDatasetSection(datasetRecord);

    setShowDataSetModal(true);
  }

  function onDelete(datasetQuestionAnswerId: string | number) {
    setConfirmationBoxData(
      Object.assign(
        {},
        {
          title: "Confirm Delete",
          message: "Are you sure want to delete this record?",
          callBack: () => {
            setShowConfirmationModal(false);
            apis
              .deleteDataSet({
                datasetQuestionAnswerId,
                sectionId,
                accountQuestionnaireId
              })
              .then((response: any) => {
                setDataSetRecord(datasetQuestionAnswerId, "delete");
                setCompletePercentage(
                  response?.data?.deleteDatasetAnswer?.completePercentage
                );
              })
              .catch((error: any) => {
                toast.error(utils.formatGraphQLError(error.message));
              });
          }
        }
      )
    );
    setShowConfirmationModal(true);
  }

  function saveExternalDatasets() {
    setSaving(true);
    if (!sharableRows.current?.length) {
      toast.error(t("Please select a Director/Shareholder"));
      return;
    }
    apis
      .saveExternalDataSet({
        sectionId,
        accountQuestionnaireId,
        datasetQuestionAnswer: {
          questionId: question.id,
          questionBankId: question.questionBankId,
          answer: "",
          answerId: "",
          questionType: question.fieldType.name
        },
        directorAndShareholderIds: _.compact(_.flatten(sharableRows.current))
      })
      .then((response: any) => {
        setSaving(false);
        let errors = response.data?.saveExternalDatasets.errors;
        if (errors && errors.length) {
          setErrors(errors);
          setShowErrorModal(true);
        }

        if (!errors?.length) {
          toast.success(response.data?.saveExternalDatasets.message);
          onPageRefresh && onPageRefresh();
        }
        onImportClose();
      })
      .catch((error: any) => {
        setErrors([]);
        toast.error(utils.formatGraphQLError(error.message));
        setSaving(false);
      });
  }
  function onImportClose() {
    setImportInfoData(undefined);
    sharableRows.current = [];
  }

  function onClose() {
    setShowDataSetModal(false);
    setDatasetSection(null);
    setErrors([]);
    onPageRefresh && onPageRefresh();
  }
  function onTitleChange({ target }: { target: any }) {
    setDataSetCustomTitle({ ...dataSetCustomTitle, title: target.value });
  }

  const customTitle = (
    <CustomTitleComponent
      id={currentDatasetQuestionAnswerId}
      name={`dataset-title-${currentDatasetQuestionAnswerId}`}
      title={dataSetCustomTitle?.title || ""}
      description={dataSetCustomTitle?.description || ""}
      isDisabled={dataSetCustomTitle?.isDisabled}
      showError={dataSetCustomTitle?.showError}
      onChange={onTitleChange}
    />
  );

  function onSave() {
    setSaving(true);
    let datasectionTitle = "";
    const firstQuestion = getFirstQuestion({ section: datasetSection });
    if (firstQuestion) {
      let firstAnswer = questionsAnswered.find(
        (question) => question.questionId === firstQuestion.id
      )?.answer;
      if (firstAnswer) {
        switch (firstQuestion.fieldType.name) {
          case "select_multiple":
          case "checkbox":
            firstAnswer = JSON.parse(firstAnswer).join(", ");
            break;
          case "file":
            firstAnswer = JSON.parse(firstAnswer)
              .map((file: UploadedFiles) => file.originalFileName)
              .join(", ");
            break;
        }
        datasectionTitle = datasectionTitle || firstAnswer;
      }
    }
    apis
      .saveDataSet({
        sectionId,
        accountQuestionnaireId,
        datasetQuestionAnswerId:
          currentDatasetQuestionAnswerId === "DATASET_ADD"
            ? ""
            : currentDatasetQuestionAnswerId,
        datasetQuestionAnswer: {
          questionId: question.id,
          questionBankId: question.questionBankId,
          answer: "",
          answerId: "",
          questionType: question.fieldType.name
        },
        questionAnswers: questionsAnswered,
        datasetTitle: dataSetCustomTitle?.title || datasectionTitle
      })
      .then((response: any) => {
        setSaving(false);
        let errors = response?.data.saveDatasetAnswers.errors;
        let datasetQuestionAnswerId =
          response?.data.saveDatasetAnswers.datasetQuestionAnswerId;
        if (errors && errors.length) {
          setErrors(errors);
        }
        setCompletePercentage(
          response?.data?.saveDatasetAnswers?.completePercentage
        );
        if (datasetQuestionAnswerId) {
          setCurrentDatasetQuestionAnswerId(datasetQuestionAnswerId);
          if (!errors?.length) {
            let record = {
              datasetQuestionAnswerId:
                response?.data.saveDatasetAnswers.datasetQuestionAnswerId,
              datasetTitle: response?.data.saveDatasetAnswers.datasetTitle,
              datasetQuestionId: question.id
            };
            setDataSetRecord(datasetQuestionAnswerId, "save", record);
            onClose();
          }
        }
      })
      .catch((error: any) => {
        setErrors([]);
        toast.error(utils.formatGraphQLError(error.message));
        setSaving(false);
      });
  }

  function setDataSetRecord(
    datasetQuestionAnswerId: string | number,
    action: string,
    record?: DataSetRecord
  ) {
    let tempRecords = [...records];
    const index = tempRecords.findIndex(
      (record: DataSetRecord) =>
        record.datasetQuestionAnswerId === datasetQuestionAnswerId
    );

    if (action === "save" && record) {
      if (index === -1) {
        tempRecords.push(record);
      } else {
        tempRecords[index] = record;
      }
    }

    if (action === "delete") {
      tempRecords.splice(index, 1);
    }

    setRecords(tempRecords);
  }

  useEffect(() => {
    if (list && list.length) setRecords(list);
  }, [list]);
  useEffect(() => {
    queryParamRef.current = queryParams;
    if (queryParamRef?.current?.datasetId && list?.length) {
      let queryRecordIndex = list.findIndex(
        (record) =>
          String(record.datasetQuestionAnswerId) ===
          String(queryParamRef?.current?.datasetId)
      );
      if (queryRecordIndex !== -1) {
        if (isDisabled) {
          setCollapseId(queryParamRef.current.datasetId);
          activeDatasetIndex.current = String(queryRecordIndex);
          getDatasetForReview(list[queryRecordIndex], queryRecordIndex);
        } else {
          onEdit(queryParamRef?.current?.datasetId, queryRecordIndex);
        }
      }
    }
  }, [queryParams, list, isDisabled]);
  return (
    <>
      {showDatasetList()}

      {/* Modal for confirmation box start */}
      {records.length > 0 && (
        <ConfirmationModal
          modalTitle={confirmationBoxData.title}
          message={confirmationBoxData.message}
          callBack={confirmationBoxData.callBack}
          showConfirmationModal={showConfirmationModal}
          onModalClose={() => {
            setShowConfirmationModal(false);
          }}
        />
      )}
      {importInfoData && (
        <CustomModal
          modalTitle={t("Import Information")}
          showModal={importInfoData.showModal || false}
          onClose={onImportClose}
          showFooter={true}
          footer={
            importInfoData.externalCompaniesList?.length ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    onImportClose();
                  }}
                >
                  {t("Cancel")}
                </button>

                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    saveExternalDatasets();
                  }}
                >
                  {t("Confirm")}
                </button>
              </div>
            ) : (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    onImportClose();
                  }}
                >
                  {t("Close")}
                </button>
              </div>
            )
          }
        >
          {importInfoData.externalCompaniesList?.length ? (
            importInfoData.externalCompaniesList.map(
              (externalCompany, index) => {
                return (
                  !!externalCompany.directorsAndShareholders.length && (
                    <>
                      <div className="d-flex justify-content-between align-items-center">
                        <h3>{externalCompany.name}</h3>
                        <div className="d-flex align-items-center">
                          <h4 className="mr-2">{t("Reg.-No.")}</h4>
                          <h4>{externalCompany.companyCode}</h4>
                        </div>
                      </div>
                      <CommonTable
                        key={`table-${index}`}
                        id={index}
                        className={`table common-table-new filter-table`}
                        gridData={externalCompany.directorsAndShareholders}
                        columnConfig={externalCompanyColumnConfig}
                        pageIndex={0}
                        pageSize={10}
                        defaultPageSize={10}
                        totalRecordsCount={
                          externalCompany.directorsAndShareholders.length
                        }
                        selectedCheckbox
                        hiddenColumns={["id"]}
                        onBulkSelect={onBulkSelect}
                      ></CommonTable>
                      <p className="user-basic-detail-in text-dark py-2 mb-0">
                        {`${t("Data imported on")} ${utils.getDate(
                          externalCompany.updatedAt,
                          "DD/MM/YYYY"
                        )}. ${t("To update data, repeat company search.")}`}

                        <br />
                        <span className="font-italic">
                          {t(
                            "Shareholder Type: C = Company, P = Natural Person, O = Other"
                          )}
                        </span>
                      </p>
                      <div className="border-bottom mb-3"></div>
                    </>
                  )
                );
              }
            )
          ) : (
            <p className="mb-0">{t("No information can be imported")}</p>
          )}
        </CustomModal>
      )}
      <CustomModal
        modalTitle={t("Error")}
        showModal={showErrorModal}
        onClose={() => {
          setShowErrorModal(false);
        }}
        showFooter={true}
        footer={
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <button
              className="btn btn-outline-primary"
              onClick={() => {
                onPageRefresh && onPageRefresh();
                setShowErrorModal(false);
              }}
            >
              {t("Close")}
            </button>
          </div>
        }
      >
        <p>
          {t(
            `Some mandatory fields could not be completed during the creation of the datasets. Please go back and edit the Mandatory`
          )}
          <span className="mandatory-question ml-0" title="mandatory">
            *
          </span>
          {t(` questions and click on ‘Save’ before submitting the section.`)}
        </p>
      </CustomModal>
    </>
  );
};

export default DataSet;
