import gql from "graphql-tag";
export const GET_RISK_COUNTRIES_QUERY = gql`
  query riskCountries {
    riskCountries {
      germanName
      id
      name
      code
      states {
        id
        name
        code
      }
    }
  }
`;
export const GET_RISK_INDUSTRIES_QUERY = gql`
  query riskIndustries {
    riskIndustries {
      germanName
      id
      name
    }
  }
`;
export const GET_RISK_LEGAL_FORMS_QUERY = gql`
  query riskLegalForms {
    riskLegalForms {
      germanName
      id
      name
    }
  }
`;
export const GET_QUESTIONNAIRE_RISK_LEVELS = gql`
  query GetQuestionnaireRiskLevels {
    questionnaireRiskLevels {
      id
      label
      name
    }
  }
`;
