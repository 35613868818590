import React, { useCallback, useContext, useState } from "react";
import CompanySearchComponent from "./CompanySearchComponent";
import QuestionComponent from "../QuestionComponent";
import {
  SubQuestion,
  Question,
  ValidationError
} from "../QuestionComponent/types";
import { deductionDetails } from "../../presentation/Deductions/types";
import { Section } from "../SectionComponent/types";
import { QueryPrameters } from "../../../types/applicationData";
import { getUpdatedQuestion } from "../QuestionComponent/services";
import ConfirmationModal from "../../presentation/Modals/ConfirmationModal";
import { SavedQuestion } from "../CounterpartySubmittedQuestionnaire/types";
import { hasKyckrPermission } from "../../../lib/questionnairePermissionsAccess";
import { QuestionnaireContext } from "../../../Contexts/QuestionnaireContext";

type Props = {
  queryParams?: QueryPrameters | null | undefined;
  itemIndex?: number | string;
  showComments?: (commentData: any | null) => void;
  showPublicComment?: boolean;
  showPrivateComment?: boolean;
  editPublicComment?: boolean;
  editPrivateComment?: boolean;
  updateParent?: (question: Question, companyDetails?: SubQuestion[]) => void;
  isSubQuestion?: boolean;
  isInParentSection?: boolean;
  indexKey?: number;
  hiddenSections?: any;
  showAnswers?: boolean;
  sectionViewType: string;
  allowAnswerSubmit?: boolean;
  deductionDetails?: deductionDetails;
  validationErrors?: ValidationError[];
  showErrors?: boolean;
  submittedQuestionnaireId?: string | number;
  accountQuestionnaireId?: string | number;
  currentUserId: string | number;
  onFieldValChange?: ({
    ques,
    answer,
    additionalInfo,
    updatedQuestion,
    instantSave,
    companySearchQuestions,
    datasetQuestionAnswerId,
    datasetTitle
  }: {
    ques: Question;
    answer: any;
    additionalInfo?: any;
    updatedQuestion: Question;
    instantSave?: boolean;
    companySearchQuestions?: any;
    datasetQuestionAnswerId?: string | number;
    datasetTitle?: string;
  }) => void;
  fileParams?: any;
  dataSetSectionList?: Section[];
  datasetQuestionAnswerId?: string | number;
  datasetQuestionAnswer?: any;
  questions: SubQuestion[];
  questionId: number | string;
  parentQuestion: Question;
};

const CompanySearchApiComponent: React.FC<Props> = ({
  queryParams,
  itemIndex,
  questions,
  questionId,
  showComments,
  showPublicComment,
  showPrivateComment,
  editPublicComment,
  editPrivateComment,
  updateParent,
  isSubQuestion,
  isInParentSection,
  indexKey,
  hiddenSections,
  showAnswers,
  sectionViewType,
  allowAnswerSubmit,
  deductionDetails,
  validationErrors,
  showErrors,
  onFieldValChange,
  submittedQuestionnaireId,
  accountQuestionnaireId,
  currentUserId,
  fileParams,
  dataSetSectionList,
  datasetQuestionAnswerId,
  datasetQuestionAnswer,
  parentQuestion
}) => {
  const [interactionMode, setInteractionMode] = useState<string>("view");

  const saveCompanyDetails = useCallback(
    (companyDetails: any) => {
      const questionsMapping = companyDetails.mappings
        ? companyDetails.mappings
        : companyDetails;
      if (questionsMapping.length) {
        const savedCompanyQuestions: SavedQuestion[] = [];
        questions.forEach((subQuestion: SubQuestion) => {
          const companyQ = questionsMapping.find(
            (companyQ: any) => companyQ.question.id === subQuestion.question.id
          );
          if (companyQ) {
            const answerValue =
              typeof companyQ.answer === "object"
                ? companyQ.answer?.answer ?? ""
                : companyQ.answer ?? "";
            let quesObj: SavedQuestion = {
              questionId: subQuestion.question.id,
              answer: answerValue,
              questionType: subQuestion.question.fieldType.name,
              questionBankId: subQuestion.question.questionBankId,
              answerId: subQuestion.question.answerDetails.id
            };
            savedCompanyQuestions.push(quesObj);
          }
        });

        onFieldValChange &&
          onFieldValChange({
            ques: parentQuestion,
            updatedQuestion: getUpdatedQuestion({
              question: parentQuestion,
              answer: parentQuestion.answerDetails.answer || ""
            }),
            answer: parentQuestion.answerDetails.answer || "",
            instantSave: true,
            companySearchQuestions: savedCompanyQuestions,
            datasetQuestionAnswerId: companyDetails.datasetQuestionAnswerId,
            datasetTitle: companyDetails.datasetTitle
          });
        updateParent && updateParent(parentQuestion, questionsMapping);
      }
    },
    [questions, onFieldValChange, updateParent, parentQuestion]
  );

  const [confirmationBoxData, setConfirmationBoxData] = useState({
    title: "",
    message: "",
    callBack: () => {},
    showConfirmButton: true
  });
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const answerDetails = parentQuestion.answerDetails?.answer
    ? JSON.parse(parentQuestion.answerDetails.answer)
    : "";
  const { questionnaire } = useContext(QuestionnaireContext);

  function onEdit() {
    setConfirmationBoxData(
      Object.assign(
        {},
        {
          title: "Confirmation",
          message:
            "Are you sure you want to edit the information pre filled from the search?",
          callBack: () => {
            setShowConfirmationModal(false);
            setInteractionMode("edit");
          },
          showConfirmButton: true
        }
      )
    );
    setShowConfirmationModal(true);
  }

  return (
    <>
      <div className="border-bottom pb-4 mb-4 mr-4 mt-1">
        {/* {interactionMode === "view" && allowAnswerSubmit && (
          <button
            className="btn btn-primary font-weight-400 mb-4"
            onClick={() => setInteractionMode("searchCompany")}
          >
            Search for Company Information
          </button>
        )} */}
        {allowAnswerSubmit &&
        hasKyckrPermission({
          permissions: questionnaire?.sectionDetail?.permissions || [],
          permissionType: "CompanySearch"
        }) ? (
          <CompanySearchComponent
            onCompanySelection={saveCompanyDetails}
            questionId={questionId}
            datasetQuestionAnswerId={datasetQuestionAnswerId}
            datasetQuestionAnswer={datasetQuestionAnswer}
          />
        ) : (
          ""
        )}
      </div>
      {/* {allowAnswerSubmit && interactionMode !== "searchCompany" && (
        <div className="d-flex flex-end">
          <span
            onClick={() => {
              if (interactionMode === "view") {
                onEdit();
              } else {
                setInteractionMode("view");
              }
            }}
          >
            <Icons
              className="mr-10 cursor-pointer"
              icon={interactionMode === "view" ? "circleEdit" : "circleSave"}
            />
          </span>
          {interactionMode === "edit" && (
            <span onClick={() => setInteractionMode("view")}>
              <Icons className="mr-10 cursor-pointer" icon="circleClose" />
            </span>
          )}
        </div>
      )} */}
      <>
        {questions.length &&
          []
            .concat(questions as any)
            .sort((a: SubQuestion, b: SubQuestion) => {
              return a.question.position - b.question.position;
            })
            .map((ques: SubQuestion, index: number) => {
              let subQItemIndex = itemIndex + "." + (index + 1);
              return (
                <QuestionComponent
                  key={index}
                  queryParams={queryParams}
                  itemIndex={subQItemIndex}
                  question={ques.question}
                  showComments={showComments}
                  showPublicComment={showPublicComment}
                  showPrivateComment={showPrivateComment}
                  editPublicComment={editPublicComment}
                  editPrivateComment={editPrivateComment}
                  submittedQuestionnaireId={submittedQuestionnaireId}
                  accountQuestionnaireId={accountQuestionnaireId}
                  currentUserId={currentUserId}
                  updateParent={updateParent}
                  validationErrors={validationErrors}
                  showErrors={showErrors}
                  showAnswers={showAnswers}
                  allowAnswerSubmit={
                    // allowAnswerSubmit && interactionMode === "edit"
                    // allowAnswerSubmit && !ques.question.apiFieldMappingId
                    allowAnswerSubmit
                  }
                  sectionViewType={sectionViewType}
                  isSubQuestion={true}
                  isInParentSection={true}
                  hiddenSections={hiddenSections}
                  deductionDetails={deductionDetails}
                  onFieldValChange={onFieldValChange}
                  fileParams={{
                    ...fileParams,
                    ...{ questionId: ques.question.id }
                  }}
                  dataSetSectionList={dataSetSectionList}
                  datasetQuestionAnswerId={datasetQuestionAnswerId}
                  isCompanySearchQuestionParent={true}
                  searchedCompanyDetails={answerDetails}
                />
              );
            })}
        <ConfirmationModal
          modalTitle={confirmationBoxData.title}
          message={confirmationBoxData.message}
          callBack={confirmationBoxData.callBack}
          showConfirmButton={confirmationBoxData.showConfirmButton}
          showConfirmationModal={showConfirmationModal}
          onModalClose={() => {
            setShowConfirmationModal(false);
          }}
        />
      </>
    </>
  );
};

export default CompanySearchApiComponent;
