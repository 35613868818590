import {
  Question,
  FieldValue,
  FieldType,
  SubQuestion,
  PublicPrivateCommentsType
} from "./types";
import { BUILD_REVIEW, REVIEW } from "../../../lib/utils";
export function getSelectDefaultValue(answer: any, options: any) {
  if (answer) {
    return answer.map((value: any) => {
      let obj = options.find((o: any) => o.value === value);
      return obj;
    });
  }
  return [];
}
export function showSubQuestions({
  answer,
  currentField,
  allowSubmit,
  fieldType,
  sectionViewType
}: {
  answer: string | string[];
  currentField: FieldValue;
  allowSubmit?: boolean;
  fieldType: FieldType;
  sectionViewType: string;
}): boolean {
  if (!allowSubmit && sectionViewType === BUILD_REVIEW) {
    return true;
  }

  if (
    fieldType.name === "checkbox" &&
    answer?.indexOf(currentField.value) !== -1
  ) {
    return true;
  }
  if (
    fieldType.name === "radio_button" &&
    answer.toString() === currentField.value.toString()
  ) {
    return true;
  }
  return false;
}
export function updateAnswerInSubQuestion({
  subQuestions,
  fieldValue,
  subQuestionQuestion,
  parentQuestion,
  companyDetails
}: {
  subQuestions: SubQuestion[];
  fieldValue: FieldValue;
  subQuestionQuestion: Question;
  parentQuestion: Question;
  companyDetails?: SubQuestion[];
}) {
  let subQIndex = subQuestions.findIndex(
    (val: any) => val.question.id === subQuestionQuestion.id
  );
  let updatedSubQes = [...subQuestions];
  if (companyDetails?.length) {
    companyDetails.forEach((companyQ: any) => {
      updatedSubQes.forEach((subQuestion: SubQuestion, idx: number) => {
        const answerValue =
          typeof companyQ.answer === "object"
            ? companyQ.answer?.answer ?? ""
            : companyQ.answer ?? "";
        if (subQuestion.question.id === companyQ.question.id) {
          updatedSubQes[idx] = {
            ...updatedSubQes[idx],
            question: {
              ...updatedSubQes[idx].question,
              answerDetails: {
                ...updatedSubQes[idx].question.answerDetails,
                answer: answerValue,
                prefillInfo:
                  companyQ.answer?.prefillInfo ||
                  updatedSubQes[idx].question.answerDetails.prefillInfo
              },
              isEditable: false
            }
          };
        }
      });
    });
  }
  updatedSubQes[subQIndex] = {
    ...updatedSubQes[subQIndex],
    question: { ...subQuestionQuestion }
  };

  let fieldValueIndex = parentQuestion.fieldValues.findIndex(
    (val: any) => val.id === fieldValue.id
  );
  let updatedFieldValues = [...parentQuestion.fieldValues];
  updatedFieldValues[fieldValueIndex] = {
    ...updatedFieldValues[fieldValueIndex],
    subQuestions: [...updatedSubQes]
  };
  parentQuestion = { ...parentQuestion, fieldValues: [...updatedFieldValues] };

  return { ...parentQuestion };
}
export function getUpdatedQuestion({
  question,
  answer
}: {
  question: Question;
  answer: any;
}) {
  let hashedAnswerExists = false;
  try {
    const prefillInfo = JSON.parse(question?.answerDetails?.prefillInfo);
    hashedAnswerExists = !!prefillInfo?.hashed_answer;
  } catch (e) {
    console.error("Invalid JSON in prefillInfo", e);
  }
  if (
    hashedAnswerExists &&
    (!question.answerDetails?.answeredBy || !question.answerDetails?.answer)
  ) {
    return {
      ...question,
      answerDetails: {
        ...question.answerDetails,
        answer: answer,
        prefillInfo: null
      }
    };
  }
  return {
    ...question,
    answerDetails: { ...question.answerDetails, answer: answer }
  };
}
export function updateCommentsInAnswer(
  question: Question,
  comments: PublicPrivateCommentsType[]
) {
  return {
    ...question,
    answerDetails: {
      ...question.answerDetails,
      publicPrivateComments: comments?.length ? [...comments] : []
    }
  };
}
export function updateFieldValueScore({
  question,
  points,
  fieldValueId
}: {
  question: Question;
  points: number | string;
  fieldValueId: number | string;
}) {
  let fieldValues = [...question.fieldValues];
  let foundFieldValIndex = fieldValues.findIndex(
    (val) => Number(val.id) === Number(fieldValueId)
  );

  fieldValues[foundFieldValIndex] = {
    ...fieldValues[foundFieldValIndex],
    isDeductionNotesPresent: true,
    currentDeductions: Number(points)
  };
  return {
    ...question,
    fieldValues: [...fieldValues]
  };
}
