import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  inputType: string;
  min?: number;
  max?: number;
  id: number | string;
  placeholder?: string;
  value: string | number;
  isDisabled?: boolean;
  name: string;
  onChange?: ({ target }: { target: any }) => void;
}

const InputField: React.FC<Props> = ({
  inputType = "text",
  min,
  max,
  id,
  placeholder,
  value,
  isDisabled = false,
  name,
  onChange
}) => {
  const { t } = useTranslation();
  const [fieldVal, setFieldVal] = useState<string | number>(value);
  useEffect(() => {
    setFieldVal(value);
  }, [value]);
  function getInputType() {
    switch (inputType) {
      case "text_area":
        return (
          <textarea
            rows={6}
            id={`text_area-${id}`}
            name={name}
            className="form-control form-control--bordered mt-0"
            value={fieldVal || ""}
            onChange={(e) => {
              setFieldVal(e.target.value || "");
              onChange && onChange(e);
            }}
            placeholder={t(`${placeholder}`) || ""}
            disabled={isDisabled}
          />
        );
      case "number":
        return (
          <input
            id={`text-${id}`}
            type={inputType}
            className="form-control form-control--bordered mt-0"
            disabled={isDisabled}
            name={name}
            value={fieldVal && Number(fieldVal)}
            onChange={(e) => {
              setFieldVal(e.target.value || "");
              onChange && onChange(e);
            }}
            placeholder={t(`${placeholder}`) || ""}
            min={min}
            max={max}
          />
        );
      case "text":
      default:
        return (
          <input
            id={`text-${id}`}
            type={inputType}
            className="form-control form-control--bordered mt-0"
            disabled={isDisabled}
            name={name}
            value={fieldVal || ""}
            onChange={(e) => {
              setFieldVal(e.target.value || "");
              onChange && onChange(e);
            }}
            placeholder={t(`${placeholder}`) || ""}
          />
        );
    }
  }
  return <>{getInputType()}</>;
};
export default InputField;
