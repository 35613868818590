import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Params } from "../../CounterpartySubmittedQuestionnaire/types";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { object, string } from "yup";
import * as utils from "../../../../lib/utils";
import {
  RiskCountry,
  SearchCompany,
  LocationDetails,
  Company,
  CompanyDetails
} from "./types";
import MandatoryIcon from "../../../presentation/MandatoryIcon";
import * as apis from "../../../../apis/CompanySearch";
import Select from "react-select";
import { toast } from "react-toastify";
import { appData } from "../../../../services/appConfig";
import { getCompanyColumnConfig } from "./services";
import NoDataFound from "../../../presentation/NoDataFound";
import CommonTable from "../../../presentation/CommonTable";
import CustomModal from "../../../presentation/Modals/CustomModal";
import i18n from "../../../../i18n";
import { CompanyApiProfile } from "../../../../apis/CompanySearch/types";

type Props = {
  id?: string | number;
  questionId: string | number;
  onCompanySelection: any;
  datasetQuestionAnswerId?: string | number;
  datasetQuestionAnswer?: any;
};
const CompanySearchComponent: React.FC<Props> = ({
  id,
  questionId,
  onCompanySelection,
  datasetQuestionAnswerId,
  datasetQuestionAnswer
}) => {
  const { t } = useTranslation();
  const { accountQuestionnaireId, sectionId }: Params = useParams<any>();
  const [selectedCompany, setSelectedCompany] = useState<CompanyDetails>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [riskCountries, setRiskCountries] = useState<RiskCountry[]>([]);
  const [initialValues, setInitialValues] = useState<SearchCompany>({
    nameOfCompany: "",
    registrationNumber: "",
    country: { id: "", name: "", code: "", germanName: "", states: [] },
    state: { id: "", name: "", code: "" },
    nameOrRegistration: ""
  });
  const [companyList, setCompanyList] = useState<Company[]>();
  const [message] = useState<string>(
    "There are currently no company to display."
  );
  const [displayCompanyList, setDisplayCompanyList] = useState<boolean>();
  const formikContextRef = useRef<any>();
  const companyColumnConfig = React.useMemo(
    () => getCompanyColumnConfig({ companyList, t }),
    [companyList, t]
  );
  const validationSchema = object().shape({
    nameOfCompany: string(),
    registrationNumber: string(),
    country: object().shape({
      name: string().required("Please select Country")
    }),
    state: object().when(["country"], {
      is: (country: any) =>
        country && ["USA", "Canada"].indexOf(country.name) !== -1,
      then: object().shape({
        name: string().required(
          "State is required when the country is USA or Canada"
        )
      }),
      otherwise: object().notRequired()
    }),
    nameOrRegistration: string().when(["nameOfCompany", "registrationNumber"], {
      is: (nameOfCompany: any, registrationNumber: any) =>
        !nameOfCompany && !registrationNumber,
      then: string().required(t("Provide company name or register number")),
      otherwise: string().notRequired()
    })
  });

  const selectCompany = () => {
    if (selectedCompany) {
      const reqObj: CompanyApiProfile = {
        accountQuestionnaireId,
        sectionId,
        countryCode: selectedCompany.countryCode,
        stateCode: selectedCompany.stateCode,
        companyCode: selectedCompany.code || "",
        registrationAuthCode: selectedCompany.registrationAuthCode || ""
      };
      if (datasetQuestionAnswerId) {
        reqObj.datasetQuestionAnswerId =
          datasetQuestionAnswerId === "DATASET_ADD"
            ? ""
            : datasetQuestionAnswerId;
        reqObj.datasetQuestionAnswer = datasetQuestionAnswer;
        apis
          .getDatasetCompanyApiProfile(reqObj)
          .then((res) => {
            onCompanySelection &&
              onCompanySelection(res.data.getDatasetCompanyApiProfile);
            setShowModal(false);
            formikContextRef.current.resetForm();
            setDisplayCompanyList(false);
          })
          .catch((error) => {
            toast.error(utils.formatGraphQLError(error.message));
          });
        return;
      }
      reqObj.questionId = questionId;
      apis
        .getCompanyApiProfile(reqObj)
        .then((res) => {
          onCompanySelection &&
            onCompanySelection(res.data.getCompanyApiProfile);
          setShowModal(false);
          formikContextRef.current.resetForm();
          setDisplayCompanyList(false);
        })
        .catch((error) => {
          toast.error(utils.formatGraphQLError(error.message));
        });
    }
  };

  const getCountryOrStateDetail = useCallback(
    ({
      countryId,
      key,
      stateId
    }: {
      countryId: string | number;
      key: keyof LocationDetails;
      stateId?: string | number;
    }) => {
      const riskCountryFound = riskCountries.find(
        (riskCountry) => riskCountry.id === countryId
      );
      if (!riskCountryFound) return "";
      if (!stateId) return riskCountryFound[key];
      const stateFound = riskCountryFound.states.find(
        (state) => state.id === stateId
      );
      return stateFound ? stateFound[key] : "";
    },
    [riskCountries]
  );

  const handleSubmit = (values: SearchCompany, { setSubmitting }: any) => {
    if (!values) return;
    const countryCode = getCountryOrStateDetail({
      countryId: values.country?.id || "",
      key: "code"
    });
    const stateCode = getCountryOrStateDetail({
      countryId: values.country?.id || "",
      key: "code",
      stateId: values.state?.id
    });
    setDisplayCompanyList(false);
    if (values.nameOfCompany.length) {
      setSelectedCompany({
        ...selectedCompany,
        countryCode: countryCode || "",
        stateCode: stateCode || ""
      });
      apis
        .searchCompanyByName({
          countryCode,
          stateCode,
          searchTerm: values.nameOfCompany,
          accountQuestionnaireId,
          sectionId
        })
        .then((res) => {
          setCompanyList(res.data.searchCompany);
          setDisplayCompanyList(true);
        })
        .catch((error) => {
          toast.error(utils.formatGraphQLError(error.message));
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      setSelectedCompany({
        ...selectedCompany,
        countryCode: countryCode || "",
        stateCode: stateCode || ""
      });
      apis
        .searchCompanyByCode({
          countryCode: getCountryOrStateDetail({
            countryId: values.country?.id || "",
            key: "code"
          }),
          stateCode: getCountryOrStateDetail({
            countryId: values.country?.id || "",
            key: "code",
            stateId: values.state?.id
          }),
          companyCode: values.registrationNumber,
          accountQuestionnaireId,
          sectionId
        })
        .then((res) => {
          setCompanyList(res.data.searchCompany);
          setDisplayCompanyList(true);
        })
        .catch((error) => {
          toast.error(utils.formatGraphQLError(error.message));
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const showCompanySearchDefault = () => {
    return (
      <>
        <Formik
          enableReinitialize
          innerRef={formikContextRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            isSubmitting,
            isValid,
            dirty,
            setFieldValue,
            setValues
          }) => (
            <Form className="row row-15">
              <div className="form-group col-6 mb-3">
                <label
                  htmlFor="nameOfCompany"
                  className="ddd-input-3 pb-8 d-block"
                >
                  {t("Company")} <MandatoryIcon />
                </label>
                <Field
                  name="nameOfCompany"
                  type="text"
                  className={`form-control form-control--bordered ${
                    values.registrationNumber.length ? "mt-0 pl-3" : ""
                  }`}
                  placeholder={t("e.g. Berlin Hyp")}
                  onChange={(data: any) => {
                    setFieldValue("nameOfCompany", data.target.value);
                  }}
                  disabled={values.registrationNumber.length ? true : false}
                />
                <div className="mt-1" style={{ color: "red" }}>
                  <ErrorMessage name="nameOfCompany" />
                </div>
                <div className="mt-1" style={{ color: "red" }}>
                  <ErrorMessage name="nameOrRegistration" />
                </div>
              </div>
              <div className="form-group mb-3 pt-4">
                <b>{t("or")}</b>
              </div>
              <div className="form-group col">
                <label
                  htmlFor="registration_number"
                  className="ddd-input-3 pb-8 d-block"
                >
                  {t("Registration Number")} <MandatoryIcon />
                </label>
                <Field
                  name="registrationNumber"
                  type="text"
                  className={`form-control form-control--bordered ${
                    values.nameOfCompany.length ? "mt-0 pl-3" : ""
                  }`}
                  placeholder={t("e.g. HRB 56530")}
                  onChange={(data: any) => {
                    setFieldValue("registrationNumber", data.target.value);
                  }}
                  disabled={values.nameOfCompany.length ? true : false}
                />
                <div className="mt-1" style={{ color: "red" }}>
                  <ErrorMessage name="registrationNumber" />
                </div>
              </div>
              <div className="form-group col-6 mb-3">
                <label htmlFor="country" className="ddd-input-3 pb-8 d-block">
                  {t("Country")} <MandatoryIcon />
                </label>
                <Select
                  name="country"
                  options={utils.modifyForSelectQuestionBox(
                    riskCountries,
                    "id",
                    i18n.language === "de" ? "germanName" : "name",
                    "id"
                  )}
                  value={
                    values.country?.id && {
                      id: values.country.id,
                      label:
                        i18n.language === "de"
                          ? values.country.germanName
                          : values.country.name,
                      value:
                        i18n.language === "de"
                          ? values.country.germanName
                          : values.country.name
                    }
                  }
                  placeholder={`${t("Select")}...`}
                  onChange={(value) => {
                    value?.id &&
                      setValues({
                        ...values,
                        country: riskCountries.find(
                          (riskCountry) => riskCountry.id === value.id
                        ),
                        state: { id: "", name: "", code: "" }
                      });
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 })
                  }}
                />
                <div className="mt-1" style={{ color: "red" }}>
                  <ErrorMessage name="country.name" />
                </div>
              </div>
              <div className="opacity-0 mb-3 pt-4">
                <b>Or</b>
              </div>
              {!!values.country?.states?.length && (
                <div className="form-group col mb-3">
                  <label
                    htmlFor="state_or_province"
                    className="ddd-input-3 pb-8 d-block"
                  >
                    {t("State/Province")} <MandatoryIcon />
                  </label>
                  <Select
                    name="state"
                    options={utils.modifyForSelectBox(
                      values?.country?.states || [],
                      "id",
                      "name"
                    )}
                    placeholder={`${t("Select")}...`}
                    onChange={(value) => {
                      value?.id &&
                        setFieldValue(
                          "state",
                          values.country?.states.find(
                            (state) => state.id === value.id
                          )
                        );
                    }}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 })
                    }}
                  />
                  <div className="mt-1" style={{ color: "red" }}>
                    <ErrorMessage name="state.name" />
                  </div>
                </div>
              )}
              <div className="form-group col-12 mb-0">
                <button
                  className={`col-sm-auto btn btn-primary font-weight-400 ${
                    !(dirty && isValid) || isSubmitting ? "disabled" : ""
                  }`}
                  disabled={!(dirty && isValid) || isSubmitting}
                  type="submit"
                >
                  {t("Search")}
                </button>

                {/* <button
                  type="button"
                  className="btn btn-outline-primary font-weight-400"
                  onClick={onClose}
                >
                  Cancel
                </button> */}
              </div>
            </Form>
          )}
        </Formik>
        {displayCompanyList ? (
          <div className="pt-4 company-table">
            <h3 className="font-18">{t("Result")}</h3>
            {!!companyList?.length ? (
              <div className="company-table-scroll">
                <CommonTable
                  gridData={companyList}
                  columnConfig={companyColumnConfig}
                  pageIndex={0}
                  pageSize={10}
                  defaultPageSize={10}
                  totalRecordsCount={companyList.length}
                  hiddenColumns={["address"]}
                  onRowSelect={(row: any) => {
                    setSelectedCompany({
                      ...selectedCompany,
                      ...row?.original
                    });
                    setShowModal(true);
                  }}
                  tableRowClassName="font-weight-normal"
                />
              </div>
            ) : (
              <NoDataFound message={message} />
            )}
            {selectedCompany && (
              <CustomModal
                showModal={showModal || false}
                modalTitle={t("Confirmation")}
                onClose={() => {
                  setShowModal(false);
                }}
                showFooter={true}
                footer={
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <button className="btn btn-primary" onClick={selectCompany}>
                      {t("Confirm")}
                    </button>
                    <button
                      className="btn btn-outline-primary"
                      onClick={(e) => {
                        setShowModal(false);
                      }}
                    >
                      {t("Cancel")}
                    </button>
                  </div>
                }
              >
                <>
                  <div className="mb-3">
                    {t(
                      "Please check the information below. If this is the correct company, click Confirm."
                    )}
                  </div>
                  <div className="company-table mb-3">
                    <table>
                      <thead>
                        <tr>
                          <th>{t("Company")}</th>
                          <th>{t("Registration Number")}</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>{selectedCompany.name}</td>
                          <td>{selectedCompany.id}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {selectedCompany.address && (
                    <div className="company-table mb-3">
                      <table>
                        <thead>
                          <tr>
                            <th>{t("Address")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{selectedCompany.address}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              </CustomModal>
            )}
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  useEffect(() => {
    appData.fetchRiskCountries().then((res: any) => {
      setRiskCountries(res);
    });
  }, []);

  return <>{showCompanySearchDefault()}</>;
};

export default CompanySearchComponent;
