import {
  UBODetailsType,
  KnownUBOListType,
  PersonOfInterestType,
  FictitiousUBOListType,
  OrgTreeDetails,
  ImportUBONode,
  ImportUBONodeEdge
} from "./types";
import { isNumericValue } from "../../lib/utils";
import {
  KNOWN,
  FICTITIOUS,
  DERIVED,
  uboQuestionDropDownGerman,
  uboQuestionDropDown
} from "./AddUBOs/service";
import i18n from "../../i18n";
import moment from "moment";
export function validateUboData(data: UBODetailsType) {
  if (
    data?.uboInfoType?.value === KNOWN &&
    data.knownUbosDetails?.knownUBOs?.length
  ) {
    for (let ubo of data.knownUbosDetails.knownUBOs) {
      if (
        !ubo.firstName ||
        !ubo.lastName ||
        !ubo.country ||
        !ubo.city ||
        !ubo.dob ||
        !ubo.placeOfBirth ||
        !isNumericValue({
          data: ubo.shareHolding,
          allowNegative: false,
          upperLimit: 100
        }) ||
        !isNumericValue({
          data: ubo.votingRights,
          allowNegative: false,
          upperLimit: 100
        })
      ) {
        return i18n.t("Please enter valid values in all mandatory fields");
      }
      if (ubo.dob) {
        const inputDate = moment(ubo.dob, "DD-MM-YYYY"); // Parse the input date
        const fiveYearsAgo = moment().subtract(5, "years");

        if (inputDate.isAfter(fiveYearsAgo)) {
          return i18n.t("Date of birth should be at least 5 years old");
        }
      }
      if (!ubo.taxIdNumber && !ubo.comments) {
        return i18n.t(
          "If exempt from providing the Tax-ID number,please include the reason as a comment."
        );
      }
    }
    let duplicates = getDuplicateUbos(data.knownUbosDetails.knownUBOs);
    if (duplicates.length) {
      return `Following ubos are duplicate - ${duplicates.toString()}`;
    }
  }
  if (data?.uboInfoType?.value === FICTITIOUS && data.fictitiousUbos?.length) {
    for (let ubo of data?.fictitiousUbos) {
      if (
        !ubo.firstName ||
        !ubo.lastName ||
        !ubo.city ||
        !ubo.dob ||
        !ubo.placeOfBirth
      ) {
        return i18n.t("Please enter valid values in all mandatory fields");
      }
      if (ubo.dob) {
        const inputDate = moment(ubo.dob, "DD-MM-YYYY"); // Parse the input date
        const fiveYearsAgo = moment().subtract(5, "years");

        if (inputDate.isAfter(fiveYearsAgo)) {
          return i18n.t("Date of birth should be at least 5 years old");
        }
      }
      if (
        (ubo.shareHolding &&
          !isNumericValue({
            data: ubo.shareHolding,
            allowNegative: false,
            upperLimit: 100
          })) ||
        (ubo.votingRights &&
          !isNumericValue({
            data: ubo.votingRights,
            allowNegative: false,
            upperLimit: 100
          }))
      ) {
        return "Please enter valid value between 0-100";
      }
      if (!ubo.taxIdNumber && !ubo.comments) {
        return i18n.t(
          "If exempt from providing the Tax-ID number,please include the reason as a comment."
        );
      }
    }
    let duplicates = getDuplicateUbos(data.fictitiousUbos);
    if (duplicates.length) {
      return `Following ubos are duplicate - ${duplicates.toString()}`;
    }
  }
  if (data?.uboInfoType?.value === DERIVED && data.orgStructure) {
    let blankEntities = checkForBlankEntities(data.orgStructure);
    if (blankEntities) {
      return blankEntities;
    }
  }
  return "";
}
export function getNonCompleteEntities(data: UBODetailsType) {
  let invalidEntitiesList = "";
  if (data?.uboInfoType?.value === DERIVED && data.orgStructure) {
    let invalidEntities = checkFullOwnership(data?.orgStructure);
    invalidEntitiesList = invalidEntities
      .filter((val: string) => val)
      .join(", ");
  }
  return invalidEntitiesList;
}
export function trimAllVals(
  data: PersonOfInterestType[] | KnownUBOListType[] | FictitiousUBOListType[]
) {
  data.forEach(
    (
      entity:
        | PersonOfInterestType
        | KnownUBOListType
        | FictitiousUBOListType
        | any
    ) => {
      if (entity.firstName) {
        entity.firstName = entity.firstName.trim();
      }
      if (entity.lastName) {
        entity.lastName = entity.lastName.trim();
      }
      if (entity.justification) {
        entity.justification = entity.justification.trim();
      }
      if (entity.role) {
        entity.role = entity.role.trim();
      }
    }
  );
  return data;
}
export function getDuplicateUbos(data: any[]) {
  if (data?.length) {
    const duplicate = data.reduce((a: any, e: any) => {
      a[`${e.firstName} ${e.lastName}`] =
        ++a[`${e.firstName} ${e.lastName}`] || 0;
      return a;
    }, {});
    return Object.keys(duplicate).filter((key) => duplicate[key] !== 0);
  }
  return [];
}
//This function is used to check if all the entities have all the 100%
//voting and shareholding occupied or not.
function checkFullOwnership(orgTree: OrgTreeDetails, entityList: any = []) {
  let total: number = 0;
  if (
    orgTree.entityType === "legalEntity" &&
    !(orgTree.isEntityWithNoUbo || orgTree.isFreeFloatShareEntity) &&
    orgTree.subsidiaries
  ) {
    total = orgTree.subsidiaries.reduce((acc: number, e: OrgTreeDetails) => {
      acc += Number(e.votingRights || 0) + Number(e.shareHolding || 0);
      return acc;
    }, 0);
    if (Number(total) < 200) {
      entityList.push(orgTree.legalEntityName);
      //return entityList;
    }
  }
  if (
    orgTree.entityType === "legalEntity" &&
    !(orgTree.isEntityWithNoUbo || orgTree.isFreeFloatShareEntity) &&
    orgTree.subsidiaries?.length
  ) {
    for (let subsidiary of orgTree.subsidiaries) {
      entityList = checkFullOwnership(subsidiary, entityList);
    }
  }
  return entityList;
}
function checkForBlankEntities(orgTree: OrgTreeDetails): string {
  if (!orgTree.entityType) {
    return i18n.t("Please put details for all new entities");
  }
  if (orgTree.isUBO && !orgTree.country) {
    return i18n.t("Please select country for all UBOs");
  }
  if (orgTree.subsidiaries?.length) {
    for (let subsidiary of orgTree.subsidiaries) {
      let message = checkForBlankEntities(subsidiary);
      if (message) {
        return message;
      }
    }
  }
  return "";
}

function parseNestedEntities({
  nodeId,
  nodeFields,
  edgeField,
  parentNodeId
}: {
  nodeId: string;
  nodeFields: ImportUBONode[];
  edgeField: ImportUBONodeEdge;
  parentNodeId: string;
}): OrgTreeDetails {
  const nestedNode: ImportUBONode | undefined = nodeFields.find(
    (node: ImportUBONode) => edgeField.nodeIdField === node.entityField.idField
  );
  //nested node and edge field combined has data about the edge field
  const subsidiaries: OrgTreeDetails[] = [];
  if (nestedNode?.edgesField?.length) {
    let nestedNodeId = 1;
    for (let edgeField of nestedNode.edgesField) {
      if (edgeField.typeField === "SHAREHOLDER") {
        const subsidiary: OrgTreeDetails = parseNestedEntities({
          nodeFields,
          edgeField,
          nodeId: `${nodeId}.${nestedNodeId++}`,
          parentNodeId: nodeId
        });
        subsidiaries.push(subsidiary);
      }
    }
  }
  //nested node and edge field combined has data about the edge field and subsiadiaries
  let nodeDetails: OrgTreeDetails = {
    nodeId,
    votingRights: Number(edgeField.percentageField || 0),
    shareHolding: Number(edgeField.percentageField || 0),
    absoluteVotePercent: Number(nestedNode?.rollupPercentageField || 0),
    absoluteSharePercent: Number(nestedNode?.rollupPercentageField || 0),
    parentNodeId,
    firstName: "",
    lastName: "",
    legalEntityName: ""
  };
  if (nestedNode?.entityField?.typeField === "PERSON") {
    const fullName = nestedNode?.entityField?.nameField || "";
    const lastName = fullName.substring(
      fullName.lastIndexOf(" ") + 1,
      fullName.length
    );
    const firstName = fullName.substring(0, fullName.lastIndexOf(" "));
    nodeDetails = {
      ...nodeDetails,
      entityType: "naturalPerson",
      firstName,
      lastName
    };
  } else {
    nodeDetails = {
      ...nodeDetails,
      entityType: "legalEntity",
      legalEntityName: nestedNode?.entityField?.nameField || ""
    };
  }
  return { ...nodeDetails, subsidiaries };
}
export function parseToUBOOrgStructure({
  nodeFields
}: {
  nodeFields: ImportUBONode[];
}): OrgTreeDetails {
  const parentNode: ImportUBONode | undefined = nodeFields.find(
    (data: ImportUBONode) => data.levelField === 1
  );
  const nestedEntities: ImportUBONodeEdge[] = parentNode?.edgesField?.length
    ? parentNode.edgesField
    : [];
  const subsidiaries = [];
  if (nestedEntities.length) {
    let nodeId = 1;
    for (let nestedEntity of nestedEntities) {
      if (nestedEntity.typeField === "SHAREHOLDER") {
        const subsidiary = parseNestedEntities({
          nodeId: `1.${nodeId++}`,
          nodeFields,
          edgeField: nestedEntity,
          parentNodeId: "1"
        });
        subsidiaries.push(subsidiary);
      }
    }
  }
  return {
    nodeId: "1",
    entityType: "legalEntity",
    legalEntityName: parentNode?.entityField?.nameField,
    firstName: "",
    lastName: "",
    votingRights: 100,
    shareHolding: 100,
    absoluteVotePercent: 100,
    absoluteSharePercent: 100,
    subsidiaries
  };
}
export function getDefaultUBOIndentification() {
  let dropdown = uboQuestionDropDown;
  if (i18n.language === "de") {
    dropdown = uboQuestionDropDownGerman;
  }
  return dropdown.find((val) => val.value === "derived");
}
