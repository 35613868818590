import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import PageHeader from "../../presentation/PageHeader";
import {
  getDefaultRoute,
  getDefaultTab,
  getTabDetails
} from "../ReviewSubmittedQuestionnaire/service";
import * as apis from "../../../apis/SubmittedQuestionnaire";
import * as utils from "../../../lib/utils";
import {
  ChangeStatusType,
  Questionnaire
} from "../SubmittedQuestionnaire/types";
import { Params } from "./types";
import ReviewSubmittedQuestionnaireRoutes from "./routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Icons from "../../presentation/Icons";
import {
  DRAFT,
  getChangableStatus,
  NEW,
  INTERNAL_DRAFT,
  acceptRequestToReopen
} from "../../../lib/questionnaireStatusAccess";
import { UserContext } from "../../../Contexts/UserContext";
import { useTranslation } from "react-i18next";
import { AllSubmissionStatus } from "../../../types/applicationData";
import { ReopenRequestType } from "../ResponseQuestionnaireList/types";
import { toast } from "react-toastify";
import {
  findUserPermissionsFromRoles,
  ADMIN_REGENERATE_PDF_PERMISSION,
  ASSET_MANAGER_USER,
  USER_ADMIN,
  permissionError,
  ADMIN_SECTION_REOPEN_PERMISSION
} from "../../../lib/questionnairePermissionsAccess";
import CustomModal from "../../presentation/Modals/CustomModal";
import Select from "react-select";
import { downloadReports } from "../../../services/FileUploadDownloadService";
import {
  AM,
  FILE_TYPE_AML_REPORT,
  FILE_TYPE_QUESTIONNAIRE_PDF,
  FILE_TYPE_QUESTIONNAIRE_PDF_IN_ZIP,
  PDF_FILE_EXTENSION,
  ZIP_FILE_EXTENSION
} from "../../../constants/app-constants";
import { appData } from "../../../services/appConfig";
import ConfirmationModal from "../../presentation/Modals/ConfirmationModal";
import { useQueryParams } from "../../../Hooks/useQueryParams";

const ReviewSubmittedQuestionnaire: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    assetManagerId,
    submittedQuestionnaireId,
    accountType,
    sectionId,
    accountQuestionnaireId,
    nestedPath
  }: Params = useParams<any>();
  const defaultRoute = getDefaultRoute(nestedPath);
  const defaultTab = getDefaultTab(defaultRoute);
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [questionnaire, setQuestionnaire] = useState<Questionnaire | null>(
    null
  );
  const { userDetails }: any = useContext(UserContext);
  const [changeStatusData, setChangeStatusData] = useState<ChangeStatusType>();
  const [confirmationBoxData, setConfirmationBoxData] = useState({
    title: "",
    message: "",
    callBack: () => {},
    showConfirmButton: true
  });
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [reopenRequestData, setReopenRequestData] =
    useState<ChangeStatusType>();
  const [allSubmissionStatus, setAllSubmissionStatus] =
    useState<AllSubmissionStatus[]>();
  const [refreshQuestionnaire, setRefreshQuestionnaire] = useState<number>(0);
  const [refreshApprovals, setRefreshApprovals] = useState(0);
  const defaultPath = "questionnaire";
  useEffect(() => {
    (async () => {
      let response: any = await appData.fetchAllSubmissionStatus();
      setAllSubmissionStatus(response);
    })();
  }, []);
  useEffect(() => {
    toast.dismiss();
    setActiveTab(getDefaultTab(defaultRoute));
  }, [defaultRoute]);
  useEffect(() => {
    apis
      .getSubmittedQuestionnaire(
        submittedQuestionnaireId === NEW ? "" : submittedQuestionnaireId,
        sectionId,
        accountQuestionnaireId
      )
      .then((response) => {
        if (
          response?.data?.getSubmittedQuestionnaireDetails &&
          response?.data?.getSubmittedQuestionnaireDetails.sections
        ) {
          setQuestionnaire(
            Object.assign({}, response?.data?.getSubmittedQuestionnaireDetails)
          );
        }
      })
      .catch((error) => {
        toast.error(utils.formatGraphQLError(error.message));
        setQuestionnaire(null);
      });
  }, [
    assetManagerId,
    submittedQuestionnaireId,
    sectionId,
    refreshQuestionnaire
  ]);
  function handleTabChange(tabName: string, tabLink: string) {
    toast.dismiss();
    setActiveTab(tabName);
    history.push(tabLink);
  }
  function reopenRequest({
    changeSectionId,
    changeAccountQuestionnaireId,
    reopenRequest,
    sectionName,
    questionnaireName,
    currentStatus
  }: {
    changeSectionId?: string | number;
    changeAccountQuestionnaireId?: string | number;
    reopenRequest: ReopenRequestType | null;
    sectionName?: string;
    questionnaireName?: string;
    currentStatus: string;
  }) {
    let draftStatus = getChangableStatus({
      statusList: allSubmissionStatus,
      currentStatus,
      userType: userDetails.user_type
    })?.find((val: any) => val.name === DRAFT);
    if (changeSectionId) {
      setReopenRequestData({
        showModal: true,
        accountQuestionnaireId: accountQuestionnaireId,
        sectionId: changeSectionId,
        reopenRequest,
        sectionName,
        currentStatus
      });
      setChangeStatusData({
        accountQuestionnaireId: accountQuestionnaireId,
        sectionId: changeSectionId,
        statusId: draftStatus?.id,
        currentStatus
      });
    }
    if (changeAccountQuestionnaireId) {
      setReopenRequestData({
        showModal: true,
        accountQuestionnaireId: changeAccountQuestionnaireId,
        sectionId: undefined,
        reopenRequest,
        questionnaireName,
        currentStatus
      });
      setChangeStatusData({
        accountQuestionnaireId: changeAccountQuestionnaireId,
        sectionId: undefined,
        statusId: draftStatus?.id,
        currentStatus
      });
    }
  }
  function denyEditRequest() {
    if (reopenRequestData) {
      apis
        .rejectReopenRequest({
          accountQuestionnaireId: reopenRequestData.accountQuestionnaireId,
          sectionId: reopenRequestData?.sectionId || null
        })
        .then(() => {
          toast.success(t("Operation successfully"));
          setChangeStatusData(undefined);
          setReopenRequestData(undefined);
          setRefreshQuestionnaire(refreshQuestionnaire + 1);
          setRefreshApprovals(refreshApprovals + 1);
        })
        .catch((e: any) => {
          toast.error(utils.formatGraphQLError(e.message));
        });
    }
  }
  function regenerateQuestionnairePDF() {
    apis
      .regenerateQuestionnairePDF({
        accountQuestionnaireId
      })
      .then((response: any) => {
        toast.success(response.data?.reGenerateQuestionnairePdf.message);
        setShowConfirmationModal(false);
      })
      .catch((e: any) => {
        toast.error(utils.formatGraphQLError(e.message));
      });
    setShowConfirmationModal(false);
  }
  function changeStatus() {
    if (changeStatusData?.statusId) {
      apis
        .changeQuestionnaireStatus({
          accountQuestionnaireId,
          statusId: changeStatusData?.statusId,
          sectionId: changeStatusData?.sectionId || null
        })
        .then(() => {
          toast.success(t("Status changed successfully"));
          setChangeStatusData(undefined);
          setRefreshQuestionnaire(refreshQuestionnaire + 1);
          setRefreshApprovals(refreshApprovals + 1);
          setReopenRequestData(undefined);
        })
        .catch((e) => {
          toast.error(utils.formatGraphQLError(e.message));
        });
    } else {
      toast.error(t("Please select status"));
    }
  }

  // Remove jwt_token if present in url
  useQueryParams({
    paramNames: ["jwt_token"]
  });

  return (
    <>
      {questionnaire && (
        <>
          <PageHeader
            title={`${questionnaire.distributor}`}
            accountLogo={questionnaire.accountLogo}
            backLink={`/accountManagerInfo/${assetManagerId}/responseList`}
            backLinkLabel={t("Overview")}
            // details={[
            //   `${t("Valid until")}: ${
            //     utils.getDate(questionnaire.validUntil) || "-"
            //   } ${
            //     accountType === AM
            //       ? `| Score: ${questionnaire.score || "-"}`
            //       : "-"
            //   }`
            // ]}
            tabs={getTabDetails({
              activeTab,
              assetManagerId,
              submittedQuestionnaireId,
              accountType,
              sectionId,
              accountQuestionnaireId,
              nestedPath
            })}
            handleTabChange={handleTabChange}
            headerLeftMenuTriggerButton={
              <span className="ml-3">
                <Icons className="small-icon" icon="circleMenuIcon" />
              </span>
            }
            headerLeftMenu={
              <>
                <span
                  onClick={async () => {
                    if (questionnaire.questionnairePdfError) {
                      toast.error(questionnaire.questionnairePdfError);
                    } else {
                      downloadReports({
                        accountQuestionnaireId: accountQuestionnaireId,
                        fileName: questionnaire.title,
                        fileType: FILE_TYPE_QUESTIONNAIRE_PDF,
                        fileExtension: PDF_FILE_EXTENSION
                      });
                    }
                  }}
                  className="dropdown-item flex-center py-3 px-4"
                >
                  <Icons className="mr-3 small-icon" icon="darkDownloadIcon" />
                  {t("Questionnaire PDF")}
                </span>
                <span
                  className="dropdown-item flex-center py-3 px-4"
                  onClick={async () => {
                    if (questionnaire.questionnairePdfError) {
                      toast.error(questionnaire.questionnairePdfError);
                    } else {
                      downloadReports({
                        accountQuestionnaireId: accountQuestionnaireId,
                        fileName: questionnaire.title,
                        fileType: FILE_TYPE_QUESTIONNAIRE_PDF_IN_ZIP,
                        fileExtension: ZIP_FILE_EXTENSION
                      });
                    }
                  }}
                >
                  <Icons className="mr-3 small-icon" icon="darkDownloadIcon" />
                  {t("All Documents Zip")}
                </span>

                {questionnaire.amlReport && (
                  <span
                    className="dropdown-item flex-center py-3 px-4"
                    onClick={async () => {
                      downloadReports({
                        accountQuestionnaireId: accountQuestionnaireId,
                        fileName: questionnaire.amlReport,
                        fileType: FILE_TYPE_AML_REPORT
                      });
                    }}
                  >
                    <Icons
                      className="mr-3 small-icon"
                      icon="darkDownloadIcon"
                    />
                    {t("AML report")}
                  </span>
                )}
                {findUserPermissionsFromRoles([
                  { name: ADMIN_REGENERATE_PDF_PERMISSION }
                ]) && (
                  <span
                    className="dropdown-item flex-center py-3 px-4"
                    onClick={() => {
                      setShowConfirmationModal(true);
                      setConfirmationBoxData({
                        title: "Regenerate Questionnaire PDF",
                        message:
                          "Do you want to regenerate the questionnaire PDF?",
                        showConfirmButton: true,
                        callBack: regenerateQuestionnairePDF
                      });
                    }}
                  >
                    <Icons className="mr-3 small-icon" icon="regeneratePdf" />
                    {t("Regenerate Questionnaire PDF")}
                  </span>
                )}
                {acceptRequestToReopen({
                  status: questionnaire.questionnaireStatus?.name
                }) &&
                  !questionnaire?.sectionSubmission && (
                    <>
                      {questionnaire?.reopenRequest?.requestedAt && (
                        <OverlayTrigger
                          placement="top"
                          rootClose
                          overlay={
                            <Tooltip id={`reopen-info-questionnaire`}>
                              {questionnaire?.reopenRequest?.requestedBy}{" "}
                              {t(
                                "has requested to reopen the questionnaire at"
                              )}{" "}
                              {utils.getDate(
                                questionnaire?.reopenRequest?.requestedAt,
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </Tooltip>
                          }
                        >
                          <span
                            className="dropdown-item flex-center py-3 px-4"
                            onClick={() => {
                              if (
                                findUserPermissionsFromRoles([
                                  {
                                    name: USER_ADMIN,
                                    permissionType: ASSET_MANAGER_USER
                                  },
                                  { name: ADMIN_SECTION_REOPEN_PERMISSION }
                                ])
                              ) {
                                reopenRequest({
                                  changeAccountQuestionnaireId:
                                    accountQuestionnaireId,
                                  reopenRequest:
                                    questionnaire.reopenRequest || null,
                                  questionnaireName: questionnaire.title,
                                  currentStatus:
                                    questionnaire.questionnaireStatus.name
                                });
                              } else {
                                permissionError();
                              }
                            }}
                          >
                            <Icons
                              className="mr-3 small-icon"
                              icon="activeDocument"
                            />
                            {t("Request to reopen")}
                          </span>
                        </OverlayTrigger>
                      )}
                      <span
                        className="dropdown-item flex-center py-3 px-4"
                        onClick={() => {
                          setChangeStatusData({
                            showModal: true,
                            accountQuestionnaireId,
                            currentStatus:
                              questionnaire.questionnaireStatus.name
                          });
                        }}
                      >
                        <Icons
                          className="mr-3 small-icon"
                          icon="changeStatusIcon"
                        />
                        {t("Change status")}
                      </span>
                    </>
                  )}
              </>
            }
          />
          <ReviewSubmittedQuestionnaireRoutes
            defaultPath={defaultPath}
            refreshQuestionnaire={refreshQuestionnaire}
          />
          {/* Modal for confirmation box start */}
          <ConfirmationModal
            modalTitle={confirmationBoxData.title}
            message={confirmationBoxData.message}
            callBack={confirmationBoxData.callBack}
            showConfirmButton={confirmationBoxData.showConfirmButton}
            showConfirmationModal={showConfirmationModal}
            onModalClose={() => {
              setShowConfirmationModal(false);
            }}
          />
        </>
      )}

      {changeStatusData?.showModal && (
        <CustomModal
          modalTitle="Change status"
          classNames="modal__body p-0"
          showModal={changeStatusData?.showModal}
          onClose={() => {
            setChangeStatusData(undefined);
          }}
          showFooter={true}
          footer={
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <button
                className="btn btn-outline-primary"
                onClick={(e) => {
                  setChangeStatusData(undefined);
                }}
              >
                {t("Cancel")}
              </button>
              <button
                className="btn btn-primary"
                onClick={() => changeStatus()}
              >
                {t("Confirm")}
              </button>
            </div>
          }
        >
          <form
            className="form"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <div className="form-group">
              <div className="mt-3">
                *
                {t(
                  "On change of status, all the existing approvals will be reset (if present)."
                )}
              </div>
              <div className="mt-3">
                <label className="mb-1">{t("Change Status")}</label>
                <Select
                  options={utils.modifyForSelectBox(
                    (changeStatusData &&
                      getChangableStatus({
                        statusList: allSubmissionStatus,
                        currentStatus: changeStatusData.currentStatus,
                        userType: userDetails.user_type,
                        isClientManaged: !!questionnaire?.isClientManaged
                      })) ||
                      [],
                    "id",
                    "label"
                  )}
                  value={
                    changeStatusData.statusId
                      ? {
                          label: changeStatusData.statusLabel,
                          value: changeStatusData.statusId
                        }
                      : null
                  }
                  placeholder={`${t("Select")}...`}
                  noOptionsMessage={() => t("No options")}
                  isClearable={true}
                  isMulti={false}
                  onChange={({
                    id,
                    value,
                    label
                  }: {
                    id: string | number;
                    value: string | number;
                    label: string;
                  }) => {
                    setChangeStatusData({
                      ...changeStatusData,
                      statusId: id,
                      statusLabel: label
                    });
                  }}
                />
              </div>
            </div>
          </form>
        </CustomModal>
      )}
      <CustomModal
        modalTitle="Request to edit"
        classNames="modal"
        modalBodyClasses="modal__body p-0"
        showModal={reopenRequestData?.showModal || false}
        onClose={() => {
          setReopenRequestData(undefined);
        }}
      >
        <form
          className="form"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div className="form-group">
            <div className="mt-3">
              <div className="loop-edit-questionnaire">
                <div className="text-muted">
                  {utils.getDate(
                    reopenRequestData?.reopenRequest?.requestedAt,
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                </div>
                <div className="loop-question-level">
                  {reopenRequestData?.reopenRequest?.requestedBy}{" "}
                  {t("has requested to edit")}{" "}
                  <strong>
                    {reopenRequestData?.sectionName ||
                      reopenRequestData?.questionnaireName}
                  </strong>
                  <br />
                  {reopenRequestData?.currentStatus === INTERNAL_DRAFT && (
                    <div className="color-red">
                      {t(
                        `Request to reopen cannot be allowed if ${
                          reopenRequestData?.sectionName
                            ? "section"
                            : "questionnaire"
                        } status is in Internal draft. Please complete ${
                          reopenRequestData?.sectionName
                            ? "the section"
                            : "all the sections"
                        } first`
                      )}
                    </div>
                  )}
                </div>
                <div className="loop-edit-questionnaire-btn">
                  <button
                    className={`btn ${
                      reopenRequestData?.currentStatus === INTERNAL_DRAFT
                        ? "btn-disabled"
                        : "btn-primary"
                    }`}
                    onClick={() => {
                      if (reopenRequestData?.currentStatus === INTERNAL_DRAFT)
                        return false;
                      changeStatusData?.statusId
                        ? changeStatus()
                        : toast.error(t("Request to edit cannot be allowed"));
                    }}
                  >
                    {t("Allow")}
                  </button>
                  <button
                    onClick={denyEditRequest}
                    className="btn btn-outline-secondary"
                  >
                    {t("Deny")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </CustomModal>
    </>
  );
};

export default ReviewSubmittedQuestionnaire;
