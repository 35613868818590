import { Column } from "react-table";
import { Company } from "./types";
import { TFunction } from "i18next";

export function getCompanyColumnConfig({
  companyList,
  t
}: {
  companyList: any;
  t: TFunction;
}) {
  const columnConfig: Column<Company>[] = [
    {
      Header: t(`Company`) || "Company",
      accessor: "name"
    },
    {
      Header: t("Registration Number") || "Registration Number",
      accessor: "id"
    },
    {
      Header: "Company Address",
      accessor: "address"
    }
  ];
  return columnConfig;
}
