import { CompanyApiProfile } from "./types";
import client from "../apollo.config";
import * as queries from "./queries";

export const getRiskCountries = () => {
  return client.query({
    query: queries.GET_RISK_COUNTRIES,
    fetchPolicy: "network-only"
  });
};
export const searchCompanyByCode = ({
  countryCode,
  stateCode,
  companyCode,
  accountQuestionnaireId,
  sectionId
}: {
  countryCode: string;
  stateCode: string;
  companyCode: string;
  accountQuestionnaireId: string | number;
  sectionId: string | number;
}) => {
  return client.query({
    query: queries.SEARCH_COMPANY_BY_CODE,
    fetchPolicy: "network-only",
    variables: {
      countryCode,
      stateCode,
      companyCode,
      accountQuestionnaireId,
      sectionId
    }
  });
};
export const searchCompanyByName = ({
  countryCode,
  stateCode,
  searchTerm,
  accountQuestionnaireId,
  sectionId
}: {
  countryCode: string;
  stateCode: string;
  searchTerm: string;
  accountQuestionnaireId: string | number;
  sectionId: string | number;
}) => {
  return client.query({
    query: queries.SEARCH_COMPANY_BY_NAME,
    fetchPolicy: "network-only",
    variables: {
      countryCode,
      stateCode,
      searchTerm,
      accountQuestionnaireId,
      sectionId
    }
  });
};
export const getCompanyApiProfile = ({
  accountQuestionnaireId,
  countryCode,
  stateCode,
  companyCode,
  questionId,
  sectionId,
  registrationAuthCode
}: CompanyApiProfile) => {
  return client.query({
    query: queries.GET_COMPANY_API_PROFILE,
    fetchPolicy: "network-only",
    variables: {
      accountQuestionnaireId,
      countryCode,
      stateCode,
      companyCode,
      questionId,
      sectionId,
      registrationAuthCode
    }
  });
};
export const getExternalCompaniesList = ({
  accountQuestionnaireId
}: {
  accountQuestionnaireId: string | number;
}) => {
  return client.query({
    query: queries.GET_EXTERNAL_COMPANIES_LIST,
    fetchPolicy: "network-only",
    variables: {
      accountQuestionnaireId
    }
  });
};
export const getExternalDocumentsList = ({
  accountQuestionnaireId,
  companyCode,
  sectionId,
  questionId,
  continuationKey
}: {
  accountQuestionnaireId: string | number;
  companyCode: string | number;
  sectionId: string | number;
  questionId: string | number;
  continuationKey?: string;
}) => {
  return client.query({
    query: queries.GET_EXTERNAL_DOCUMENTS_LIST,
    fetchPolicy: "network-only",
    variables: {
      accountQuestionnaireId,
      companyCode,
      sectionId,
      questionId,
      continuationKey
    }
  });
};
export const orderDocuments = (input: any) => {
  return client.mutate({
    mutation: queries.ORDER_DOCUMENTS,
    variables: { input }
  });
};
export const deleteExternalOrder = (input: any) => {
  return client.mutate({
    mutation: queries.DELETE_EXTERNAL_ORDER,
    variables: { input }
  });
};
export const getCompanyUBOs = ({
  accountQuestionnaireId,
  sectionId,
  questionId,
  orderId
}: {
  accountQuestionnaireId: string | number;
  sectionId: string | number;
  questionId: string | number;
  orderId: string | number;
}) => {
  return client.query({
    query: queries.GET_COMPANY_UBOS,
    fetchPolicy: "network-only",
    variables: {
      accountQuestionnaireId,
      sectionId,
      questionId,
      orderId
    }
  });
};

export const getDatasetCompanyApiProfile = ({
  accountQuestionnaireId,
  countryCode,
  stateCode,
  companyCode,
  sectionId,
  registrationAuthCode,
  datasetQuestionAnswerId,
  datasetQuestionAnswer
}: CompanyApiProfile) => {
  return client.query({
    query: queries.GET_DATASET_COMPANY_API_PROFILE,
    fetchPolicy: "network-only",
    variables: {
      accountQuestionnaireId,
      countryCode,
      stateCode,
      companyCode,
      sectionId,
      registrationAuthCode,
      datasetQuestionAnswerId,
      datasetQuestionAnswer
    }
  });
};

export const orderExternalDocumentsForDataset = (input: any) => {
  return client.mutate({
    mutation: queries.ORDER_EXTERNAL_DOCUMENTS_FOR_DATASET,
    variables: { input }
  });
};
export const orderExternalUboTree = (input: any) => {
  return client.mutate({
    mutation: queries.ORDER_EXTERNAL_UBO_TREE,
    variables: { input }
  });
};
