import React, { useState, useEffect } from "react";
import {
  uboQuestionDropDown,
  uboQuestionDropDownGerman,
  KNOWN,
  FICTITIOUS,
  DERIVED,
  parseDataToSave,
  isRedirectToFictitious
} from "./service";
import * as apis from "../../../apis/CounterpartySubmittedQuestionnaire";
import { formatGraphQLError } from "../../../lib/utils";
import {
  SelectDropDownType,
  KnownUBOType,
  OrgTreeDetails,
  UBODetailsType,
  FictitiousUBOListType
} from "../types";
import Select from "react-select";
import KnownUBO from "./KnownUBO";
import DerivedUBO from "./DerivedUBO";
import FictitiousUBO from "./FictitiousUBO";
import { toast } from "react-toastify";
import { validateUboData, getNonCompleteEntities } from "../service";
import Icons from "../../presentation/Icons";
import ConfirmationModal from "../../presentation/Modals/ConfirmationModal";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
interface Props {
  voteThreshold: number;
  shareThreshold: number;
  saveQuestionDetails: any;
  answer?: UBODetailsType;
  onSave: (details: UBODetailsType) => void;
  onClose: () => void;
  fileUploadParams: any;
  allowEdit: boolean;
}

const ADDUBOs: React.FC<Props> = ({
  voteThreshold,
  shareThreshold,
  saveQuestionDetails,
  answer,
  onSave,
  onClose,
  fileUploadParams,
  allowEdit
}) => {
  const { t } = useTranslation();
  const [confirmationBoxData, setConfirmationBoxData] = useState({
    showModal: false,
    title: "",
    message: "",
    confirmCallBack: () => {},
    cancelCallBack: () => {}
  });
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [uboDetails, setUboDetails] = useState<UBODetailsType>();
  const [showFictitiousRedirect, setShowFictitiousRedirect] =
    useState<boolean>(false);
  useEffect(() => {
    if (answer) {
      setUboDetails({ ...answer });
    }
  }, [answer]);
  function onUBOComponentChange(data: SelectDropDownType) {
    setUboDetails({ ...uboDetails, isFinalized: false, uboInfoType: data });
    setShowValidationErrors(false);
  }
  function updateKnownUboList(data: KnownUBOType) {
    setUboDetails({
      ...uboDetails,
      isFinalized: false,
      knownUbosDetails: data
    });
    onSave({ ...uboDetails, isFinalized: false, knownUbosDetails: data });
    setShowValidationErrors(false);
  }
  function updateDerivedUbos(data?: OrgTreeDetails) {
    setUboDetails({ ...uboDetails, isFinalized: false, orgStructure: data });
    onSave({ ...uboDetails, isFinalized: false, orgStructure: data });
    setShowValidationErrors(false);
  }
  function updateFictitiousUboList(data: FictitiousUBOListType[]) {
    setUboDetails({ ...uboDetails, isFinalized: false, fictitiousUbos: data });
    onSave({ ...uboDetails, isFinalized: false, fictitiousUbos: data });
    setShowValidationErrors(false);
  }
  function saveUBODetails() {
    if (allowEdit) {
      setShowValidationErrors(true);
      let parsedData = uboDetails
        ? parseDataToSave(uboDetails, shareThreshold, voteThreshold)
        : {};
      let validationMessage = parsedData && validateUboData(parsedData);

      if (!validationMessage) {
        let inCompleteEntities =
          parsedData && getNonCompleteEntities(parsedData);
        if (inCompleteEntities) {
          setConfirmationBoxData({
            showModal: true,
            title: "Confirm finalise",
            message: `${t(
              "dynamicStringTranslation.uboIncompleteEntitiesConfirmMessage",
              {
                inCompleteEntities
              }
            )}`,
            confirmCallBack: () => {
              finaliseUBODetails(parsedData || {});
              setConfirmationBoxData({
                showModal: false,
                title: "",
                message: ``,
                confirmCallBack: () => {},
                cancelCallBack: () => {}
              });
            },
            cancelCallBack: () => {
              saveUnfinalisedData(parsedData || {});
              setConfirmationBoxData({
                showModal: false,
                title: "",
                message: ``,
                confirmCallBack: () => {},
                cancelCallBack: () => {}
              });
            }
          });
        } else {
          finaliseUBODetails(parsedData || {});
        }
      } else {
        toast.error(validationMessage);
        saveUnfinalisedData(parsedData || {});
      }
    }
  }
  function finaliseUBODetails(parsedData: UBODetailsType) {
    setShowFictitiousRedirect(
      parsedData ? isRedirectToFictitious(parsedData) : true
    );
    apis
      .saveAnswers({
        ...saveQuestionDetails,
        questionAnswers: [
          {
            ...saveQuestionDetails.questionAnswers,
            answer: JSON.stringify(parsedData)
          }
        ]
      })
      .then((res) => {
        const errors = res?.data?.saveAnswers?.errors;
        if (errors?.length) {
          errors.forEach((err: any) => {
            toast.error(err.errors.join(","));
          });
          return;
        }
        toast.success(t("UBO information saved successsfully"));
        setUboDetails({
          ...parsedData
        });
        onSave({
          ...parsedData
        });
      })
      .catch((error) => {
        toast.error(formatGraphQLError(error.message));
      });
  }
  function saveUnfinalisedData(parsedData: UBODetailsType) {
    setUboDetails({ ...parsedData, isFinalized: false });
    onSave({ ...parsedData, isFinalized: false });
  }
  function getAddUBOComponent() {
    switch (uboDetails?.uboInfoType?.value) {
      case KNOWN:
        return (
          <KnownUBO
            onUboInfoTypeChange={onUBOComponentChange}
            uboInfoType={uboDetails?.uboInfoType}
            updateParent={updateKnownUboList}
            knownUbos={uboDetails?.knownUbosDetails || { knownUBOs: [] }}
            fileUploadParams={{
              ...fileUploadParams,
              sectionId: saveQuestionDetails.sectionId
            }}
            allowEdit={allowEdit}
            showValidationErrors={showValidationErrors}
          />
        );
      case DERIVED:
        return (
          <DerivedUBO
            voteThreshold={voteThreshold}
            shareThreshold={shareThreshold}
            onUboInfoTypeChange={onUBOComponentChange}
            uboInfoType={uboDetails?.uboInfoType}
            updateParent={updateDerivedUbos}
            orgStructure={uboDetails?.orgStructure}
            fileUploadParams={{
              ...fileUploadParams,
              sectionId: saveQuestionDetails.sectionId
            }}
            allowEdit={allowEdit}
          />
        );
      case FICTITIOUS:
        return (
          <FictitiousUBO
            onUboInfoTypeChange={onUBOComponentChange}
            uboInfoType={uboDetails?.uboInfoType}
            updateParent={updateFictitiousUboList}
            fictitiousUBOs={uboDetails?.fictitiousUbos || []}
            fileUploadParams={{
              ...fileUploadParams,
              sectionId: saveQuestionDetails.sectionId
            }}
            allowEdit={allowEdit}
            showValidationErrors={showValidationErrors}
          />
        );
      default:
        return "";
    }
  }
  return (
    <>
      {showFictitiousRedirect && (
        <div className="custom-flash-message flash-message--info">
          <div className="custom-flash-content">
            <div className="flash-message-icon">
              <Icons icon={"warningTriangle"} />
            </div>
            <div className="flash-message-text">
              {t(
                `No UBOs have been identified. Please complete the diagram or`
              )}{" "}
              <button
                className="flash-msg-link"
                onClick={() => {
                  setShowFictitiousRedirect(false);
                  onUBOComponentChange({
                    value: "fictitious",
                    label: "Add fictitious ubos"
                  });
                }}
              >
                {t("input at least one fictional UBO for")}{" "}
                {uboDetails?.orgStructure?.legalEntityName || ""}.
              </button>
            </div>
            {/* <div className="flash-message-close-icon">
              <button type="button" className="flash-message-close close">
                <svg
                  aria-hidden="true"
                  className="icon--small"
                  viewBox="0 0 16 16"
                >
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="images/icons-sprite.svg#cross-16"
                  ></use>
                </svg>
              </button>
            </div> */}
          </div>
        </div>
      )}
      <div className="page-header">
        <div className="ubo-heading-row">
          <div className="heading-text-left">
            <h1 className="h2 page-header__title mt-3">{t("UBO Engine")}</h1>
          </div>
          <div className="heading-button-right">
            <button
              disabled={!allowEdit}
              className="btn btn-primary"
              onClick={saveUBODetails}
            >
              {t("Save")}
            </button>
            <button onClick={onClose} className="btn btn-outline-primary">
              {t("Save and Close")}
            </button>
          </div>
        </div>
      </div>

      {!uboDetails?.uboInfoType && (
        <div className="ubo-sec-content">
          <div className="ubo-left-filter">
            <div className="ubo-status l-r-15">
              <p className="ubo-status-text">
                {t("Do you already know who the UBOs are?")}
              </p>
              <Select
                placeholder={`${t("Select")}...`}
                name="counterparty"
                onChange={(option: any) => onUBOComponentChange(option)}
                value={{
                  value: uboDetails?.uboInfoType?.value || "",
                  label: i18n.t(uboDetails?.uboInfoType?.label || "")
                }}
                options={
                  i18n.language === "de"
                    ? uboQuestionDropDownGerman
                    : uboQuestionDropDown
                }
                isDisabled={!allowEdit}
                noOptionsMessage={() => t("No options")}
              />
            </div>
          </div>
        </div>
      )}
      {getAddUBOComponent()}
      {confirmationBoxData.showModal && (
        <ConfirmationModal
          modalTitle={confirmationBoxData.title}
          message={confirmationBoxData.message}
          callBack={confirmationBoxData.confirmCallBack}
          showConfirmationModal={confirmationBoxData.showModal}
          onModalClose={() => {
            confirmationBoxData.cancelCallBack();
          }}
        />
      )}
    </>
  );
};

export default ADDUBOs;
