import React, { useEffect, useState } from "react";
import Select from "react-select";
import i18n from "../../../i18n";
import * as utils from "../../../lib/utils";
import { useTranslation } from "react-i18next";
import { ImportInformationData } from "../../container/ImportInfo/types";
import { useParams } from "react-router-dom";
import { Params } from "../../container/CounterpartySubmittedQuestionnaire/types";
import * as apis from "../../../apis/CompanySearch";
import { toast } from "react-toastify";

type Props = {
  onCompanySelect?: (value: any) => void;
};

const ExternalCompaniesList: React.FC<Props> = ({ onCompanySelect }) => {
  const { t } = useTranslation();
  const { accountQuestionnaireId }: Params = useParams<any>();
  const [importInformation, setImportInformation] =
    useState<ImportInformationData>();

  useEffect(() => {
    (async () => {
      try {
        const externalCompaniesList = await apis.getExternalCompaniesList({
          accountQuestionnaireId
        });
        if (externalCompaniesList.errors?.length) {
          externalCompaniesList.errors.forEach((err: any) => {
            toast.error(err);
          });
          return;
        }
        setImportInformation({
          externalCompaniesList:
            externalCompaniesList.data.getExternalCompaniesList || []
        });
      } catch (err) {
        if (err instanceof Error) {
          toast.error(utils.formatGraphQLError(err.message));
        }
      }
    })();
  }, []);

  return (
    <React.Fragment>
      {importInformation && (
        <div className="d-flex align-items-center">
          <label htmlFor="company" className="ddd-input-3 d-block mr-3">
            {t("Select Company")}
          </label>
          <Select
            name="company"
            options={utils.modifyForSelectBox(
              importInformation.externalCompaniesList || [],
              "companyCode",
              // i18n.language === "de" ? "germanName" : "name"
              "name"
            )}
            placeholder={`${t("Select")}...`}
            onChange={(value) => {
              if (value.id) {
                setImportInformation({
                  ...importInformation,
                  selectedCompany: value
                });
                onCompanySelect && onCompanySelect(value);
              }
            }}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 })
            }}
            className="w-50"
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default ExternalCompaniesList;
