import React from "react";
import CustomModal from "../../presentation/Modals/CustomModal";
import { useTranslation } from "react-i18next";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { IMPORT_KEY_MAPPING } from "../../../constants/app-constants";
import Select from "react-select";
import MandatoryIcon from "../../presentation/MandatoryIcon";
import {
  MetaDataField,
  SelectedDocument
} from "../QuestionnaireDocumentsList/types";
import * as apis from "../../../apis/QuestionnaireDocumentsList";
import { toast } from "react-toastify";
import { validateInteger, removeFileExtension } from "../../../lib/utils";

interface Props {
  metaDataFields: MetaDataField;
  allowEdit: boolean;
  selectedDocument: SelectedDocument;
  onClose: () => void;
  onSuccess: ({
    selectedDocument,
    xmlMetadata
  }: {
    selectedDocument: SelectedDocument;
    xmlMetadata: MetaDataField;
  }) => void;
}

const AddMetaData: React.FC<Props> = ({
  metaDataFields,
  allowEdit,
  selectedDocument,
  onClose,
  onSuccess
}) => {
  const { t } = useTranslation();
  const validatePartnerNumber = (value: string) => {
    let error;
    if (validateInteger(value)) {
      error = t("Please enter a valid partner number");
    }
    return error;
  };
  const validationSchema: any = Yup.object().shape({
    tagwords: Yup.string().test(
      "is-semi-colon-separated",
      t("Please add tagword or remove semi-colon"),
      (value) => {
        // Allow null or undefined values
        if (value === null || value === undefined) return true;
        // Disallow strings that contain only whitespace
        if (/^\s+$/.test(value)) return false;
        // Regex to validate semicolon-separated values
        const semiColonSeparatedRegex = /^(\s*[^;\s]+\s*;\s*)*\s*[^;\s]+\s*$/;
        // Validate the input using the regex
        return semiColonSeparatedRegex.test(value);
      }
    ),
    partnerNumber: Yup.string().required(
      `*${t("Please enter partner number")}`
    ),
    documentName: Yup.string().required(`*${t("Please enter document name")}`),
    importKey: Yup.object()
      .shape({
        value: Yup.string().required(`*${t("Please enter document type")}`)
      })
      .nullable()
      .required(`*${t("Please enter document type")}`)
  });
  const handleMetaDatFormSubmit = async (values: any) => {
    try {
      let response = await apis.SaveQuestionnaireFileUploadMetadata({
        questionnaireFileUploadId: selectedDocument.documentId,
        documentName:
          values.documentName.trim() || metaDataFields.documentName.trim(),
        partnerNumber: values.partnerNumber.toString(),
        importSystem: values.importSystem,
        importKey: values.importKey.value,
        tagwords: values.tagwords
      });
      const { message, questionnaireFileUploadMetadata } =
        response.data.saveQuestionnaireFileUploadMetadata;
      toast.success(message);
      onSuccess({
        selectedDocument: selectedDocument,
        xmlMetadata: questionnaireFileUploadMetadata
      });
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      } else {
        console.error("Unknown error:", err);
      }
    }
  };
  return (
    <CustomModal
      modalTitle={t("Export document to Doxis")}
      classNames="modal modal--large"
      showModal={true}
      onClose={onClose}
    >
      <div className="tab-content que-tab-content">
        <Formik
          initialValues={{
            ...metaDataFields,
            importKey: metaDataFields.importKey
              ? IMPORT_KEY_MAPPING.find(
                  (val) => val.value === metaDataFields.importKey
                )
              : null,
            documentName: metaDataFields.documentName
              ? removeFileExtension(metaDataFields.documentName)
              : ""
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            // same shape as initial values
            await handleMetaDatFormSubmit(values);
          }}
        >
          {({ setFieldValue, values, handleBlur }) => (
            <Form>
              <div id="onetime" className="tab-pane in bg-none border-0 p-0">
                <div className="row sm-gutters">
                  <div className="col-6">
                    <div className="form-group choices__inner__border">
                      <label>{t("Tagwords")}</label>
                      <Field
                        id="tagwords"
                        className="form-control form-control--bordered"
                        name="tagwords"
                        disabled={!allowEdit}
                        placeholder={t(
                          "Separate multiple tagwords with semicolon"
                        )}
                      />
                      <span className="error-m">
                        <ErrorMessage name="tagwords"></ErrorMessage>
                      </span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group choices__inner__border">
                      <label>
                        {t("Document Name")}
                        <MandatoryIcon />
                      </label>
                      <Field
                        id="documentName"
                        className="form-control form-control--bordered"
                        disabled={!allowEdit}
                        name="documentName"
                      />
                      <span className="error-m">
                        <ErrorMessage name="documentName"></ErrorMessage>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row sm-gutters">
                  <div className="col-6">
                    <div className="form-group choices__inner__border">
                      <label>
                        {t("Partner Number")}
                        <MandatoryIcon />
                      </label>
                      <Field
                        id="partnerNumber"
                        type="number"
                        step="1"
                        className="form-control form-control--bordered"
                        name="partnerNumber"
                        disabled={!allowEdit}
                        placeholder="100902938"
                        validate={validatePartnerNumber}
                      />
                      <span className="error-m">
                        <ErrorMessage name="partnerNumber"></ErrorMessage>
                      </span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group choices__inner__border">
                      <label>{t("Creator")}</label>
                      <Field
                        id="creator"
                        disabled
                        className="form-control form-control--bordered"
                        name="creator"
                      />
                    </div>
                  </div>
                </div>
                <div className="row sm-gutters">
                  <div className="col-6">
                    <div className="form-group choices__inner__border">
                      <label>
                        {t("Document type")}
                        <MandatoryIcon />
                      </label>
                      <Select
                        value={values?.importKey}
                        placeholder={t("Select")}
                        name="importKey"
                        onChange={(val) => {
                          setFieldValue("importKey", val);
                        }}
                        options={IMPORT_KEY_MAPPING.sort((a, b) =>
                          a.label.localeCompare(b.label)
                        )}
                        isDisabled={!allowEdit}
                        onBlur={handleBlur("importKey")}
                      />
                      <span className="error-m">
                        <ErrorMessage name="importKey"></ErrorMessage>
                      </span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group choices__inner__border">
                      <label>{t("Import System")}</label>
                      <Field
                        id="importSystem"
                        disabled
                        className="form-control form-control--bordered"
                        name="importSystem"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal__footer modal-footer pl-0 pr10">
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-outline-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onClose();
                    }}
                  >
                    {t("Cancel")}
                  </button>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={!allowEdit}
                  >
                    {t("Save")}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </CustomModal>
  );
};

export default AddMetaData;
