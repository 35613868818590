import * as utils from "../../../lib/utils";
import {
  findUserPermissionsFromRoles,
  USER_ADMIN,
  ADMIN_DASHBOARD_PERMISSION,
  ASSET_MANAGER_USER
} from "../../../lib/questionnairePermissionsAccess";
import { BHY } from "../../../constants/app-constants";

export function getTabDetails(
  activeTab: string,
  accountId: number | string,
  userType: string
) {
  let tabs = [
    {
      className:
        activeTab === "Questionnaires" ? "nav-link active" : "nav-link",
      role: "tab",
      ariaControls: "Questionnaires",
      ariaSelected: true,
      tabName: "Questionnaires",
      tabLink: `/accountManagerInfo/${accountId}/responseList`,
      visible: utils.isCounterPartyOnly(userType) ? false : true
    },
    {
      className:
        activeTab === "Received Assignments" ? "nav-link active" : "nav-link",
      role: "tab",
      ariaControls: "Received Assignments",
      ariaSelected: true,
      tabName: "Received Assignments",
      tabLink: `/accountManagerInfo/${accountId}/assignmentsList`,
      visible: utils.isCounterParty(userType) ? true : false
    },
    {
      className: activeTab === "Mail Settings" ? "nav-link active" : "nav-link",
      role: "tab",
      ariaControls: "Mail Settings",
      ariaSelected: false,
      tabName: "Mail Settings",
      tabLink: `/accountManagerInfo/${accountId}/questionnairesList`,
      visible: utils.isCounterPartyOnly(userType) ? false : true
    },
    {
      className: activeTab === "Users" ? "nav-link active" : "nav-link",
      role: "tab",
      ariaControls: "Users",
      ariaSelected: false,
      tabName: "Users",
      tabLink: `/accountManagerInfo/${accountId}/userManagement`,
      visible:
        findUserPermissionsFromRoles([{ name: USER_ADMIN }]) &&
        process.env.REACT_APP_ORGANIZATION === BHY
    },
    {
      className: activeTab === "Groups" ? "nav-link active" : "nav-link",
      role: "tab",
      ariaControls: "Groups",
      ariaSelected: false,
      tabName: "Groups",
      tabLink: `/accountManagerInfo/${accountId}/groupManagement`,
      visible:
        findUserPermissionsFromRoles([{ name: USER_ADMIN }]) &&
        process.env.REACT_APP_ORGANIZATION === BHY
    },
    {
      className: activeTab === "Dashboard" ? "nav-link active" : "nav-link",
      role: "tab",
      ariaControls: "Dashboard",
      ariaSelected: false,
      tabName: "Dashboard",
      tabLink: `/accountManagerInfo/${accountId}/dashboard`,
      visible: findUserPermissionsFromRoles([
        { name: USER_ADMIN, permissionType: ASSET_MANAGER_USER },
        { name: ADMIN_DASHBOARD_PERMISSION }
      ])
    }
  ];

  return tabs;
}

export function getDefaultRoute(path: string) {
  //console.log("getDefaultRoute called with >> ", path);
  switch (path) {
    case "responseList":
      return "responseList";
    case "assignmentsList":
      return "assignmentsList";
    case "questionnairesList":
      return "questionnairesList";
    case "userManagement":
      return "userManagement";
    case "groupManagement":
      return "groupManagement";
    case "dashboard":
      return "dashboard";
    default:
      return "responseList";
  }
}

export function getDefaultTab(nestedRoute: string) {
  //console.log("getDefaultTab called with >> ", nestedRoute);
  switch (nestedRoute) {
    case "userManagement":
      return "Users";
    case "groupManagement":
      return "Groups";
    case "questionnairesList":
      return "Mail Settings";
    case "assignmentsList":
      return "Received Assignments";
    case "dashboard":
      return "Dashboard";
    case "responseList":
    default:
      return "Questionnaires";
  }
}
