import React, { useContext, useState } from "react";
import CustomModal from "../presentation/Modals/CustomModal";
import AddUBOs from "./AddUBOs";
import {
  UBODetailsType,
  PersonOfInterestType,
  OrgTreeDetails,
  ImportUBOOwnershipTree,
  SelectDropDownType
} from "./types";
import ShowKnownUboList from "./FinalUboDetails/ShowKnownUboList";
import ShowDerivedUboList from "./FinalUboDetails/ShowDerivedUboList";
import PersonOfInterest from "./PersonOfInterest";
//import * as apis from "../../apis/CounterpartySubmittedQuestionnaire";
import { KNOWN, DERIVED, FICTITIOUS } from "./AddUBOs/service";
//import { formatGraphQLError } from "../../lib/utils";
import { toast } from "react-toastify";
import ShowFictitiousUboList from "./FinalUboDetails/ShowFictitiousUboList";
import { convertTree } from "./OrganizationChart/service";
import OrganizationChart from "./OrganizationChart";
import { useTranslation } from "react-i18next";
import {
  getDefaultUBOIndentification,
  parseToUBOOrgStructure
} from "./service";
import { markUBOsInOrgStructure } from "./AddUBOs/DerivedUBO/service";
import ExternalCompaniesList from "../presentation/ExternalCompaniesList";
import { getCompanyUBOs, orderExternalUboTree } from "../../apis/CompanySearch";
import { QuestionnaireContext } from "../../Contexts/QuestionnaireContext";
import { hasKyckrPermission } from "../../lib/questionnairePermissionsAccess";
interface Props {
  saveQuestionDetails: any;
  voteThreshold: number;
  shareThreshold: number;
  //Above 2 parameters are used to idenntify the ubos
  answer?: UBODetailsType;
  fileUploadParams: any;
  allowEdit: boolean;
  questionId: string | number;
  onChange: (data: any) => void;
  sectionId: string | number;
}

const UBOEngine: React.FC<Props> = ({
  voteThreshold,
  shareThreshold,
  saveQuestionDetails,
  answer,
  fileUploadParams,
  allowEdit,
  questionId,
  onChange,
  sectionId
}) => {
  const { t } = useTranslation();
  const [showAddUBO, setShowAddUBO] = useState(false);
  const [showImportUBO, setShowImportUBO] = useState(false);
  const [uboDetails, setUboDetails] = useState<UBODetailsType | undefined>({
    ...answer
  });
  const [externalUboOrder, setExternalUboOrder] = useState<any>(
    saveQuestionDetails.questionAnswers.answer.externalUboOrder
  );
  const [selectedImportCompany, setSelectedImportCompany] =
    useState<SelectDropDownType | null>();

  const { questionnaire } = useContext(QuestionnaireContext);

  function saveUboDetails(details: UBODetailsType) {
    setUboDetails({ ...details });
    onChange(details);
  }
  function onUpdatePersonOfInterest(data: PersonOfInterestType[]) {
    let parsedData = {
      ...uboDetails,
      personOfInterest: [...data]
    };
    setUboDetails({
      ...parsedData
    });
    onChange(parsedData);
    // apis
    //   .saveAnswers({
    //     ...saveQuestionDetails,
    //     questionAnswers: [
    //       {
    //         ...saveQuestionDetails.questionAnswers,
    //         answer: JSON.stringify(parsedData)
    //       }
    //     ]
    //   })
    //   .then((res) => {
    //     toast.success("Data saved successsfully");
    //     setUboDetails({
    //       ...parsedData
    //     });
    //   })
    //   .catch((error) => {
    //     toast.error(formatGraphQLError(error.message));
    //   });
  }
  async function getUBODetails(accountQuestionnaireId: string | number) {
    const uboData: any = await getCompanyUBOs({
      questionId,
      accountQuestionnaireId,
      sectionId,
      orderId: externalUboOrder?.id
    }).catch((e) => {
      console.error(e);
    });
    const ownershipTree: ImportUBOOwnershipTree =
      uboData?.data?.getExternalUbos?.ownershipTreeField;
    if (!ownershipTree) {
      toast.error(t("Unable to import UBO data for the selected company"));
      return;
    }
    const parsedData: OrgTreeDetails = parseToUBOOrgStructure({
      nodeFields: ownershipTree.nodesField
    });
    const updatedData: OrgTreeDetails = markUBOsInOrgStructure({
      orgTree: parsedData,
      shareHolding: shareThreshold,
      voteRights: voteThreshold
    });
    setUboDetails({
      ...uboDetails,
      uboInfoType: getDefaultUBOIndentification(),
      orgStructure: { ...updatedData },
      ubosFromOrgStructure: updatedData?.owners || [],
      isFinalized: true,
      importedUBOdata: ownershipTree
    });
    setShowAddUBO(true);
  }
  async function importUBOData() {
    if (!selectedImportCompany?.id) {
      toast.error(t("Please select a company to import UBO data"));
      return;
    }
    const accountQuestionnaireId =
      fileUploadParams?.accountLevelOneQuestionnaireId;
    const companyCode = selectedImportCompany?.id;
    const uboOrder: any = await orderExternalUboTree({
      accountQuestionnaireId,
      companyCode,
      sectionId,
      questionId
    }).catch((e) => {
      console.error(e);
    });
    setExternalUboOrder(uboOrder.data?.orderExternalUboTree?.order);
    onClose();
  }
  function onClose() {
    setSelectedImportCompany(null);
    setShowImportUBO(false);
  }
  function showUboList() {
    switch (uboDetails?.uboInfoType?.value) {
      case KNOWN:
        return uboDetails.isFinalized ? (
          <>
            <div className="company-ubo-heading">Known UBOs</div>
            <ShowKnownUboList
              uboData={uboDetails?.knownUbosDetails || { knownUBOs: [] }}
              fileParams={{
                ...fileUploadParams,
                sectionId: saveQuestionDetails.sectionId
              }}
            />
          </>
        ) : (
          <div style={{ color: "red" }}>
            *
            {t(
              `Please finalise the ubo details by clicking on "Launch UBO engine"`
            )}
          </div>
        );
      case DERIVED:
        return uboDetails.isFinalized ? (
          <>
            <div className="company-ubo-heading">{t("Derived UBOs")}</div>
            <ShowDerivedUboList
              uboData={uboDetails?.ubosFromOrgStructure || []}
              fileParams={{
                ...fileUploadParams,
                sectionId: saveQuestionDetails.sectionId
              }}
            />
            <div className="ubo-chart">
              <div className="ubo-chart-heading-row">
                <div className="ubo-chart-heading">
                  {t("Company structure diagram")}
                </div>
              </div>
              <div className="ubo-chart-in">
                {uboDetails?.orgStructure && (
                  <div style={{ height: "500px" }}>
                    <OrganizationChart
                      hierarchy={convertTree(uboDetails?.orgStructure, true)}
                      scrollToZoomDisabled
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div style={{ color: "red" }}>
            *
            {t(
              `Please finalise the ubo details by clicking on "Launch UBO engine"`
            )}
          </div>
        );
      case FICTITIOUS:
        return uboDetails.isFinalized ? (
          <>
            <div className="company-ubo-heading">Fictitious UBOs</div>
            <ShowFictitiousUboList
              uboData={uboDetails?.fictitiousUbos || []}
              fileParams={{
                ...fileUploadParams,
                sectionId: saveQuestionDetails.sectionId
              }}
            />
            <div className="ubo-chart">
              <div className="ubo-chart-heading-row">
                <div className="ubo-chart-heading">
                  {t("Company structure diagram")}
                </div>
              </div>
              <div className="ubo-chart-in">
                {uboDetails?.orgStructure && (
                  <div style={{ height: "500px" }}>
                    <OrganizationChart
                      hierarchy={convertTree(uboDetails?.orgStructure, true)}
                      scrollToZoomDisabled
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div style={{ color: "red" }}>
            *
            {t(
              `Please finalise the ubo details by clicking on "Launch UBO engine"`
            )}
          </div>
        );

      default:
        return t("No UBOs present");
    }
  }
  return (
    <>
      <div className="d-inline-flex">
        <div className="ubo-question">
          <div className="company-ubo-button mt-0">
            <button
              disabled={!allowEdit || externalUboOrder?.status === "pending"}
              className="btn btn-primary"
              onClick={() => {
                if (
                  externalUboOrder &&
                  externalUboOrder.status === "ready" &&
                  !saveQuestionDetails.questionAnswers.answer.answer
                ) {
                  getUBODetails(
                    fileUploadParams.accountLevelOneQuestionnaireId
                  );
                }
                setShowAddUBO(true);
              }}
            >
              {t("Launch UBO engine")}
            </button>
          </div>
        </div>
        {hasKyckrPermission({
          permissions: questionnaire?.sectionDetail?.permissions || [],
          permissionType: "UBO"
        }) && (
          <div className="ubo-question">
            <div className="company-ubo-button mt-0">
              <button
                disabled={
                  !allowEdit ||
                  !questionnaire?.allowExternalInfoRetrieval ||
                  (externalUboOrder && externalUboOrder?.status !== "failed")
                }
                className="btn btn-primary"
                onClick={() => {
                  setShowImportUBO(true);
                }}
              >
                {t("Import UBO Information")}
              </button>
            </div>
          </div>
        )}
      </div>
      {externalUboOrder && (
        <>
          {externalUboOrder.status === "pending" ? (
            <div style={{ color: "red" }}>
              {t(
                "The UBO chart is currently being created. Please check after some time."
              )}
            </div>
          ) : null}
          {externalUboOrder.status === "ready" ? (
            <div style={{ color: "red" }}>
              {t(
                'The chart is ready. Please click on "Launch UBO engine" to see and edit it.'
              )}
            </div>
          ) : null}
          {externalUboOrder.status === "failed" && (
            <div style={{ color: "red" }}>
              {t(
                'The UBO information could not be retrieved. Please enter the information manually by clicking on "Launch UBO engine".'
              )}
            </div>
          )}
        </>
      )}
      {showUboList()}

      <PersonOfInterest
        personsOfInterest={uboDetails?.personOfInterest || []}
        onUpdate={onUpdatePersonOfInterest}
        allowEdit={allowEdit}
        questionId={questionId}
      />

      {showAddUBO && (
        <CustomModal
          classNames="cdd-groups-management cdd-auto-feature editable ubo-tool-modal"
          showModal={showAddUBO}
          // onClose={() => {
          //   setShowAddUBO(false);
          // }}
          bodyStyle={{ padding: "0px", minHeight: "100%" }}
          contentClassName="fullScreenModal"
        >
          <AddUBOs
            voteThreshold={voteThreshold}
            shareThreshold={shareThreshold}
            saveQuestionDetails={saveQuestionDetails}
            answer={uboDetails}
            onSave={saveUboDetails}
            onClose={() => setShowAddUBO(false)}
            fileUploadParams={fileUploadParams}
            allowEdit={allowEdit}
          />
        </CustomModal>
      )}
      {showImportUBO && (
        <CustomModal
          modalTitle={t("Select Company")}
          showModal={showImportUBO || false}
          onClose={onClose}
          showFooter={true}
          footer={
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <button className="btn btn-outline-primary" onClick={onClose}>
                {t("Cancel")}
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  importUBOData();
                }}
              >
                {t("Confirm")}
              </button>
            </div>
          }
        >
          <div className="mb-3">
            <ExternalCompaniesList
              onCompanySelect={(data) => {
                setSelectedImportCompany(data);
              }}
            />
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default UBOEngine;
