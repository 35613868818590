import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const ENG = "en";
export const GER = "de";

const LanguageSelector: React.FC = () => {
  const { t, i18n } = useTranslation();
  const selectedLanguage = useRef<string>(
    localStorage.getItem("i18nextLng") || GER
  );

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage.current);
  }, []);

  function handleLanguageChange(selectedLanguage: string) {
    i18n.changeLanguage(selectedLanguage);
  }

  return (
    <div className="languageBoxWrp">
      <div className="text">{t("Language")}</div>
      <div className="languageBox text-right">
        <button
          className={`btn  ${selectedLanguage.current !== GER ? "active" : ""}`}
          onClick={() => {
            selectedLanguage.current = ENG;
            handleLanguageChange(ENG);
          }}
        >
          {i18n.t("English")}
        </button>
        <button
          className={`btn  ${selectedLanguage.current === GER ? "active" : ""}`}
          onClick={() => {
            selectedLanguage.current = GER;
            handleLanguageChange(GER);
          }}
        >
          {i18n.t("German")}
        </button>
      </div>
    </div>
  );
};

export default LanguageSelector;
