import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import InputField from "../../../../presentation/InputField";
//import RadioButton from "../../../../presentation/RadioButton";
import { SelectedNodeShareHolder } from "../../../types";
import { checkAvailability, validateData, parseData } from "./service";
import { getSelectDefaultValue, getMultiSelectValue } from "../../service";
import Icons from "../../../../presentation/Icons";
import Files from "../../../../presentation/Files";
import MandatoryIcon from "../../../../presentation/MandatoryIcon";
import ConfirmationModal from "../../../../presentation/Modals/ConfirmationModal";
import { useTranslation } from "react-i18next";
import Flatpickr from "react-flatpickr";
import { appData } from "../../../../../services/appConfig";
import * as utils from "../../../../../lib/utils";
import { RiskCountry } from "../../../../../types/applicationData";
import Select from "react-select";
import i18n from "../../../../../i18n";
import Checkbox from "../../../../presentation/Checkbox";
import { SHARES_IN_FREE_FLOAT } from "../../../../../constants/app-constants";
interface Props {
  shareHolderDetails?: SelectedNodeShareHolder;
  getShareHolder: (data: SelectedNodeShareHolder) => void;
  remainingVotePercent: number;
  remainingSharePercent: number;
  onDelete: (nodeId: string | null, parentNodeId: string | null) => void;
  onClose: () => void;
  fileUploadParams: any;
  allowEdit: boolean;
}

const ShareholderDetails: React.FC<Props> = ({
  shareHolderDetails,
  getShareHolder,
  remainingVotePercent,
  remainingSharePercent,
  onDelete,
  onClose,
  fileUploadParams,
  allowEdit
}) => {
  const { t } = useTranslation();
  const [shareholderData, setShareHolderData] =
    useState<SelectedNodeShareHolder>();
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [confirmationBoxData, setConfirmationBoxData] = useState({
    title: "",
    message: "",
    callBack: () => {}
  });
  const [countries, setCountries] = useState<RiskCountry[]>([]);
  useEffect(() => {
    setShareHolderData({
      ...shareHolderDetails,
      entityType: shareHolderDetails?.entityType || "legalEntity"
    });
  }, [shareHolderDetails]);
  useEffect(() => {
    appData.fetchRiskCountries().then((res: any) => {
      setCountries(res);
    });
  }, []);
  function handleShareHolderDetails({ target }: any) {
    if (target.name === "isFreeFloatShareEntity" && target.value) {
      setShareHolderData({
        ...shareholderData,
        legalEntityName: t(SHARES_IN_FREE_FLOAT),
        [target.name]: target.value
      });
    } else {
      setShareHolderData({ ...shareholderData, [target.name]: target.value });
    }
  }
  function saveShareHolderData() {
    if (shareholderData) {
      let validationMessage = validateData(shareholderData);
      let message = checkAvailability({
        shareholderData,
        remainingVotePercent,
        remainingSharePercent
      });
      if (validationMessage || message) {
        toast.error(
          validationMessage ? t(validationMessage) : message ? t(message) : null
        );
      } else {
        //setShareHolderData({ ...getDefaultData({ shareHoldingData: undefined }) });
        getShareHolder({ ...parseData(shareholderData) });
        setShareHolderData(undefined);
      }
    }
  }
  return shareholderData ? (
    <>
      {" "}
      <div className="ubo-right-content">
        <div className="entity-graph">
          <div className="left-nav-step-right">
            <div className="intyty-heading l-r-15">
              <span>{t("New entity")}</span>
              <button onClick={onClose} className="btn">
                <Icons icon="chevronLeft12" />
              </button>
            </div>

            <div className="panel with-nav-tabs panel-default">
              {shareholderData?.parentNodeId && (
                <div className="panel-heading l-r-15">
                  <ul className="nav nav-tabs">
                    <li>
                      <button
                        id="legalEntity"
                        name="entityType"
                        value="legalEntity"
                        className={`btn ${
                          shareholderData?.entityType === "legalEntity"
                            ? "active"
                            : ""
                        }`}
                        data-toggle="tab"
                        aria-expanded="true"
                        onClick={({ target }: { target: any }) => {
                          handleShareHolderDetails({ target });
                        }}
                        disabled={!allowEdit}
                      >
                        {t("Legal entity")}
                      </button>
                    </li>

                    <li aria-expanded="false">
                      <button
                        id="naturalPerson"
                        name="entityType"
                        className={`btn ${
                          shareholderData?.entityType === "naturalPerson"
                            ? "active"
                            : ""
                        }`}
                        value="naturalPerson"
                        data-toggle="tab"
                        aria-expanded="false"
                        onClick={({ target }: { target: any }) => {
                          handleShareHolderDetails({ target });
                        }}
                        disabled={!allowEdit}
                      >
                        {t("Natural person")}
                      </button>
                    </li>
                  </ul>
                </div>
              )}

              <div className="panel-body">
                <div className="tab-content">
                  <div
                    className="tab-pane fade in active show"
                    id="natural-person"
                    aria-expanded="false"
                  >
                    <div className="form intity-form ">
                      <div className="l-r-15">
                        {shareholderData?.entityType === "legalEntity" && (
                          <>
                            <div className="form-group common-input">
                              <Checkbox
                                id={`shares_in_free_float`}
                                name="isFreeFloatShareEntity"
                                label={t(SHARES_IN_FREE_FLOAT)}
                                value={String(
                                  shareholderData?.isFreeFloatShareEntity
                                )}
                                checked={
                                  shareholderData?.isFreeFloatShareEntity ||
                                  false
                                }
                                onChange={(e: any) => {
                                  handleShareHolderDetails({
                                    target: {
                                      name: e.target.name,
                                      value: e.target.checked
                                    }
                                  });
                                }}
                              />
                            </div>
                            <div className="form-group common-input">
                              <Checkbox
                                id={`entity_with_no_ubo`}
                                name="isEntityWithNoUbo"
                                label={"Entity with no UBO"}
                                value={String(
                                  shareholderData?.isEntityWithNoUbo
                                )}
                                checked={
                                  shareholderData.isFreeFloatShareEntity ||
                                  shareholderData?.isEntityWithNoUbo ||
                                  false
                                }
                                isDisabled={
                                  shareholderData.isFreeFloatShareEntity
                                }
                                onChange={(e: any) => {
                                  handleShareHolderDetails({
                                    target: {
                                      name: e.target.name,
                                      value: e.target.checked
                                    }
                                  });
                                }}
                              />
                            </div>
                            <div className="form-group common-input">
                              <label htmlFor="text" className="label-pd">
                                <MandatoryIcon /> {t("Company Name")}
                              </label>
                              <InputField
                                inputType="text"
                                id="legalEntityName"
                                placeholder={t("Legal entity name")}
                                value={shareholderData?.legalEntityName || ""}
                                name="legalEntityName"
                                onChange={handleShareHolderDetails}
                                isDisabled={
                                  !allowEdit ||
                                  shareholderData?.isFreeFloatShareEntity
                                }
                              />
                            </div>
                          </>
                        )}
                        {shareholderData?.entityType === "naturalPerson" && (
                          <>
                            <div className="form-group common-input">
                              <label htmlFor="text" className="label-pd">
                                <MandatoryIcon /> {t("First Name")}
                              </label>
                              <InputField
                                inputType="text"
                                id="firstName"
                                placeholder="First name"
                                value={shareholderData?.firstName || ""}
                                name="firstName"
                                onChange={handleShareHolderDetails}
                                isDisabled={!allowEdit}
                              />
                            </div>
                            <div className="form-group common-input">
                              <label htmlFor="text" className="label-pd">
                                <MandatoryIcon /> {t("Last Name")}
                              </label>
                              <InputField
                                inputType="text"
                                id="lastName"
                                placeholder={t("Last name")}
                                value={shareholderData?.lastName || ""}
                                name="lastName"
                                onChange={handleShareHolderDetails}
                                isDisabled={!allowEdit}
                              />
                            </div>
                          </>
                        )}
                        {shareHolderDetails &&
                          shareHolderDetails.nodeId !== "1" && (
                            <>
                              <div className="form-group common-input">
                                <label htmlFor="text" className="label-pd">
                                  <MandatoryIcon /> {t("Voting rights")}(%)
                                </label>
                                <InputField
                                  inputType="number"
                                  min={0}
                                  max={100}
                                  id="votingRights"
                                  placeholder="Voting rights"
                                  value={shareholderData?.votingRights ?? ""}
                                  name="votingRights"
                                  onChange={handleShareHolderDetails}
                                  isDisabled={!allowEdit}
                                />
                              </div>
                              <div className="form-group common-input">
                                <label htmlFor="text" className="label-pd">
                                  <MandatoryIcon /> {t("Capital share")}(%)
                                </label>
                                <InputField
                                  inputType="number"
                                  min={0}
                                  max={100}
                                  id="shareHolding"
                                  placeholder="Capital share"
                                  value={shareholderData?.shareHolding ?? ""}
                                  name="shareHolding"
                                  onChange={handleShareHolderDetails}
                                  isDisabled={!allowEdit}
                                />
                              </div>
                            </>
                          )}
                        <div className="file-upload">
                          <div className="file-upload-label label-pd">
                            <div> {t("Documentation")}:</div>
                            <div> {t("If available, please upload")}:</div>
                            <div>
                              <ul
                                style={{
                                  listStyleType: "disc",
                                  paddingLeft: "10pt"
                                }}
                              >
                                <li>
                                  {t("Excerpt from the commercial register")}
                                </li>
                                <li>{t("Articles of association")}</li>
                                <li> {t("List of shareholders")} </li>
                                <li>{t("Shareholders' agreement")}</li>
                                <li>
                                  {t("Excerpt from the transparency register")}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="form-group">
                            <Files
                              uploadButtonName="Choose files"
                              uploadFiles={allowEdit}
                              uploaderId="uboEntity"
                              downloadFiles={true}
                              files={shareholderData?.documents || []}
                              onChange={(
                                files: [
                                  {
                                    originalFileName: string;
                                    timeStampedFileName: string;
                                  }
                                ]
                              ) => {
                                handleShareHolderDetails({
                                  target: { name: "documents", value: files }
                                });
                              }}
                              fileParams={fileUploadParams}
                            />
                          </div>
                        </div>
                      </div>

                      {shareholderData?.entityType === "naturalPerson" && (
                        <div className="divided-line l-r-15">
                          <div>
                            <h3>{t("UBO – Extra information")}</h3>
                            <p className="text-grey-ubo">
                              {t(
                                `We require extra information if the person is a UBO. If you aren’t sure we will let you know when you finalise which require additional information`
                              )}
                              .
                            </p>
                          </div>

                          <div className="form-group common-input">
                            <label htmlFor="text" className="label-pd">
                              <MandatoryIcon /> {t("Country")}
                            </label>
                            <Select
                              value={
                                shareholderData?.country
                                  ? getSelectDefaultValue(
                                      shareholderData?.country.id,
                                      countries
                                    )
                                  : null
                              }
                              options={utils.modifyForSelectBoxi18n(
                                countries,
                                "id",
                                "name",
                                i18n.language === "de" ? "germanName" : "name"
                              )} // name="country"
                              placeholder={t("Country")}
                              noOptionsMessage={() => t("No options")}
                              onChange={(ele: any) => {
                                handleShareHolderDetails({
                                  target: {
                                    name: "country",
                                    value: { id: ele.id, name: ele.value }
                                  }
                                });
                              }}
                              isDisabled={!allowEdit}
                            />
                          </div>

                          <div className="form-group common-input">
                            <label htmlFor="text" className="label-pd">
                              <MandatoryIcon /> {t("Date of birth")}
                            </label>
                            <Flatpickr
                              disabled={!allowEdit}
                              value={shareholderData?.dob}
                              onChange={(date: any) => {
                                let dateStr = utils.getDate(date[0]);
                                handleShareHolderDetails({
                                  target: { name: "dob", value: dateStr }
                                });
                              }}
                              className="form-control form-control--bordered"
                              options={{
                                dateFormat: "d-m-Y",
                                mode: "single",
                                disable: [
                                  function (date) {
                                    return date > new Date();
                                  }
                                ]
                              }}
                            />
                          </div>

                          <div className="form-group common-input">
                            <label htmlFor="text" className="label-pd">
                              <MandatoryIcon color="black" />{" "}
                              {t("Tax-ID number")}
                            </label>
                            <InputField
                              inputType="text"
                              id="derived-ubo-tax-id-number"
                              placeholder="xxx-xx-xx"
                              value={shareholderData?.taxIdNumber || ""}
                              name="taxIdNumber"
                              onChange={handleShareHolderDetails}
                              isDisabled={!allowEdit}
                            />
                          </div>

                          <div className="form-group common-input">
                            <label htmlFor="text" className="label-pd">
                              {t("Comments")}
                            </label>
                            <InputField
                              inputType="text"
                              id="derived-ubo-comments"
                              placeholder="Comments"
                              value={shareholderData?.comments || ""}
                              name="comments"
                              onChange={handleShareHolderDetails}
                              isDisabled={!allowEdit}
                            />
                          </div>

                          <div className="form-group common-input">
                            <label htmlFor="text" className="label-pd">
                              {t("Address")}
                            </label>
                            <InputField
                              inputType="text"
                              id="derived-ubo-address"
                              placeholder="Address"
                              value={shareholderData?.address || ""}
                              name="address"
                              onChange={handleShareHolderDetails}
                              isDisabled={!allowEdit}
                            />
                          </div>

                          <div className="form-group common-input">
                            <label htmlFor="text" className="label-pd">
                              {t("Zip")}
                            </label>
                            <InputField
                              inputType="text"
                              id="derived-ubo-zip"
                              placeholder="Zip"
                              value={shareholderData?.zip || ""}
                              name="zip"
                              onChange={handleShareHolderDetails}
                              isDisabled={!allowEdit}
                            />
                          </div>

                          <div className="form-group common-input">
                            <label htmlFor="text" className="label-pd">
                              <MandatoryIcon /> {t("City/Place of residence")}
                            </label>
                            <InputField
                              inputType="text"
                              id="derived-ubo-city"
                              placeholder="City/Place of residence"
                              value={shareholderData?.city || ""}
                              name="city"
                              onChange={handleShareHolderDetails}
                              isDisabled={!allowEdit}
                            />
                          </div>
                          <div className="form-group common-input">
                            <label htmlFor="text" className="label-pd">
                              {t("Nationality")}
                            </label>
                            <Select
                              value={
                                shareholderData?.nationality
                                  ? getMultiSelectValue(
                                      shareholderData?.nationality,
                                      countries
                                    )
                                  : []
                              }
                              options={utils.modifyForSelectBoxi18n(
                                countries,
                                "id",
                                "name",
                                i18n.language === "de" ? "germanName" : "name"
                              )}
                              isMulti={true}
                              // name="country"
                              placeholder={t("Nationality")}
                              onChange={(ele: any) => {
                                handleShareHolderDetails({
                                  target: {
                                    name: "nationality",
                                    value: ele
                                  }
                                });
                              }}
                              isDisabled={!allowEdit}
                            />
                          </div>
                          <div className="ubo-add-input">
                            <div className="form-group common-input">
                              <label htmlFor="text" className="label-pd">
                                <MandatoryIcon /> {t("Place of birth")}
                              </label>
                              <InputField
                                inputType="text"
                                id={`derived-ubo-place-of-birth`}
                                placeholder="Place of birth"
                                value={shareholderData.placeOfBirth || ""}
                                name="placeOfBirth"
                                onChange={handleShareHolderDetails}
                                isDisabled={!allowEdit}
                              />
                            </div>
                          </div>
                          <div className="ubo-add-input file-document-box">
                            <div className="form-group common-input">
                              *
                              {t(
                                "If exempt from providing the Tax-ID number,please include the reason as a comment."
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {allowEdit && (
              <div className="approve-btn-bottom l-r-15">
                <div
                  className="approveText"
                  onClick={() => {
                    setShowConfirmationModal(true);
                    setConfirmationBoxData(
                      Object.assign(
                        {},
                        {
                          title: "Confirm Delete",
                          message: "Are you sure want to delete the entity ?",
                          callBack: () => {
                            setShowConfirmationModal(false);
                            onDelete(
                              shareHolderDetails?.nodeId || null,
                              shareHolderDetails?.parentNodeId || null
                            );
                          }
                        }
                      )
                    );
                  }}
                >
                  <Icons className="icon" icon="delete" />
                </div>
                <div className="done-ubo">
                  <button
                    className="btn btn-primary"
                    onClick={saveShareHolderData}
                  >
                    {t("Save")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfirmationModal
        modalTitle={confirmationBoxData.title}
        message={confirmationBoxData.message}
        callBack={confirmationBoxData.callBack}
        showConfirmationModal={showConfirmationModal}
        onModalClose={() => {
          setShowConfirmationModal(false);
        }}
      />
    </>
  ) : (
    <></>
  );
};
export default ShareholderDetails;
//   return shareholderData ? (
//     <>
//       <RadioButton
//         id="naturalPerson"
//         name="entityType"
//         label="Natural Person"
//         value="naturalPerson"
//         checked={shareholderData.entityType || "naturalPerson"}
//         onChange={({ target }: { target: any }) => {
//           handleShareHolderDetails({ target });
//         }}
//         // isDisabled={!allowAnswerSubmit}
//       />

//       <RadioButton
//         id="legalEntity"
//         name="entityType"
//         label="Legal entity"
//         value="legalEntity"
//         checked={shareholderData.entityType || "naturalPerson"}
//         onChange={({ target }: { target: any }) => {
//           handleShareHolderDetails({ target });
//         }}
//         //isDisabled={!allowAnswerSubmit}
//       />

//       <div>
//         <InputField
//           inputType="text"
//           id="legalEntityName"
//           placeholder="Legal entity name"
//           value={shareholderData.legalEntityName || ""}
//           name="legalEntityName"
//           onChange={handleShareHolderDetails}
//         />
//       </div>
//       {shareHolderDetails && shareHolderDetails.nodeId !== "1" && (
//         <>
//           {" "}
//           <InputField
//             inputType="text"
//             id="votingRights"
//             placeholder="Voting rights"
//             value={shareholderData.votingRights || 0}
//             name="votingRights"
//             onChange={handleShareHolderDetails}
//           />
//           <InputField
//             inputType="text"
//             id="shareHolding"
//             placeholder="Share holding"
//             value={shareholderData.shareHolding || 0}
//             name="shareHolding"
//             onChange={handleShareHolderDetails}
//           />
//         </>
//       )}
//       <button
//         type="button"
//         className="dropdown-item dropdown-item--apply"
//         onClick={saveShareHolderData}
//       >
//         Done
//       </button>
//     </>
//   ) : (
//     <div></div>
//   );
// };
