import React from "react";
import { Column } from "react-table";
import Checkbox from "../../presentation/Checkbox";
import { isEqual, sortBy } from "lodash";
import { TFunction } from "i18next";
export function getExternalDocumentsListColumnConfig(
  selectedRows: string[],
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>,
  t: TFunction
) {
  const columnConfig: Column<any>[] = [
    {
      Header: (headProps: any) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Checkbox
            {...headProps.getToggleAllRowsSelectedProps()}
            id={`table-${1}-checkbox-all`}
            name="checkbox"
            checked={
              !!selectedRows.length &&
              isEqual(
                sortBy(selectedRows),
                sortBy(headProps.rows.map((row: any) => row.original.productId))
              )
            }
            onChange={(e) => {
              setSelectedRows((prevSelectedRows: string[]) => {
                const newSelectedRows = new Set(prevSelectedRows);

                if (e.target.checked) {
                  headProps.rows.forEach((row: any) => {
                    newSelectedRows.add(row.original.productId);
                  });
                } else {
                  headProps.rows.forEach((row: any) => {
                    newSelectedRows.delete(row.original.productId);
                  });
                }

                return Array.from(newSelectedRows) as string[];
              });
            }}
          />
        </div>
      ),
      accessor: "isSelected",
      Cell: (cellProps: any) => {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Checkbox
              {...cellProps.row.getToggleRowSelectedProps()}
              name="checkbox"
              checked={
                !!selectedRows.length &&
                selectedRows.includes(cellProps.row.original.productId)
              }
              onChange={(e) => {
                setSelectedRows((prevSelectedRows: string[]) => {
                  const productId = cellProps.row.original.productId;
                  const newSelectedRows = new Set(prevSelectedRows);

                  if (newSelectedRows.has(productId)) {
                    newSelectedRows.delete(productId);
                  } else {
                    newSelectedRows.add(productId);
                  }

                  return Array.from(newSelectedRows) as string[];
                });
              }}
              id={`table-${1}-checkbox-${cellProps.row.id}`}
            />
          </div>
        );
      }
    },
    {
      Header: "Id",
      accessor: "productId"
    },
    {
      Header: t("Document") || "Document",
      accessor: "title",
      Cell: (cellProps: any) => <span>{cellProps.value || "-"}</span>
    },
    {
      Header: "Credits",
      accessor: "credits",
      Cell: (cellProps: any) => (
        <span>{cellProps.value ? cellProps.value : "-"}</span>
      )
    },
    {
      Header: "Company Code",
      accessor: "companyCode",
      Cell: (cellProps: any) => <span>{cellProps.value || "-"}</span>
    },
    {
      Header: t("Document type") || "Document type",
      accessor: "format",
      Cell: (cellProps: any) => <span>{cellProps.value || "-"}</span>
    },
    {
      Header: t("Delivery time (min)") || "Delivery time (min)",
      accessor: "deliveryTime",
      Cell: (cellProps: any) => <span>{cellProps.value || "-"}</span>
    }
  ];
  return columnConfig;
}
