import React, { useState, useEffect } from "react";
import {
  uboQuestionDropDown,
  getSelectDefaultValue,
  uboQuestionDropDownGerman,
  getTranslatedLabel,
  getMultiSelectValue
} from "../service";
import {
  SelectDropDownType,
  KnownUBOType,
  KnownUBOListType
} from "../../types";
import Select from "react-select";
import InputField from "../../../presentation/InputField";
import Files from "../../../presentation/Files";
import MandatoryIcon from "../../../presentation/MandatoryIcon";
import FieldErrorMessage from "../../../presentation/FieldErrorMessage";
import { isNumericValue } from "../../../../lib/utils";
import ConfirmationModal from "../../../presentation/Modals/ConfirmationModal";
import { useTranslation } from "react-i18next";
import Flatpickr from "react-flatpickr";
import { appData } from "../../../../services/appConfig";
import * as utils from "../../../../lib/utils";
import { RiskCountry } from "../../../../types/applicationData";
import i18n from "../../../../i18n";
interface Props {
  uboInfoType: { value: string; label: string };
  onUboInfoTypeChange: (data: SelectDropDownType) => void;
  updateParent: (data: KnownUBOType) => void;
  knownUbos: KnownUBOType;
  fileUploadParams: any;
  allowEdit: boolean;
  showValidationErrors: boolean;
}
const KnownUBO: React.FC<Props> = ({
  uboInfoType,
  onUboInfoTypeChange,
  updateParent,
  knownUbos,
  fileUploadParams,
  allowEdit,
  showValidationErrors
}) => {
  const { t } = useTranslation();
  const defaultUboData: KnownUBOListType = {
    firstName: "",
    lastName: "",
    votingRights: "",
    shareHolding: "",
    country: null,
    city: "",
    placeOfBirth: "",
    dob: ""
  };

  const [uboDetails, setUboDetails] = useState<KnownUBOType>(
    knownUbos?.knownUBOs?.length ? knownUbos : { knownUBOs: [defaultUboData] }
  );
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [confirmationBoxData, setConfirmationBoxData] = useState({
    title: "",
    message: "",
    callBack: () => {}
  });
  const [countries, setCountries] = useState<RiskCountry[]>([]);
  function addUbo() {
    let dummyUboList = [...uboDetails.knownUBOs];
    dummyUboList.push(defaultUboData);
    setUboDetails({ ...uboDetails, knownUBOs: dummyUboList });
  }
  useEffect(() => {
    updateParent(uboDetails);
  }, [uboDetails]);
  useEffect(() => {
    appData.fetchRiskCountries().then((res: any) => {
      setCountries(res);
    });
  }, []);
  function deleteUbo(index: number) {
    if (uboDetails.knownUBOs.length === 1) {
      setUboDetails({ ...uboDetails, knownUBOs: [defaultUboData] });
    } else {
      let dummyUboList = [...uboDetails.knownUBOs];
      dummyUboList.splice(index, 1);
      setUboDetails({ ...uboDetails, knownUBOs: dummyUboList });
    }
  }
  function onInputChange(target: any, index: number) {
    let dummyData: any = { ...uboDetails.knownUBOs[index] };
    dummyData[target.name] = target.value;
    let dummyList = [...uboDetails.knownUBOs];
    dummyList[index] = dummyData;
    setUboDetails({ ...uboDetails, knownUBOs: dummyList });
  }
  function handleStructureDocUpload(data: any) {
    setUboDetails({ ...uboDetails, companyStructureDocuments: data });
  }
  return (
    <>
      {" "}
      <div className="ubo-sec-content">
        <div className="ubo-left-filter filter-drop-shadow">
          <div className="ubo-status l-r-15">
            <p className="ubo-status-text">
              {t("Do you already know who the UBOs are?")}
            </p>
            <Select
              placeholder={t("Select")}
              name="uboInfoType"
              onChange={(option: any) => onUboInfoTypeChange(option)}
              value={{
                ...uboInfoType,
                label: getTranslatedLabel(uboInfoType.value)
              }}
              options={
                i18n.language === "de"
                  ? uboQuestionDropDownGerman
                  : uboQuestionDropDown
              }
              isDisabled={!allowEdit}
              noOptionsMessage={() => t("No options")}
            />
          </div>

          <div className="ubo-add-file-upload l-r-15 add-bonus-ubo-wrp">
            <div className="file-upload">
              <label className="file-upload-label label-pd">
                {t("Upload company structure diagram")}
              </label>
              <div className="form-group">
                <Files
                  uploadFiles={allowEdit}
                  uploadButtonName="Choose files"
                  downloadFiles={true}
                  files={uboDetails.companyStructureDocuments || []}
                  onChange={(
                    files: [
                      {
                        originalFileName: string;
                        timeStampedFileName: string;
                      }
                    ]
                  ) => {
                    handleStructureDocUpload(files);
                  }}
                  uploaderId="structureDiagram"
                  fileParams={fileUploadParams}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ubo-right-content ubo-add-row add-fictitious-ubos-content">
          <div className="ubo-right-content-in-pd m-r-15">
            <div className="add-remove-ubo-list top-add-row">
              <div className="ubo-add-input"></div>
              <button
                disabled={!allowEdit}
                onClick={addUbo}
                className="btn btn-primary"
              >
                {t("Add another UBO")}
              </button>
            </div>

            <div className="ubo-add-list-wrp">
              {uboDetails.knownUBOs.map((ubo, index) => {
                return (
                  <div key={index} className="ubo-add-list-loop">
                    <div className="form ubo-border-box">
                      <div className="innerForm eqaual-coulmn-form">
                        <div className="ubo-add-input">
                          <div className="form-group common-input">
                            <label htmlFor="text" className="label-pd">
                              <MandatoryIcon /> {t("First Name")}
                            </label>
                            <InputField
                              inputType="text"
                              id={`firstName-${index}`}
                              placeholder="First name"
                              value={ubo.firstName || ""}
                              name="firstName"
                              onChange={(data: any) =>
                                onInputChange(data.target, index)
                              }
                              isDisabled={!allowEdit}
                            />
                            {showValidationErrors && !ubo.firstName && (
                              <div>
                                <FieldErrorMessage errorMessage="Please enter first name" />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="ubo-add-input">
                          <div className="form-group common-input">
                            <label htmlFor="text" className="label-pd">
                              <MandatoryIcon /> {t("Last Name")}
                            </label>
                            <InputField
                              inputType="text"
                              id={`lastName-${index}`}
                              placeholder={t("Last name")}
                              value={ubo.lastName || ""}
                              name="lastName"
                              onChange={(data: any) =>
                                onInputChange(data.target, index)
                              }
                              isDisabled={!allowEdit}
                            />
                            {showValidationErrors && !ubo.lastName && (
                              <div>
                                <FieldErrorMessage errorMessage="Please enter last name" />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="ubo-add-input">
                          <div className="form-group common-input">
                            <label htmlFor="text" className="label-pd">
                              <MandatoryIcon /> {t("Date of birth")}
                            </label>
                            <Flatpickr
                              disabled={!allowEdit}
                              value={ubo.dob}
                              onChange={(date: any) => {
                                let dateStr = utils.getDate(date[0]);
                                onInputChange(
                                  { name: "dob", value: dateStr },
                                  index
                                );
                              }}
                              className="form-control form-control--bordered"
                              options={{
                                dateFormat: "d-m-Y",
                                mode: "single",
                                disable: [
                                  function (date) {
                                    return date > new Date();
                                  }
                                ]
                              }}
                            />
                            {showValidationErrors && !ubo.dob && (
                              <div>
                                <FieldErrorMessage errorMessage="Please enter date of birth" />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="ubo-add-input">
                          <div className="form-group common-input">
                            <label htmlFor="text" className="label-pd">
                              <MandatoryIcon color="black" />{" "}
                              {t("Tax-ID number")}
                            </label>
                            <InputField
                              inputType="text"
                              id={`tax-id-number-${index}`}
                              placeholder="xxx-xx-xx"
                              value={ubo.taxIdNumber || ""}
                              name="taxIdNumber"
                              onChange={(data: any) =>
                                onInputChange(data.target, index)
                              }
                              isDisabled={!allowEdit}
                            />
                          </div>
                        </div>
                        <div className="ubo-add-input">
                          <div className="form-group common-input">
                            <label htmlFor="text" className="label-pd">
                              <MandatoryIcon /> {t("Voting Rights")}
                            </label>
                            <InputField
                              inputType="number"
                              min={0}
                              max={100}
                              id={`votingRights-${index}`}
                              placeholder={t("Voting Rights")}
                              value={ubo.votingRights || ""}
                              name="votingRights"
                              onChange={(data: any) =>
                                onInputChange(data.target, index)
                              }
                              isDisabled={!allowEdit}
                            />
                            {showValidationErrors &&
                              !isNumericValue({
                                data: ubo.votingRights,
                                allowNegative: false,
                                upperLimit: 100
                              }) && (
                                <div>
                                  <FieldErrorMessage errorMessage="Please enter valid voting rights between 0-100" />
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="ubo-add-input">
                          <div className="form-group common-input">
                            <label htmlFor="text" className="label-pd">
                              <MandatoryIcon /> {t("Capital shares")}
                            </label>
                            <InputField
                              inputType="number"
                              min={0}
                              max={100}
                              id={`shareHolding-${index}`}
                              placeholder="Capital shares"
                              value={ubo.shareHolding || ""}
                              name="shareHolding"
                              onChange={(data: any) =>
                                onInputChange(data.target, index)
                              }
                              isDisabled={!allowEdit}
                            />
                            {showValidationErrors &&
                              !isNumericValue({
                                data: ubo.shareHolding,
                                allowNegative: false,
                                upperLimit: 100
                              }) && (
                                <div>
                                  <FieldErrorMessage errorMessage="Please enter valid capital shares between 0-100" />
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="ubo-add-input">
                          <div className="form-group common-input">
                            <label htmlFor="text" className="label-pd">
                              {t("Comments")}
                            </label>
                            <InputField
                              inputType="text"
                              id={`comments-${index}`}
                              placeholder="Comments"
                              value={ubo.comments || ""}
                              name="comments"
                              onChange={(data: any) =>
                                onInputChange(data.target, index)
                              }
                              isDisabled={!allowEdit}
                            />
                          </div>
                          {showValidationErrors &&
                            !ubo.taxIdNumber &&
                            !ubo.comments && (
                              <div>
                                <FieldErrorMessage
                                  errorMessage={t(
                                    "If exempt from providing the Tax-ID number,please include the reason as a comment."
                                  )}
                                />
                              </div>
                            )}
                        </div>
                        <div className="ubo-add-input">
                          <div className="form-group common-input">
                            <label htmlFor="text" className="label-pd">
                              <MandatoryIcon /> {t("Place of birth")}
                            </label>
                            <InputField
                              inputType="text"
                              id={`place-of-birth-${index}`}
                              placeholder="Place of birth"
                              value={ubo.placeOfBirth || ""}
                              name="placeOfBirth"
                              onChange={(data: any) =>
                                onInputChange(data.target, index)
                              }
                              isDisabled={!allowEdit}
                            />
                          </div>
                          {showValidationErrors && !ubo.placeOfBirth && (
                            <div>
                              <FieldErrorMessage errorMessage="Please enter place of birth" />
                            </div>
                          )}
                        </div>
                        <div className="ubo-add-input file-document-box">
                          <div className="ubo-add-file-upload">
                            <div className="">
                              <div className="file-upload">
                                <label className="file-upload-label label-pd">
                                  {t("Upload files")}
                                </label>
                                <div className="form-group">
                                  <Files
                                    uploadFiles={allowEdit}
                                    downloadFiles={true}
                                    files={ubo.documents || []}
                                    uploadButtonName="Choose files"
                                    onChange={(
                                      files: [
                                        {
                                          originalFileName: string;
                                          timeStampedFileName: string;
                                        }
                                      ]
                                    ) => {
                                      onInputChange(
                                        {
                                          name: "documents",
                                          value: files
                                        },
                                        index
                                      );
                                    }}
                                    uploaderId={`knownUBO-${index}`}
                                    fileParams={fileUploadParams}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-top">
                        <div className="innerForm eqaual-coulmn-form">
                          <div className="ubo-add-input">
                            <div className="form-group common-input">
                              <label htmlFor="text" className="label-pd">
                                {t("Address")}
                              </label>
                              <InputField
                                inputType="text"
                                id={`address-${index}`}
                                placeholder="Address"
                                value={ubo.address || ""}
                                name="address"
                                onChange={(data: any) =>
                                  onInputChange(data.target, index)
                                }
                                isDisabled={!allowEdit}
                              />
                            </div>
                          </div>
                          <div className="ubo-add-input">
                            <div className="form-group common-input">
                              <label htmlFor="text" className="label-pd">
                                {t("Zip")}
                              </label>
                              <InputField
                                inputType="text"
                                id={`zip-${index}`}
                                placeholder="Zip"
                                value={ubo.zip || ""}
                                name="zip"
                                onChange={(data: any) =>
                                  onInputChange(data.target, index)
                                }
                                isDisabled={!allowEdit}
                              />
                            </div>
                          </div>
                          <div className="ubo-add-input">
                            <div className="form-group common-input">
                              <label htmlFor="text" className="label-pd">
                                <MandatoryIcon /> {t("City/Place of residence")}
                              </label>
                              <InputField
                                inputType="text"
                                id={`city-${index}`}
                                placeholder="City/Place of residence"
                                value={ubo.city || ""}
                                name="city"
                                onChange={(data: any) =>
                                  onInputChange(data.target, index)
                                }
                                isDisabled={!allowEdit}
                              />
                              {showValidationErrors && !ubo.city && (
                                <div>
                                  <FieldErrorMessage errorMessage="Please enter city/place of residence" />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="ubo-add-input">
                            <div className="form-group common-input">
                              <label htmlFor="text" className="label-pd">
                                <MandatoryIcon /> {t("Country")}
                              </label>
                              <Select
                                value={
                                  ubo.country
                                    ? getSelectDefaultValue(
                                        ubo.country.id,
                                        countries
                                      )
                                    : null
                                }
                                options={utils.modifyForSelectBoxi18n(
                                  countries,
                                  "id",
                                  "name",
                                  i18n.language === "de" ? "germanName" : "name"
                                )}
                                // name="country"
                                placeholder={t("Country")}
                                onChange={(ele: any) => {
                                  onInputChange(
                                    {
                                      name: "country",
                                      value: { id: ele.id, name: t(ele.value) }
                                    },
                                    index
                                  );
                                }}
                                isDisabled={!allowEdit}
                                noOptionsMessage={() => t("No options")}
                              />
                              {showValidationErrors && !ubo.country && (
                                <div>
                                  <FieldErrorMessage
                                    errorMessage={t("Please select country")}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="ubo-add-input">
                            <div className="form-group common-input">
                              <label htmlFor="text" className="label-pd">
                                {t("Nationality")}
                              </label>
                              <Select
                                value={
                                  ubo?.nationality
                                    ? getMultiSelectValue(
                                        ubo?.nationality,
                                        countries
                                      )
                                    : []
                                }
                                // value={ubo?.nationality || []}
                                options={utils.modifyForSelectBoxi18n(
                                  countries,
                                  "id",
                                  "name",
                                  i18n.language === "de" ? "germanName" : "name"
                                )}
                                isMulti={true}
                                // name="country"
                                placeholder={t("Nationality")}
                                onChange={(ele: any) => {
                                  onInputChange(
                                    {
                                      name: "nationality",
                                      value: ele
                                    },
                                    index
                                  );
                                }}
                                isDisabled={!allowEdit}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="ubo-add-input file-document-box">
                          <div className="form-group common-input">
                            *
                            {t(
                              "If exempt from providing the Tax-ID number,please include the reason as a comment."
                            )}
                          </div>
                        </div>
                        <div className="add-remove-ubo-list">
                          <button
                            onClick={() => {
                              setShowConfirmationModal(true);
                              setConfirmationBoxData(
                                Object.assign(
                                  {},
                                  {
                                    title: "Confirm Delete",
                                    message:
                                      "Are you sure want to delete the entity ?",
                                    callBack: () => {
                                      setShowConfirmationModal(false);
                                      deleteUbo(index);
                                    }
                                  }
                                )
                              );
                            }}
                            className="btn btn-primary"
                            disabled={!allowEdit}
                          >
                            {t("Remove Details")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        modalTitle={confirmationBoxData.title}
        message={confirmationBoxData.message}
        callBack={confirmationBoxData.callBack}
        showConfirmationModal={showConfirmationModal}
        onModalClose={() => {
          setShowConfirmationModal(false);
        }}
      />
    </>
  );
};

export default KnownUBO;
